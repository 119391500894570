import { Header, SEO, Spacer, Text, Toast } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { breakpoint } from 'common/styles/variables'
import RichTextRenderer from 'Components/RichTextRenderer'
import { useCompliance } from 'hooks/contentful/useCompliance'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Element, Link } from 'react-scroll'
import styled from 'styled-components/macro'

import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import type { IComplianceContentFields, IHeaderWithParagraphFields } from '../../types/generated/contentful'

const ComplianceSection = ({ id, title, children, color }: { id?: string; color?: string; title: string; children: React.ReactNode }) => {
  return (
    <>
      <SectionTitle color={color} id={id || ''}>
        {title}
      </SectionTitle>
      {children}
    </>
  )
}

const ComplianceSubSection = ({ id, title, children, color }: { id?: string; color?: string; title: string; children: React.ReactNode }) => {
  return (
    <>
      <SectionSubTitle color={color} id={id || ''}>
        {title}
      </SectionSubTitle>
      {children}
    </>
  )
}

const Compliance_Index = ({ children, n, color }: { n: string; children: string; color?: string }) => {
  return (
    <Compliance_Index_Container>
      <Text underline variant='body'>
        <StyledLink color={color} smooth to={n} offset={-80}>
          {children}
        </StyledLink>
      </Text>
    </Compliance_Index_Container>
  )
}

const Compliance: React.FC = () => {
  const pageType = window.location.pathname
  const { error, pageContent } = useCompliance(pageType.replace('/', ''))
  const { trackErrorShownEvent } = useAnalytics()
  const { pharmCustomer } = useCustomerConfigContext()

  const { theme } = useContentfulTheme()

  useEffect(() => {
    if (error) {
      trackErrorShownEvent('compliance_page_load')
    }
  }, [error, trackErrorShownEvent])

  return (
    <>
      <SEO title={`${pharmCustomer?.displayName} - ${pageContent?.pageTitle}`} useDefaults />
      <Toast
        borderLeft={true}
        color='pastel'
        className='toast-message'
        icon={true}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={'error'}
        visible={error}
      >
        <div>An error occurred while providing the required information</div>
      </Toast>
      <Compliance_Header backgroundColor={theme.colors['primary-300']}>
        <Custom_Header variant='5xl'>{pageContent?.pageTitle}</Custom_Header>
      </Compliance_Header>
      <Compliance_MainContainer>
        <Text variant='body' style={{ fontSize: '0.9rem' }}>
          Last Updated: {moment(pageContent?.lastUpdated).format('MMMM DD, YYYY')}
        </Text>

        {pageContent?.tableOfContentLinks && (
          <>
            <Complicance_TableOfContent color={theme.colors['primary-700']}>Table of Contents</Complicance_TableOfContent>
            {pageContent?.tableOfContentLinks.map((link) => {
              const { complianceSection } = link.fields
              const { contentId, mainContent } = complianceSection?.fields as IComplianceContentFields
              const { mainHeader } = mainContent?.fields as IHeaderWithParagraphFields
              return (
                <Compliance_Index color={theme.colors['primary-500']} key={contentId} n={contentId as string}>
                  {mainHeader || ''}
                </Compliance_Index>
              )
            })}
          </>
        )}

        <Spacer></Spacer>

        {pageContent?.complianceContent &&
          pageContent?.complianceContent.map((section) => {
            const { mainContent, subContent, contentId } = section.fields
            return (
              <Element name={contentId as string} key={contentId}>
                <ComplianceSection
                  color={theme.colors['primary-700']}
                  title={mainContent?.fields.mainHeader as string}
                  key={mainContent?.fields.mainHeader}
                >
                  <RichTextRenderer css={{ marginBottom: '20px', color: theme.colors['primary-900'] }} document={mainContent?.fields.subtext} />
                </ComplianceSection>
                {subContent?.map((subSection) => {
                  const { mainHeader, subtext } = subSection.fields
                  return (
                    <ComplianceSubSection color={theme.colors['primary-700']} title={mainHeader as string} key={mainHeader}>
                      <RichTextRenderer css={{ marginBottom: '20px', color: theme.colors['primary-900'] }} document={subtext} />
                    </ComplianceSubSection>
                  )
                })}
              </Element>
            )
          })}
        <Spacer size='xl' />
      </Compliance_MainContainer>
    </>
  )
}

const Compliance_Index_Container = styled.div`
  display: flex;
`
const Compliance_Header = styled.div`
  text-align: center;
  background: ${(props: { backgroundColor?: string }) => props.backgroundColor ?? defaultTheme.colors['primary-300']};
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 30px;
`

const Custom_Header = styled(Header)`
  max-width: 450px;
  margin: auto;

  ${breakpoint.laptop} {
    max-width: 350px;
  }
`

const Complicance_TableOfContent = styled.div`
  /* Display/2xl/Desktop bold */
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 30px;
  letter-spacing: -0.5px;
  font-feature-settings: 'liga' off;
  color: ${(props) => props.color ?? defaultTheme.colors['primary-700']};
  margin-top: 20px;
  margin-bottom: 20px;
`

const Compliance_MainContainer = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 85%;
  }
`

const SectionTitle = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
  font-feature-settings: 'liga' off;
  color: ${(props) => props.color ?? defaultTheme.colors['primary-700']};
  margin-top: 20px;
  margin-bottom: 20px;
`

const SectionSubTitle = styled.div`
  /* Display/2xl/Desktop bold */
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-feature-settings: 'liga' off;
  color: ${(props) => props.color ?? defaultTheme.colors['primary-700']};
  margin-left: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => props.color ?? defaultTheme.colors['primary-500']};
`

export default Compliance
