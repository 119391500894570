// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Button } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'
import { breakpoint } from '../../common/styles/variables'

const StyledCompletedAnswerContainer = styled.div`
  display: inline-block;
  padding: 0rem 0.75rem;
`

const StyledShortAnswer = styled.span`
  font-weight: bold;
`

const StyledShortAnswerButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledAnswerButton = styled(ThemedButton)`
  margin: 0rem 0.75rem;
  padding: 1rem 0.5rem;
  width: 100%;

  ${breakpoint.tablet} {
    margin: 0rem 0.5rem;
  }
`

const StyledAnswerDetailsContainer = styled.div`
  padding: 0rem 0.75rem;
`

const StyledAddAnswerContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  ${breakpoint.tablet} {
    flex-direction: column;
  }
`
const StyledValidationErrorContainer = styled.div<ThemedComponent>`
  padding-bottom: 1rem;
  color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark']};
`

const StyledSaveAnswersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
`

const StyledAnswerListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 24px;
`

const StyledSaveButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
  width: 100%;
`

const StyledAddButtonContainer = styled.div`
  ${breakpoint.tablet} {
    margin-top: 0.5rem;
    width: 100%;
    > button {
      width: 100%;
    }
  }
`

const chipStyle = {
  fontSize: '0.6875rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  padding: '0.5rem 0.125rem 0.5rem 0.5rem',
  height: 'min-content',
}

const StyledSubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  button {
    -webkit-appearance: none;
  }
`

const StyledPregnancyShortAnswerButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

// TODO: Should use ThemedButton, but this creates a bug in mobile where deselected item appears selected until clicking away
const StyledPregnancyAnswerButton = styled(Button)<ThemedComponent>`
  line-height: 1.3;
  background-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.grayWhite}`)};
  padding: 1rem 0.5rem;
  width: 100%;
  color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondaryLabel}` : `${vpTheme.colors.buttonPrimary}`)};
  border-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.buttonPrimary}`)};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ vpTheme }) => vpTheme.colors.buttonSecondary} !important;
      border-color: ${({ vpTheme }) => vpTheme.colors.buttonSecondary} !important;
      color: ${({ vpTheme }) => vpTheme.colors.buttonSecondaryLabel} !important;
    }
  }

  &:enabled:active {
    color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.grayWhite}` : `${vpTheme.colors.buttonPrimary}`)};
    border-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.buttonPrimary}`)};
    background-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.grayWhite}`)};
  }

  &:enabled:hover {
    color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.grayWhite}` : `${vpTheme.colors.buttonPrimary}`)};
    border-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.buttonPrimary}`)};
    background-color: ${({ variant, vpTheme }) => (variant === 'primary' ? `${vpTheme.colors.buttonSecondary}` : `${vpTheme.colors.grayWhite}`)};
  }
`

const StyledPregancySaveButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
  width: 100%;
`

export {
  chipStyle,
  StyledAddAnswerContainer,
  StyledAddButtonContainer,
  StyledAnswerButton,
  StyledAnswerDetailsContainer,
  StyledAnswerListContainer,
  StyledCompletedAnswerContainer,
  StyledPregancySaveButtonContainer,
  StyledPregnancyAnswerButton,
  StyledPregnancyShortAnswerButtons,
  StyledSaveAnswersContainer,
  StyledSaveButtonContainer,
  StyledShortAnswer,
  StyledShortAnswerButtons,
  StyledSubmitButtonContainer,
  StyledValidationErrorContainer,
}
