import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement } from 'react'

import { insuranceAccountPageDefaults } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { PatientInsurance } from '../../interfaces'
import { IAccountManagementInsuranceFields } from '../../types/generated/contentful'
import { ButtonCard } from '../Fragments/ButtonCard'
import { ItemCard, ItemCardWrapper } from '../Fragments/ItemCard'
import { StyledPlusCircle } from './styledComponents'

interface Props {
  patientInsuranceList?: PatientInsurance[]
  pageContent: IAccountManagementInsuranceFields | undefined
  handleClickAddInsurance: () => void
  handleClickEditInsurance: (insurance: PatientInsurance) => void
  handleClickDeleteInsurance: (insurance: PatientInsurance) => void
}

const SavedInsurances: React.FC<Props> = ({
  pageContent,
  patientInsuranceList,
  handleClickAddInsurance,
  handleClickEditInsurance,
  handleClickDeleteInsurance,
}): ReactElement => {
  const { theme: vpTheme } = useContentfulTheme()

  return (
    <>
      <ItemCardWrapper mobileBreakpoint='1100px'>
        {patientInsuranceList ? (
          patientInsuranceList.map((insurance) => (
            <div key={`insurance-${insurance.truepill_insurance_token}`}>
              <ItemCard
                itemSubject='insurance'
                heading={`${pageContent?.cardholderIdLabel || insuranceAccountPageDefaults.cardholderIdLabel}: ${insurance.cardholder_id}`}
                line1={<Text>{`${pageContent?.rxBinLabel || insuranceAccountPageDefaults.rxBinLabel}: ${insurance.rx_bin}`}</Text>}
                line2={
                  insurance?.pcn ? (
                    <Text>
                      {pageContent?.rxPcnLabel || insuranceAccountPageDefaults.rxPcnLabel}: {insurance.pcn}
                    </Text>
                  ) : insurance?.rx_group ? (
                    <Text>
                      {pageContent?.rxGroupLabel || insuranceAccountPageDefaults.rxGroupLabel}: {insurance.rx_group}
                    </Text>
                  ) : (
                    <Spacer size='lg' />
                  )
                }
                line3={
                  insurance.pcn && insurance.rx_group ? (
                    <Text>
                      {pageContent?.rxGroupLabel || insuranceAccountPageDefaults.rxGroupLabel}: {insurance.rx_group}
                    </Text>
                  ) : (
                    <Spacer size='lg' />
                  )
                }
                editButtonText={pageContent?.editDetailsLabel}
                deleteButtonText={pageContent?.deleteLabel}
                editHandler={() => handleClickEditInsurance(insurance)}
                deleteHandler={() => handleClickDeleteInsurance(insurance)}
              />
            </div>
          ))
        ) : (
          <></>
        )}
        {patientInsuranceList && patientInsuranceList.length < 2 && (
          <ButtonCard
            text={pageContent?.addInsuranceCardButtonText || insuranceAccountPageDefaults.addInsuranceCardButtonText}
            icon={<StyledPlusCircle vpTheme={vpTheme} className='w-md text-cap-info-dark mb-2xs inline-block' />}
            ariaLabel='add new insurance button'
            onClick={() => handleClickAddInsurance()}
          />
        )}
      </ItemCardWrapper>
    </>
  )
}

export default SavedInsurances
