import { yupResolver } from '@hookform/resolvers/yup'
import { SEO } from '@truepill/react-capsule'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { useCustomerConfigContext } from '../../Components/CustomerConfigProvider'
import { PRESCRIPTION_MANAGEMENT_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { parseTextFieldStateForCapsule } from '../../utils'
import { ForgotPasswordInfo } from '../../utils/validation'
import { StyledForgotPassword } from './styledComponents'
import { ForgotPasswordFields } from './types'
import { useForgotPassword } from './useForgotPassword'

const ForgotPassword = (): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const { pharmCustomer } = useCustomerConfigContext()
  const { isAuthenticated, requestForgotPassword, passwordRequestSuccessMsg } = useForgotPassword()
  const {
    handleSubmit,
    register,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordFields>({
    resolver: yupResolver(ForgotPasswordInfo),
    mode: 'onBlur',
    delayError: 200,
    defaultValues: {
      email: '',
    },
  })

  const watchEmail = watch('email')

  if (isAuthenticated) {
    return <Redirect to={PRESCRIPTION_MANAGEMENT_PATH} />
  }

  return (
    <StyledForgotPassword.MainContainer>
      <SEO title={`${pharmCustomer?.displayName} - Forgot Password`} useDefaults />
      <StyledForgotPassword.MainHeader variant='4xl'>Forgot password</StyledForgotPassword.MainHeader>
      <StyledForgotPassword.Subtext>
        {passwordRequestSuccessMsg ||
          "Please enter the email address associated with your account. We'll send you an email with instructions to reset your password."}
      </StyledForgotPassword.Subtext>

      {!passwordRequestSuccessMsg && (
        <StyledForgotPassword.Form onSubmit={handleSubmit(requestForgotPassword)}>
          <ThemedTextField
            label='Email address'
            placeholder='Enter your email'
            state={parseTextFieldStateForCapsule(errors.email, watchEmail !== '')}
            required
            helperText={errors.email?.message ?? ''}
            {...register('email', {
              onChange: () => {
                clearErrors('email')
              },
            })}
            vpTheme={theme}
          />
          <StyledForgotPassword.ForgotPasswordButtonContainer>
            <ThemedButton role='button' type='submit' vpTheme={theme}>
              Reset password
            </ThemedButton>
          </StyledForgotPassword.ForgotPasswordButtonContainer>
        </StyledForgotPassword.Form>
      )}
    </StyledForgotPassword.MainContainer>
  )
}

export default ForgotPassword
