import { AccordionContent, AccordionTrigger, Header, Text, theme as capsuleTheme } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { breakpoint, mediaLargerThan } from 'common/styles/variables'
import { Check } from 'react-feather'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'
import { IFaqSectionFields } from '../../types/generated/contentful'

interface ContainerProps {
  backgroundImage?: string
}

interface ImageProps {
  imageUrl: string
  windowWidth: number
}

const HeroContainer = styled.div<ContainerProps>`
  width: 100%;
  padding: 3rem 1.5rem;
  background-image: url(${(props) => props.backgroundImage});
  ${mediaLargerThan.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
  }
  ${mediaLargerThan.laptop} {
    padding: 6rem 5rem;
  }
`

const ImageContainer = styled.div`
  ${mediaLargerThan.tablet} {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
  }
`

const Image = styled.div`
  background: url(${(props: ImageProps) => props.imageUrl}) center center;
  background-size: cover;
  border-radius: 1.25rem;
  width: 100%;
  height: ${(props: ImageProps) => `${props.windowWidth / 1.5}px`};
  ${mediaLargerThan.tablet} {
    max-width: 590px;
    height: ${(props: ImageProps) => `${props.windowWidth / 3.4}px`};
  }
`

const Headline = styled(Header)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']};
  font-size: 2.25rem;
  ${mediaLargerThan.tablet} {
    font-size: 3.75rem;
    margin-top: 0;
  }
`

const Subtitle = styled(Header)`
  margin-top: 1.5rem;
  ${mediaLargerThan.tablet} {
    margin-top: 4.438rem;
  }
`

const CallToAction = styled(ThemedButton)`
  margin-top: 3rem;
  width: 100%;
  ${mediaLargerThan.tablet} {
    max-width: 23.125rem;
  }
`

const ContentContainer = styled.div`
  margin-top: ${(props: { hasImage: string | undefined }) => (props.hasImage ? '1.5rem' : '0')};
  ${mediaLargerThan.tablet} {
    margin-top: 0;
    width: ${(props: { hasImage: string | undefined }) => (props.hasImage ? '50%' : '100%')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1.5rem;
  }
`

const ButtonContainer = styled.div<{ fullWidth: boolean; usePadding: boolean; variant: string | undefined }>`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.usePadding && '3rem 0'};

  a {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto !important')};
  }

  button {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    pointer-events: ${(props) => props.variant === 'secondary-text' && 'none'};
  }

  ${mediaLargerThan.tablet} {
    padding: ${(props) => props.usePadding && '5rem 0'};

    a {
      width: 23rem;
    }
  }
`

const StyledAccordionTrigger = styled(AccordionTrigger)<ThemedComponent>`
  border-top: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-500']};

  svg polyline {
    stroke: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  }
`

const StyledAccordionContent = styled(AccordionContent)`
  padding: 1.5rem 1.5rem 2rem;
`

const FaqSectionContainer = styled.div<IFaqSectionFields>`
  width: 100%;
  padding-top: ${(props) => props.paddingTopMobile || `4rem`};
  padding-left: ${(props) => props.paddingLeftMobile || `1.5rem`};
  padding-right: ${(props) => props.paddingRightMobile || `1.5rem`};
  padding-bottom: ${(props) => props.paddingBottomMobile || `0`};

  ${mediaLargerThan.tablet} {
    padding-top: ${(props) => props.paddingTopLargeDevices || `6rem`};
    padding-left: ${(props) => props.paddingLeftLargeDevices || `8rem`};
    padding-right: ${(props) => props.paddingRightLargeDevices || `8rem`};
    padding-bottom: ${(props) => props.paddingBottomLargeDevices || `0`};
  }
`

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  ${mediaLargerThan.tablet} {
    margin-bottom: 6rem;
  }

  img {
    margin-right: 1rem;

    ${breakpoint.tablet} {
      width: 24px;
      height: 24px;
    }
  }
`

const StyledHeader = styled(Header)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
  -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
`

const HeaderWithButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const HeaderWithUnderline = styled.div`
  display: flex;
  flex-direction: column;
`

const Underline = styled.div<ThemedComponent>`
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
  width: 18.75rem;
  margin-top: 0.813rem;
`

const HorizontalImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
`

const HorizontalImageContainer = styled.div`
  background: url(${(props: { imageUrl: string; maxWidth: number; maxHeight: number }) => props.imageUrl}) center center;
  display: flex;
  background-size: cover;
  width: 100%;
  height: 413px;
  ${mediaLargerThan.tablet} {
    height: ${(props: { imageUrl: string; maxWidth: number; maxHeight: number }) => props.maxHeight}px;
    width: ${(props: { imageUrl: string; maxWidth: number; maxHeight: number }) => props.maxWidth}px;
  }
`

const HowToContentContainer = styled.div`
  background-color: ${(props: { backgroundColor?: string }) => props.backgroundColor};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${mediaLargerThan.tablet} {
    padding: 0 2rem;
  }
  ${mediaLargerThan.desktopSm} {
    padding: 0 5rem;
  }
`

const CardInfoContainer = styled.div`
  display: inline;
  align-self: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  ${mediaLargerThan.mobile} {
    display: flex;
    align-self: center;
  }
`

const HowToTitle = styled(Header)`
  align-self: center;
  color: ${(props: { textColor: string }) => props.textColor};
`

const HowToDescription = styled(Text)`
  align-self: center;
  color: ${(props: { textColor: string }) => props.textColor};
`

const HowToButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  a {
    width: 100%;
  }
  button {
    width: 100%;
  }
  ${mediaLargerThan.tablet} {
    padding-top: 5rem;
    padding-bottom: 5rem;
    a {
      width: 23rem;
    }
  }
`

const SectionDots = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`

const DotIndicator = styled.button<{ selected: boolean } & ThemedComponent>`
  width: 1rem;
  height: 1rem;
  border: none;
  margin: 0.8rem;
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
  background: ${({ selected, vpTheme }) => (selected ? vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500'] : 'transparent')};
`

const IconContainer = styled.img`
  background-color: ${(props: { backgroundColor?: string }) => props.backgroundColor};
`

const StyledIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1.875rem;
  ${mediaLargerThan.mobile} {
    margin-left: 8%;
  }
  ${mediaLargerThan.tablet} {
    margin-left: 25%;
  }
`

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1.875rem;
  ${mediaLargerThan.mobile} {
    margin-left: 8%;
    margin-right: 8%;
  }
  ${mediaLargerThan.tablet} {
    margin-left: 25%;
    margin-right: 25%;
  }
`

const StyledSubTitle = styled(StyledTitle)<ThemedComponent>`
  p {
    font-size: 1.25rem;
    letter-spacing: -0.25px;
    line-height: ${capsuleTheme.lineHeights.lg.computedValue};
    ${mediaLargerThan.tablet} {
      letter-spacing: -0.5px;
      line-height: ${capsuleTheme.lineHeights.xl.computedValue};
      font-size: 1.5rem;
    }
  }
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
`

const PartnerContainer = styled.div`
  justify-content: center;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  height: 100%;
`

const PartnerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  a {
    width: 100%;
  }
  button {
    width: 90%;
  }
  ${mediaLargerThan.mobile} {
    button {
      width: 45%;
    }
  }
  ${mediaLargerThan.tablet} {
    a {
      width: 23rem;
    }
    button {
      width: 35%;
    }
  }
  ${mediaLargerThan.laptop} {
    button {
      width: 25%;
    }
  }
`

const PartnerLogoContainer = styled.div`
  margin-top: 4rem;
  align-self: center;
  width: 110px;
  height: 43px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledPartnerImage = styled.img`
  width: 85%;
  height: ${(props: { height: string }) => props.height};
  mix-blend-mode: multiply;
  border-radius: 1.875rem;
  background-size: cover;
`

const PartnerImageContainer = styled.div`
  margin-top: ${(props: { height: string }) => `-${props.height}`};
  flex-direction: column;
  width: 85%;
  background-image: linear-gradient(
    to right,
    ${(props: { startFadeColor: string; endFadeColor: string; height: string }) => props.startFadeColor},
    ${(props: { startFadeColor: string; endFadeColor: string; height: string }) => props.endFadeColor}
  );
  border-radius: 1.875rem;
  display: flex;
  height: ${(props: { startFadeColor: string; endFadeColor: string; height: string }) => props.height};
`

const InfoTextContainer = styled.div<ThemedComponent & { backgroundColor?: string }>`
  background-color: ${({ vpTheme, backgroundColor }) => backgroundColor ?? vpTheme.colors.grayWhite};
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  color: ${(props) => props.vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']};
  ${mediaLargerThan.tablet} {
    flex-direction: row;
    font-size: 2.875rem;
  }
`

const InfoTextImageContainer = styled.div`
  background: url(${(props: { imageUrl: string }) => props.imageUrl}) center center;
  display: flex;
  background-size: cover;
  width: 100%;
  min-height: 420.57px;
  max-height: 850.68px;
`

const TextWithoutImageContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mediaLargerThan.tablet} {
    padding: 0 2rem;
    width: ${(props: { image: boolean }) => (props.image ? '50%' : '100%')};
  }
  ${mediaLargerThan.desktopSm} {
    padding: 0 5rem;
    width: ${(props: { image: boolean }) => (props.image ? '50%' : '100%')};
  }
`

const DividerContainer = styled.div`
  width: 273px;
  ${mediaLargerThan.tablet} {
    width: 193.54px;
  }
`

interface AccreditationsContainerProps {
  backgroundColor?: string
}

const AccreditationsContainer = styled.div<AccreditationsContainerProps>`
  padding: 1.5rem;
  background-color: ${(props) => props.backgroundColor};

  ${mediaLargerThan.laptop} {
    padding: 2.25rem;
  }
`

const AccreditationsLogosContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaLargerThan.tablet} {
    width: 80%;
  }

  ${mediaLargerThan.laptop} {
    width: 60%;
  }
`

const AccreditationsImageContainer = styled.div`
  width: 33%;
  max-width: 170px;
  max-height: 64px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const TwoColumnsContainer = styled.div`
  ${mediaLargerThan.tablet} {
    display: flex;
    min-height: 638px;
  }
`

const TwoColumnsImageContainer = styled.div`
  background: url(${(props: { imageUrl: string }) => props.imageUrl}) center center;
  background-size: cover;
  height: 413px;
  ${mediaLargerThan.tablet} {
    width: 50%;
    height: auto;
  }
`

const TwoColumnsContentContainer = styled.div`
  background-color: ${(props: { backgroundColor?: string }) => props.backgroundColor};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mediaLargerThan.tablet} {
    width: 50%;
    padding: 0 2rem;
  }
  ${mediaLargerThan.desktopSm} {
    padding: 0 5rem;
  }
`

const TwoColumnsLogosSection = styled.div`
  margin-top: 5rem;
`

const TwoColumnsLogoContainer = styled.div`
  margin-top: 2rem;
  width: 110px;
  height: 43px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const BrandLogoContainer = styled.div`
  margin-top: 2rem;
  width: 33%;
  max-height: 60px;
  max-width: 170px;
  img {
    max-width: 100%;
    max-height: 100%;
    padding-right: 1rem;
  }
`

const TwoColumnsSubtitle = styled(Header)`
  margin-top: 2rem;
  max-width: 530px;
`

const TwoColumnsBody = styled(Text)`
  margin-top: 2rem;
  max-width: 400px;
`

const TextItemsContainer = styled.div`
  margin-top: 1rem;
`

const TextItem = styled(Header)`
  display: flex;
  padding-top: 1rem;
  align-items: center;
`

const TextItemIcon = styled(Check)<ThemedComponent>`
  width: 1.54rem;
  color: ${({ vpTheme }) => vpTheme.colors['primary-500'] ?? defaultTheme.colors['primary-500']};
  margin-right: 1rem;
`

const TwoColumnsLogosContainer = styled.div`
  display: flex;
`

const ValuePropsContentContainer = styled.div`
  background-color: ${(props: { backgroundColor?: string }) => props.backgroundColor};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${mediaLargerThan.tablet} {
    padding: 0 2rem;
  }
  ${mediaLargerThan.desktopSm} {
    padding: 0 5rem;
  }
`

const ValuePropsCardInfoContainer = styled.div`
  display: inline;
  align-self: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  ${mediaLargerThan.mobile} {
    display: flex;
    align-self: center;
  }
`

const ValuePropsTitle = styled(Header)`
  display: flex;
  align-self: center;
  color: ${(props: { textColor: string }) => props.textColor || defaultTheme.colors['primary-100']};
`

export {
  AccreditationsContainer,
  AccreditationsImageContainer,
  AccreditationsLogosContainer,
  BrandLogoContainer,
  ButtonContainer,
  CallToAction,
  CardInfoContainer,
  ContentContainer,
  DividerContainer,
  DotIndicator,
  FaqSectionContainer,
  HeaderWithButton,
  HeaderWithUnderline,
  Headline,
  HeroContainer,
  HorizontalImageContainer,
  HorizontalImageWrapper,
  HowToButtonContainer,
  HowToContentContainer,
  HowToDescription,
  HowToTitle,
  IconContainer,
  Image,
  ImageContainer,
  InfoTextContainer,
  InfoTextImageContainer,
  PartnerButtonContainer,
  PartnerContainer,
  PartnerImageContainer,
  PartnerLogoContainer,
  SectionDots,
  SectionTitle,
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledHeader,
  StyledIcon,
  StyledPartnerImage,
  StyledSubTitle,
  StyledTitle,
  Subtitle,
  TextItem,
  TextItemIcon,
  TextItemsContainer,
  TextWithoutImageContainer,
  TwoColumnsBody,
  TwoColumnsContainer,
  TwoColumnsContentContainer,
  TwoColumnsImageContainer,
  TwoColumnsLogoContainer,
  TwoColumnsLogosContainer,
  TwoColumnsLogosSection,
  TwoColumnsSubtitle,
  Underline,
  ValuePropsCardInfoContainer,
  ValuePropsContentContainer,
  ValuePropsTitle,
}
