import axios from 'axios'
import { selectedPatientTokenAtom } from 'persistRecoil'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { useCustomerProfile } from '../../../hooks'
import { CustomerProfile } from '../../../interfaces'
import StorageService from '../../../services/StorageService'

interface ReturnValues {
  isUploadingInsuranceImage: boolean
  uploadInsuranceImage: (image: File) => Promise<string>
  customerProfile: CustomerProfile
}

export const useInsuranceImageUpload = (): ReturnValues => {
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [isUploadingInsuranceImage, setIsUploadingImage] = useState<boolean>(false)

  const uploadInsuranceImage = async (insuranceImageFile: File): Promise<string> => {
    const storageService = new StorageService()
    const form = new FormData()
    setIsUploadingImage(true)
    try {
      const fileExtension = insuranceImageFile.type.split('/')[1] as 'jpeg' | 'png' | 'jpg'
      form.append('Content-Type', insuranceImageFile.type)
      const insuranceUploadInfo = await storageService.requestInsuranceCardUpload(
        fileExtension,
        selectedPatientToken,
        customerProfile.vpharmCustomerToken,
      )
      const insuranceImageKey = insuranceUploadInfo.fields.key.split('/')[3]

      Object.entries(insuranceUploadInfo.fields).forEach(([key, val]) => {
        form.append(key, val)
      })

      form.append('file', insuranceImageFile)

      await axios.post(insuranceUploadInfo.url, form)
      return insuranceImageKey
    } catch (e) {
      throw new Error('Unable to upload insurance image')
    } finally {
      setIsUploadingImage(false)
    }
  }

  return {
    isUploadingInsuranceImage,
    uploadInsuranceImage,
    customerProfile,
  }
}
