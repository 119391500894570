import { IResource } from '../../types/generated/contentful'

interface ReducedObject {
  [key: string]: any
}

export const getPageMicrocopy = (microcopy: IResource[] | undefined): ReducedObject | undefined => {
  const reducedObj: ReducedObject = {}
  const fields = microcopy?.reduce((acc, curr) => {
    const { key, value, richValue } = curr.fields
    if (richValue) {
      acc[key] = richValue
    } else {
      acc[key] = value
    }
    return acc
  }, reducedObj)
  return fields
}
