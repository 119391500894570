import { datadogRum } from '@datadog/browser-rum'
import { yupResolver } from '@hookform/resolvers/yup'
import { Spacer, Text } from '@truepill/react-capsule'
import { PatientIdentityFormValues } from '@vpharm-platform/shared'
import ToastMessage from 'Components/ToastMessage'
import { PatientRegistrationSessionKeys } from 'constants/sessionKeys'
import { useCustomerProfile, useUpdateCart } from 'hooks'
import { useContentfulIdentityVerificationContent, useContentfulTheme } from 'hooks/contentful'
import { useQuery } from 'hooks/useQuery'
import CheckoutPage from 'pages/CheckoutFlow/CheckoutPage'
import React, { useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'
import { patientService } from 'services'
import { PatientIdentityValidationSchema } from 'utils/validation'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import DOBFormControl from '../../../Components/DOBFormControl'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import SupportLink from '../../../Components/SupportLink'
import { LOGIN_PATH, PATIENT_RECORD_VERIFICATION_PATH } from '../../../constants'
import { useAuth } from '../../../context/auth-context'
import { useAnalytics } from '../../../hooks/analytics-context'
import usePatientRegistration from '../../../hooks/useCheckPatientRegistered'
import { ButtonsWrapper } from '../styledComponents'

const IdentityVerification: React.FC = () => {
  const query = useQuery()
  const history = useHistory()
  const { customerProfile } = useCustomerProfile()
  const [showError, setShowError] = useState(false)
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)
  const [isMinor, setIsMinor] = useState(false)
  const { patientRegistered, loading, error, checkPatientRegistration } = usePatientRegistration()
  const {
    logout,
    authState: { isAuthenticated },
  } = useAuth()
  const { resetUserAnalyticState, trackButtonClickEvent, trackErrorShownEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const registrationToken = query.get('registration_token') || ''
  const customerToken = customerProfile.vpharmCustomerToken
  const { loading: contentIsLoading, content } = useContentfulIdentityVerificationContent()
  const { clearCart } = useUpdateCart()

  const form = useForm<PatientIdentityFormValues>({
    resolver: yupResolver(PatientIdentityValidationSchema),
    mode: 'onBlur',
  })

  if (error) {
    trackErrorShownEvent('Cannot check is pt registered', 'useCheckPatientRegistered')
  }

  const onSubmit = async (data: PatientIdentityFormValues) => {
    const submitData: PatientIdentityFormValues = { ...data }

    trackButtonClickEvent('submit patient data', 'submit', 'find patient record')
    setShowLoadingScreen(true)
    try {
      const confirmedPatient = await patientService.getPatient(submitData)
      if (confirmedPatient.isValid && confirmedPatient.first_name) {
        history.push(PATIENT_RECORD_VERIFICATION_PATH, confirmedPatient)
      } else if (confirmedPatient.error?.message === 'Patient is a minor.') {
        setIsMinor(true)
        setShowLoadingScreen(false)
        trackErrorShownEvent('Cannot verify identity - minor', 'submit identity verification form')
      } else {
        setShowError(true)
        setShowLoadingScreen(false)
        trackErrorShownEvent('Cannot verify identity - patient issue', 'submit identity verification form')
      }
    } catch {
      trackErrorShownEvent('Cannot verify identity', 'submit identity verification form')
      setShowLoadingScreen(false)
    }
  }

  useEffect(() => {
    // must log out before activating new account
    // or activation endpoint will be called with existing account
    if (isAuthenticated) {
      logout()
    }
    // clear caches if there are any left from previous accounts so new account can start from clean env
    resetUserAnalyticState()
    datadogRum.clearUser()
    localStorage.clear()
    sessionStorage.clear()
    clearCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (registrationToken) {
      sessionStorage.setItem(PatientRegistrationSessionKeys.RegistrationToken, registrationToken)
      checkPatientRegistration()
    } else {
      // Test purpose until email link is provided
      sessionStorage.setItem(PatientRegistrationSessionKeys.RegistrationToken, '85b18aa7-273b-4197-bc06-27cfc6f550c2')
    }
  }, [registrationToken, checkPatientRegistration])

  useEffect(() => {
    if (customerToken) {
      sessionStorage.setItem(PatientRegistrationSessionKeys.VpharmCustomerToken, customerToken)
      checkPatientRegistration()
    }
  }, [customerToken, checkPatientRegistration])

  if (patientRegistered) {
    return <Redirect to={{ pathname: LOGIN_PATH, state: { fromIdentityVerification: true } }} />
  }

  if (showLoadingScreen || contentIsLoading || loading) {
    return <LoadingAnimation />
  }

  return (
    <CheckoutPage
      header={content?.header || 'Please verify your information'}
      instructions={content?.description || 'Enter your information so we can match your prescriptions to your profile.'}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Text bold>Date of birth</Text>
        <Spacer size='sm' />
        <DOBFormControl form={form as UseFormReturn<PatientIdentityFormValues, Record<string, unknown>>} />
        <Spacer size='lg' />
        <ButtonsWrapper>
          <ThemedButton disabled={!form.formState.isValid} type='submit' vpTheme={theme}>
            {content?.nextButton || 'Next'}
          </ThemedButton>
        </ButtonsWrapper>
        <ToastMessage
          timeout={60000}
          onDismiss={() => {
            setShowError(false)
          }}
          state={'error'}
          visible={showError}
          onTimeout={() => {
            setShowError(false)
          }}
        >
          <Text>
            Unfortunately, we could not activate your account. Please try again. If the issue persists, please email{' '}
            <SupportLink href='/contact?issue=account_creation'>our support team</SupportLink>
          </Text>
        </ToastMessage>
        <ToastMessage
          timeout={60000}
          onDismiss={() => {
            setIsMinor(false)
          }}
          onTimeout={() => {
            setIsMinor(false)
          }}
          state={'error'}
          visible={isMinor}
        >
          <Text>
            Users must be 18 years or older to create an account. Please email <SupportLink>our support team</SupportLink> to complete your
            prescription order.
          </Text>
        </ToastMessage>
      </form>
    </CheckoutPage>
  )
}

export default IdentityVerification
