import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { AUTO_REFILLS } from '../../constants'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { Result } from '../../hooks/useUserAddresses'
import { PatientAddress } from '../../interfaces'
import { AddressDeleteText, StyledActionContainer, StyledHeader, Wrapper } from './styledComponents'

interface Props {
  currentAddress: PatientAddress
  cancel: () => void
  handleDeleteAddress: (address: PatientAddress) => Promise<Result<boolean>>
}

const DeleteAddress = ({ currentAddress, cancel, handleDeleteAddress }: Props): ReactElement => {
  const { theme } = useContentfulTheme()
  const { customerProfile } = useCustomerProfile()
  const featureFlags = useLDFlagsWithLocalStorage([AUTO_REFILLS])
  const autoRefillsEnabled = featureFlags.autoRefills && customerProfile.autoRefills

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    handleDeleteAddress(currentAddress)
  }, [currentAddress, handleDeleteAddress])

  return (
    <>
      <StyledHeader variant='4xl'>Are you sure you want to delete this address?</StyledHeader>
      <Spacer size='lg' />
      <Wrapper background={theme.colors['gray-100']} data-testid='address-to-be-deleted'>
        <Text data-testid='delete-name' bold>
          {currentAddress.name}
        </Text>
        <div>
          <Text data-testid='delete-address1'>{currentAddress.address1}</Text>
          <Text data-testid='delete-address2'>{currentAddress.address2}</Text>
        </div>
        <div>
          <Text data-testid='delete-city'>{currentAddress.city},</Text>
          <Text data-testid='delete-state'>{currentAddress.state}</Text>
          <Text data-testid='delete-zip'>{currentAddress.zip}</Text>
        </div>
      </Wrapper>
      <Spacer size='lg' />
      <AddressDeleteText vpTheme={theme}>
        {autoRefillsEnabled && currentAddress.isDefault
          ? 'Deleting this address will permanently remove it from your account and may impact previously scheduled auto refills. This action cannot be undone.'
          : 'Deleting this address will permanently remove it from your account. This action cannot be undone.'}
      </AddressDeleteText>
      <Spacer size='md' />
      <StyledActionContainer>
        <ThemedButton data-testid='delete-address-btn-cancel' variant='dark-text' size='lg' onClick={handleCancel} vpTheme={theme}>
          No, keep address
        </ThemedButton>
        <ThemedButton danger={true} data-testid='delete-address-btn-confirm' size='lg' onClick={handleDelete} vpTheme={theme}>
          Yes, delete address
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default DeleteAddress
