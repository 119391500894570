/* eslint-disable import/no-named-as-default-member */
import mixpanelPlugin from '@analytics/mixpanel'
import { truepillAnalytics } from '@truepill/tp-analytics'
import { SavingsCardProcess } from '@vpharm-platform/shared'
import Analytics from 'analytics'
import mixpanel from 'mixpanel-browser'
import React, { createContext, useCallback, useContext, useState } from 'react'

import { getDeviceType, getSubdomain } from '../utils'

export enum VpAnalyticsEventType {
  AccordionChange = 'accordion_change',
  ButtonClick = 'button_click',
  ImageClick = 'image_click',
  LinkClick = 'link_click',
  PageExit = 'page_exit',
  PageLoad = 'page_load',
  TooltipOpen = 'tooltip_open',
  RadioButtonClick = 'radio_button_click',
  Error = 'error',
  PatientVerified = 'patient_verified',
  TextFieldChange = 'text_field_change',
  CheckboxToggleClick = 'checkbox_toggle_click',
  ToggleClick = 'toggle_click',
  RxManagerView = 'rx_manager_view',
  UserSignUp = 'user_sign_up',
  OrderPlaced = 'order_placed',
  PrescriptionCheckedOut = 'prescription_checked_out',
  ABTestingRender = 'ab_testing_render',
  SavingsCardApplied = 'savings_card_applied',
  EmailUpdated = 'email_updated',
  SmsInteraction = 'sms_interaction',
  PharmacyFinderSearch = 'pharmacy_finder_search',
  TransferSubmittedEvent = 'transfer_submitted',
  AutoRefillStatusUpdateEvent = 'auto_refill_status_update',
}

export enum ToastErrorAnalyticsEventType {
  ToastError = 'toast_error',
}

export enum LinkOrigin {
  Header = 'header',
  Footer = 'footer',
  SideBar = 'sidebar',
  Body = 'body',
}

const VPConfig = {
  VPCurrentPageKey: 'currentPage',
  currentPage: '',
  analyticsConfig: {
    appId: process.env.REACT_APP_TRUEPILL_ANALYTICS_APPID || 'vpharm.stg',
    pageLoadEventType: VpAnalyticsEventType.PageLoad,
  },
}

interface AnalyticsProviderProps {
  children: React.ReactNode
}

export interface AnalyticsContextProps {
  isLoading: boolean
  identifyAnalyticsUser: (patientToken: string) => void
  resetUserAnalyticState: () => void
  trackPageViewEvent: (text: string) => void
  trackPageExitEvent: () => void
  trackEvent: (event: VpAnalyticsEventType) => void
  trackButtonClickEvent: (buttonName: string, buttonText: string, buttonAction?: string) => void
  trackRadioButtonClickEvent: (buttonName: string, buttonText: string, buttonAction?: string) => void
  trackLinkClickEvent: (linkText: string, linkAction?: string, linkOrigin?: LinkOrigin) => void
  trackAccordionChangeEvent: (accordionName: string, accordionText: string, isOpen: boolean) => void
  trackTooltipOpenEvent: (tooltipName: string, tooltipText?: string) => void
  trackErrorShownEvent: (error: string, errorAction?: string) => void
  trackCheckboxToggleClickEvent: (checkboxName: string, checkedValue: boolean) => void
  trackToggleEvent: (toggleName: string, toggleValue: boolean, ndc?: string) => void
  trackPatientVerifiedEvent: () => void
  trackTextFieldChangeEvent: (fieldName: string, page: string) => void
  trackImageClickEvent: (imageName: string) => void
  trackRxManagerViewEvent: (emptyRxm: boolean, orderableRxs: boolean) => void
  trackSavingsCardAppliedEvent: (savingsCardFlow: SavingsCardProcess) => void
  trackOrderPlacedEvent: (orderPlacedAttributes: {
    numberOfMedications: number
    copayAmount: number
    shippingAmount?: number | null
    shippingMethod?: string
    shippingState: string
    taxAmount: number
    totalAmount: number
  }) => void
  trackPrescriptionCheckedOutEvent: (prescriptionCheckoutOutAttributes: {
    copayAmount: number
    medicationName: string
    prescriptionToken: string
    rxNumber: string
    strength: string
    ndc: string
    totalAmount: number
  }) => void
  trackABTestingRenderEvent: (abTestName: string, testVersionName: string) => void
  trackSmsInteractionEvent: (pageProps: string) => void
  trackTransferOutPharmacySearchEvent: (autoTransferEnabled: boolean) => void
  trackTransferSubmittedEvent: (transferType: 'transfer_in' | 'transfer_out', medicationName: string, pharmacyName: string) => void
  trackAutoRefillStatusUpdateEvent: (autoRefillType: 'auto_refill_disable' | 'auto_refill_enable', ndc: string) => void
}

const AnalyticsStateContext = createContext<AnalyticsContextProps | undefined>(undefined)

const AnalyticsProvider = ({ children }: AnalyticsProviderProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true)

  /* Initialize analytics */
  const analytics = Analytics({
    app: 'vp-frontend',
    version: '0',
    debug: false,
    plugins: [
      mixpanelPlugin({
        token: process.env.REACT_APP_MIXPANEL_TOKEN || 'ed009541fa9de2677673fff2d6ec0754',
      }),
      truepillAnalytics({
        appId: VPConfig.analyticsConfig.appId,
        pageLoadEventType: VPConfig.analyticsConfig.pageLoadEventType,
      }),
    ],
  })

  // check if all plugins are loaded and ready
  analytics.on('ready', () => {
    setIsLoading(false)
  })
  if (process.env.REACT_APP_MIXPANEL_TOKEN_NEW) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN_NEW)
  }

  const getEventPayload = useCallback((payload?: Record<string, unknown>): Record<string, unknown> => {
    const deviceType = getDeviceType()
    const customerSubdomain = getSubdomain()
    const version = process.env.REACT_APP_API_VERSION || undefined
    const impersonation = localStorage.getItem('isAdminLogin') || false

    payload = { ...payload, deviceType, customer: customerSubdomain || 'unknown', version, impersonation }

    return payload
  }, [])

  const identifyAnalyticsUser = (patientToken: string): void => {
    const customerSubdomain = getSubdomain()

    if (process.env.REACT_APP_MIXPANEL_TOKEN_NEW) {
      mixpanel.identify(patientToken)
      mixpanel.people.set({ name: patientToken, customer: customerSubdomain })
    }
  }

  const resetUserAnalyticState = (): void => {
    // Reset current visitor
    analytics.reset()
  }

  /* Track a page view. */
  const trackPageViewEvent = (page: string): void => {
    VPConfig.currentPage = page

    const payload = getEventPayload()

    /* Track a page view. This will trigger page calls in any installed plugins */
    /**
     * @property {PageData} [data] (optional) Page data overrides.
     * @property {Object} [options] (optional) Page tracking options
     * @property {Function} [callback] (optional) Callback to fire after page view call completes
     */
    analytics.page({ ...payload, page })
    if (process.env.REACT_APP_MIXPANEL_TOKEN_NEW) {
      mixpanel.track_pageview({ ...payload }, { event_name: page })
    }
  }

  /* Track a custom event */
  const trackEventWithAttributes = useCallback(
    (event: VpAnalyticsEventType, attributes?: Record<string, unknown>): void => {
      const payload = getEventPayload(attributes)

      payload[VPConfig.VPCurrentPageKey] = VPConfig.currentPage // what is this used for?

      /* Track an analytics event. This will trigger track calls in any installed plugins */
      /**
    @property {string} [eventName]  - Event name
    @property {Object} [payload] (optional) - Event payload
    @property {Object} [options] (optional) - Event options
    @property {Function} [callback] (optional) - Callback to fire after tracking completes
    */

      analytics.track(event, payload)
      if (process.env.REACT_APP_MIXPANEL_TOKEN_NEW) {
        mixpanel.track(event, payload)
      }
    },
    [analytics, getEventPayload],
  )

  const trackEvent = useCallback(
    (event: VpAnalyticsEventType): void => {
      trackEventWithAttributes(event)
    },
    [trackEventWithAttributes],
  )

  const trackPageExitEvent = (): void => {
    trackEventWithAttributes(VpAnalyticsEventType.PageExit)
  }

  const trackButtonClickEvent = (buttonName: string, buttonText: string, buttonAction?: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.ButtonClick, {
      buttonName,
      buttonText,
      buttonAction,
    })
  }

  const trackRadioButtonClickEvent = (buttonName: string, buttonText?: string, buttonAction?: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.RadioButtonClick, {
      buttonName,
      buttonText,
      buttonAction,
    })
  }

  const trackLinkClickEvent = (linkText: string, linkAction?: string, linkOrigin?: LinkOrigin): void => {
    trackEventWithAttributes(VpAnalyticsEventType.LinkClick, {
      linkText,
      linkAction,
      linkOrigin,
    })
  }

  const trackAccordionChangeEvent = (accordionName: string, accordionText: string, isOpen: boolean): void => {
    trackEventWithAttributes(VpAnalyticsEventType.AccordionChange, {
      accordionName,
      accordionText,
      isOpen,
    })
  }

  const trackTooltipOpenEvent = (tooltipName: string, tooltipText?: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.TooltipOpen, {
      tooltipName,
      tooltipText,
    })
  }

  const trackErrorShownEvent = (errorType: string, errorAction?: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.Error, {
      errorType,
      errorAction,
    })
  }

  const trackCheckboxToggleClickEvent = (checkboxName: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.CheckboxToggleClick, {
      checkboxName,
    })
  }

  const trackToggleEvent = (toggleName: string, toggleValue: boolean, ndc?: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.ToggleClick, {
      toggleName,
      toggleValue,
      ndc,
    })
  }

  const trackPatientVerifiedEvent = (): void => {
    trackEventWithAttributes(VpAnalyticsEventType.PatientVerified)
  }

  const trackTextFieldChangeEvent = (fieldName: string, page: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.TextFieldChange, { fieldName, page })
  }

  const trackImageClickEvent = (imageName: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.ImageClick, { imageName })
  }

  const trackRxManagerViewEvent = (emptyRxm: boolean, orderableRxs: boolean): void => {
    trackEventWithAttributes(VpAnalyticsEventType.RxManagerView, { emptyRxm, orderableRxs })
  }
  const trackSavingsCardAppliedEvent = (savingsCardFlow: SavingsCardProcess): void => {
    trackEventWithAttributes(VpAnalyticsEventType.SavingsCardApplied, { savingsCardFlow })
  }

  const trackTransferOutPharmacySearchEvent = (autoTransferEnabled: boolean): void => {
    trackEventWithAttributes(VpAnalyticsEventType.PharmacyFinderSearch, { autoTransferEnabled })
  }

  const trackTransferSubmittedEvent = (transferType: 'transfer_in' | 'transfer_out', medicationName: string, pharmacyName: string) => {
    trackEventWithAttributes(VpAnalyticsEventType.TransferSubmittedEvent, { transferType, medicationName, pharmacyName })
  }

  const trackAutoRefillStatusUpdateEvent = (autoRefillType: 'auto_refill_disable' | 'auto_refill_enable', ndc: string) => {
    trackEventWithAttributes(VpAnalyticsEventType.AutoRefillStatusUpdateEvent, { ndc, autoRefillType })
  }

  const trackOrderPlacedEvent = (orderPlacedAttributes: {
    numberOfMedications: number
    copayAmount: number
    shippingAmount?: number | null
    shippingMethod?: string
    shippingState: string
    taxAmount: number
    totalAmount: number
  }): void => {
    trackEventWithAttributes(VpAnalyticsEventType.OrderPlaced, orderPlacedAttributes)
  }

  const trackPrescriptionCheckedOutEvent = (prescriptionCheckoutOutAttributes: {
    copayAmount: number
    medicationName: string
    prescriptionToken: string
    rxNumber: string
    strength: string
    ndc: string
    totalAmount: number
  }): void => {
    trackEventWithAttributes(VpAnalyticsEventType.PrescriptionCheckedOut, prescriptionCheckoutOutAttributes)
  }

  const trackABTestingRenderEvent = (abTestName: string, testVersionName: string): void => {
    trackEventWithAttributes(VpAnalyticsEventType.ABTestingRender, { abTestName, testVersionName })
  }

  const trackSmsInteractionEvent = (pageProps: string): void => {
    const props: Record<string, string> = {}
    props.smsTemplate = new URLSearchParams(pageProps).get('smsTemplate') || ''

    if (!props.smsTemplate) return
    trackEventWithAttributes(VpAnalyticsEventType.SmsInteraction, { ...props })
  }

  const value = {
    isLoading,
    trackPageViewEvent,
    identifyAnalyticsUser,
    resetUserAnalyticState,
    trackPageExitEvent,
    trackEvent,
    trackButtonClickEvent,
    trackRadioButtonClickEvent,
    trackLinkClickEvent,
    trackAccordionChangeEvent,
    trackTooltipOpenEvent,
    trackErrorShownEvent,
    trackCheckboxToggleClickEvent,
    trackToggleEvent,
    trackPatientVerifiedEvent,
    trackTextFieldChangeEvent,
    trackImageClickEvent,
    trackRxManagerViewEvent,
    trackSavingsCardAppliedEvent,
    trackOrderPlacedEvent,
    trackPrescriptionCheckedOutEvent,
    trackABTestingRenderEvent,
    trackSmsInteractionEvent,
    trackTransferOutPharmacySearchEvent,
    trackTransferSubmittedEvent,
    trackAutoRefillStatusUpdateEvent,
  }

  return <AnalyticsStateContext.Provider value={value}>{children}</AnalyticsStateContext.Provider>
}

const useAnalytics = (): AnalyticsContextProps => {
  const context = useContext(AnalyticsStateContext)

  if (!context) {
    throw new Error('useAnalytics must be used inside an AnalyticsProvider')
  }

  return context
}

export { AnalyticsProvider, useAnalytics }
