import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'

const OrdersContainer = styled.div`
  max-width: 744px;
  width: 100%;
  padding: 0 24px;
  flex-grow: 1;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 0;
`

const TryAgainButton = styled(ThemedButton)`
  margin-top: 2.5rem;
`

export { ContentWrapper, OrdersContainer, TryAgainButton }
