import {
  PatientEnrollmentErrors,
  PatientEnrollmentParams,
  PatientEnrollmentResponse,
  PatientIdentityFormValues,
  PatientIdentityResponse,
  PatientRegisteredResult,
  sharedConstants,
  unformatPhone,
  VerifyIdentityResponse,
  VerifyIdentityResult,
} from '@vpharm-platform/shared'
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { PatientRegistrationSessionKeys } from '../constants'
import { axiosAuth } from '../httpClient'
import { UserPatientWithUsers } from '../interfaces/User'
import { SelfEnrollmentState } from '../pages/SelfEnrollment'
import { SelfEnrollmentAlreadyEnrolledError } from '../pages/SelfEnrollment/errors'
import { GenderResponses } from '../pages/SelfEnrollment/sections/types'

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants
export interface PatientService {
  verifyPatientIdentity(patientInfo: PatientIdentityResponse): Promise<VerifyIdentityResult>
  getPatient(patientInfo: PatientIdentityFormValues): Promise<PatientIdentityResponse>
  getPatientUsers(patientToken: string, vpharmCustomerToken: string): Promise<UserPatientWithUsers>
  enrollPatient(selfEnrollmentInfo: SelfEnrollmentState, captchaToken: string, vpharmCustomerToken: string): Promise<PatientEnrollmentResponse>
  checkPatientRegistered(): Promise<PatientRegisteredResult>
}

class PatientApiService implements PatientService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}/patient`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async enrollPatient(selfEnrollmentInfo: SelfEnrollmentState, captchaToken: string, customerToken: string): Promise<PatientEnrollmentResponse> {
    const url = `${process.env.REACT_APP_API_URL}/enroll`
    const { month: patientDobMonth, day: patientDobDay, year: patientDobYear } = selfEnrollmentInfo.dob

    const patientEnrollmentInfo: PatientEnrollmentParams = {
      first_name: selfEnrollmentInfo.firstName,
      last_name: selfEnrollmentInfo.lastName,
      gender: selfEnrollmentInfo.gender as GenderResponses,
      dob: `${patientDobYear}${String(patientDobMonth).padStart(2, '0')}${String(patientDobDay).padStart(2, '0')}`,
      phone: unformatPhone(selfEnrollmentInfo.patientPhoneNumber),
      sms_opt_in: selfEnrollmentInfo.enrollCommunications,
      address: {
        address1: selfEnrollmentInfo.address1,
        address2: selfEnrollmentInfo.address2 || undefined,
        city: selfEnrollmentInfo.city,
        state: selfEnrollmentInfo.state,
        zip: selfEnrollmentInfo.zip,
      },
    }

    try {
      const response = await axios.post<PatientEnrollmentResponse>(
        url,
        { patient: patientEnrollmentInfo, captcha_token: captchaToken },
        { headers: { [VPHARM_CUSTOMER_HEADER]: customerToken } },
      )

      return response.data
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // Forbidden returned from server response for existing patient accouts
        if (e.response?.status === 403 && e.response.data.message === PatientEnrollmentErrors.PATIENT_HAS_ACCOUNT) {
          throw new SelfEnrollmentAlreadyEnrolledError('Patient is already enrolled into virtual pharmacy.')
        }
      }
      throw e
    }
  }

  async verifyPatientIdentity(patientInfo: PatientIdentityResponse): Promise<VerifyIdentityResult> {
    const customerToken = sessionStorage.getItem(PatientRegistrationSessionKeys.VpharmCustomerToken)
    const registrationToken = sessionStorage.getItem(PatientRegistrationSessionKeys.RegistrationToken)

    if (!customerToken || !registrationToken) {
      return { isValid: false }
    }

    const url = `${this.baseUrl}/verify_identity`
    const config: AxiosRequestConfig = { headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken } }

    const { first_name, last_name, month, day, year, sms_opt_in } = patientInfo

    const patientData = {
      first_name: first_name,
      last_name: last_name,
      dob: `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`,
      registration_token: registrationToken,
      communications_opt_in: sms_opt_in,
    }

    try {
      const response = await axios.post<VerifyIdentityResponse>(url, { patient: patientData }, config)

      if (response.data) {
        return { isValid: true, identity_verification_token: response.data.identity_verification_token }
      }
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.isAxiosError) {
        return {
          isValid: false,
          error: {
            message: axiosError.response?.data as string,
            code: Number.parseInt(axiosError.code || ''),
          },
        }
      }
    }
    return { isValid: false }
  }

  async checkPatientRegistered(): Promise<PatientRegisteredResult> {
    const customerToken = sessionStorage.getItem(PatientRegistrationSessionKeys.VpharmCustomerToken)
    const registrationToken = sessionStorage.getItem(PatientRegistrationSessionKeys.RegistrationToken)
    if (!customerToken || !registrationToken) {
      return {
        isValid: false,
      }
    }

    const url = `${this.baseUrl}/patient_registered`
    const config: AxiosRequestConfig = { headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken } }

    try {
      const response = await axios.post(url, { registration_token: registrationToken }, config)

      if (response.data) {
        return {
          isValid: true,
          registered: response.data,
        }
      }
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.isAxiosError) {
        return {
          isValid: false,
          error: {
            message: 'No customer token or registration token',
            code: 0,
          },
        }
      }
    }
    return { isValid: false }
  }

  async getPatient(patientInfo: PatientIdentityFormValues): Promise<PatientIdentityResponse> {
    const customerToken = sessionStorage.getItem(PatientRegistrationSessionKeys.VpharmCustomerToken)
    const registrationToken = sessionStorage.getItem(PatientRegistrationSessionKeys.RegistrationToken)

    if (!customerToken || !registrationToken) {
      return { isValid: false }
    }

    const url = `${this.baseUrl}/confirm_patient`
    const config: AxiosRequestConfig = { headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken } }

    const { month, day, year } = patientInfo

    const patientData = {
      dob: `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`,
      registration_token: registrationToken,
    }

    try {
      const response = await axios.post<PatientIdentityResponse>(url, patientData, config)

      if (response.data) {
        return {
          isValid: true,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          month: response.data.month,
          day: response.data.day,
          year: response.data.year,
          gender: response.data.gender,
        }
      }
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.isAxiosError) {
        return {
          isValid: false,
          error: {
            message: axiosError.response?.data as string,
            code: Number.parseInt(axiosError.code || ''),
          },
        }
      }
    }
    return { isValid: false }
  }

  async getPatientUsers(patientToken: string, vpharmCustomerToken: string): Promise<UserPatientWithUsers> {
    const url = `${process.env.REACT_APP_API_URL}/admin/users`
    const config: AxiosRequestConfig = {
      headers: {
        ...this.defaultHeaders,
        [VPHARM_CUSTOMER_HEADER]: vpharmCustomerToken,
        [VPHARM_PATIENT_HEADER]: patientToken,
      },
    }

    try {
      const response = await axiosAuth.get<UserPatientWithUsers>(url, config)
      return response.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
}

export const patientService: PatientService = new PatientApiService()
