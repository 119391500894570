import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 2rem;
`

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;

  ${mediaLargerThan.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const LoadingAnimationWrapper = styled.div<ThemedComponent>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  opacity: 0.2;
  z-index: 1000;
`

const InsuranceImageUploadContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  padding: 1rem;
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;
  border-radius: 8px;
`

const UploadInsuranceImageInput = styled.input`
  display: none;
`

const ImageActionableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-items: center;

  ${mediaLargerThan.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

const ImageButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    justify-content: space-between;
    justify-self: end;
  }
`

const DeleteActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PhotoActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
  }
`

const TextOptional = styled(Text)`
  text-align: center;
`

const UploadedImage = styled.img`
  max-height: 25vh;
  justify-self: center;
  padding-bottom: 1rem;
`

const UploadedImageContainer = styled.div<{ showImage: boolean }>`
  margin: 0 auto 1rem auto;
  ${({ showImage }) => (showImage ? 'display: block;' : 'display: none;')}
`

const SpinnerContainer = styled.div`
  position: relative;
  height: 3.75rem;
  display: flex;
  width: 100%;
  justify-content: center;
`

const InfoBox = styled.div<ThemedComponent>`
  padding: 1em;
  background-color: ${({ vpTheme }) => vpTheme.colors['primary-100']};
  border-radius: 0.5rem;

  h4 {
    line-height: 1.5;
  }
`

const SampleCardLink = styled.button<ThemedComponent>`
  border: none;
  background: none;
  text-decoration: underline;
  color: ${({ vpTheme }) => vpTheme.colors['primary-500']};
  cursor: pointer;
`

const SampleInsuranceCardModal = styled(Modal)`
  height: 100%;
  width: 100%;
`

export {
  DeleteActionContainer,
  FormGrid,
  ImageActionableContainer,
  ImageButtonContainer,
  InfoBox,
  InsuranceImageUploadContainer,
  LoadingAnimationWrapper,
  PhotoActionContainer,
  SampleCardLink,
  SampleInsuranceCardModal,
  SpinnerContainer,
  StyledActionContainer,
  TextOptional,
  UploadedImage,
  UploadedImageContainer,
  UploadInsuranceImageInput,
}
