import { SEO, Text } from '@truepill/react-capsule'
import { PrescriptionCopayStatusType } from '@vpharm-platform/shared'
import OrderSummary from 'Components/Body/OrderSummary'
import EmptyCart from 'Components/EmptyCart'
import MedicationCard from 'Components/MedicationCard'
import ToastMessage from 'Components/ToastMessage'
import { PRESCRIPTION_MANAGEMENT_PATH } from 'constants/navigation-links'
import { useContentfulCartContent, useContentfulTheme } from 'hooks/contentful'
import { StyledPrescriptionCard } from 'pages/PrescriptionManagement/PrescriptionCard/styledComponents'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { parsePrescriptionStatus } from '../../common/helpers/prescriptions'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { PrescriptionCardMicrocopy } from '../../Components/MedicationCard/microcopy'
import { useCustomerProfile } from '../../hooks'
import { MicroCopyResourceType } from '../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../hooks/contentful/useContentfulMicrocopy'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { Medication } from '../../interfaces'
import { OrderMode } from '../../persistRecoil'
import CartBanner from './CartBanner/CartBanner'
import { useCart } from './hooks/useCart'
import {
  CartContainer,
  CartDetailsContainer,
  OrderSummaryContainer,
  StyledBackLink,
  StyledCheckoutButton,
  StyledHeader,
  StyledMedicationRemoved,
  StyledMedicationRemovedText,
  UndoButton,
  UndoButtonWrapper,
} from './styledComponents'
import { CartBannerMicrocopy } from './types/microcopy'

interface CartBodyProps {
  orderMode: OrderMode
  addedMedicationsInCart: Medication[]
  medsRemoved: Medication[]
  getMedicationByRxNumber: (rxNumber: string) => string
  deleteRemovedItems: () => void
  undoRemove: (rxNumber: string) => void
  remove: (rxNumber: string) => void
}

const CartBody = ({
  orderMode,
  addedMedicationsInCart,
  medsRemoved,
  getMedicationByRxNumber,
  remove,
  undoRemove,
  deleteRemovedItems,
}: CartBodyProps) => {
  const [lastRemovedMed, setLastRemovedMed] = useState('')
  const [showToast, setShowToast] = useState(false)
  const lastRemovedMedName = getMedicationByRxNumber(lastRemovedMed)
  const { isLargerThanTablet } = useWindowDimensions()
  const { microcopy: content } = useContentfulMicrocopy<PrescriptionCardMicrocopy>(MicroCopyResourceType.PrescriptionCard)
  const { theme } = useContentfulTheme()

  useEffect(() => {
    return () => {
      deleteRemovedItems()
    }
  }, [deleteRemovedItems])

  if (addedMedicationsInCart.length === 0) {
    return <EmptyCart medicationsLink={PRESCRIPTION_MANAGEMENT_PATH} />
  }

  const onRemoveFromCart = (rxNumber: string) => {
    setShowToast(true)
    setLastRemovedMed(rxNumber)
    remove(rxNumber)
    localStorage.setItem('idempotencyKey', uuidv4())
  }

  const onUndoRemoveFromCart = (rxNumber: string) => {
    undoRemove(rxNumber)
  }

  return (
    <>
      {addedMedicationsInCart.map((medication) => {
        const prescriptionInfo = parsePrescriptionStatus(medication)

        return (
          <StyledPrescriptionCard.PrescriptionCardContainer key={medication.prescriptionToken}>
            <MedicationCard
              checkoutPrice={
                orderMode === OrderMode.CASH
                  ? Number(medication.unitPrice)
                  : Number(medication.copay?.type === PrescriptionCopayStatusType.SUCCESS && medication.copay?.amount)
              }
              daysSupply={medication.daysSupply?.toString()}
              form={medication.form}
              isLargerThanTablet={isLargerThanTablet}
              isSavingsCardApplied={!!medication.savingsCard.appliedSavingsCard}
              medicationBrandName={medication.brandDisplayName}
              medicationGenericName={medication.genericDisplayName}
              orderMode={orderMode}
              potentialPriceWithSavingsCard={medication.savingsCard.potentialPriceWithSavingsCard}
              refillsRemaining={medication.refillsRemaining}
              retailPrice={medication.retailPrice}
              rxNumber={medication.rxNumber}
              statusType={prescriptionInfo.statusType}
              handleRemoveItemFromCart={() => onRemoveFromCart(medication.rxNumber)}
              totalPriceAfterSavings={medication.totalPriceAfterSavings}
              insurancePrice={medication.insurancePrice}
              totalSavings={medication.totalSavings}
              content={content}
            />
          </StyledPrescriptionCard.PrescriptionCardContainer>
        )
      })}

      {medsRemoved.map(({ rxNumber, brandDisplayName }) => (
        <StyledMedicationRemoved key={brandDisplayName} background={theme.colors['gray-300']}>
          <StyledMedicationRemovedText>
            <Text as='span' style={{ fontWeight: 'bold' }}>
              {brandDisplayName}
            </Text>
            <Text as='span'>&nbsp;removed from cart</Text>
          </StyledMedicationRemovedText>
          <UndoButtonWrapper>
            <UndoButton onClick={() => onUndoRemoveFromCart(rxNumber)} color={theme.colors['functional-info-dark']}>
              Undo
            </UndoButton>
          </UndoButtonWrapper>
        </StyledMedicationRemoved>
      ))}
      {showToast && (
        <ToastMessage
          borderLeft={true}
          color='pastel'
          className='toast-message'
          icon={true}
          onDismiss={() => setShowToast(false)}
          position={{ vertical: 'top', horizontal: 'center' }}
          state={'success'}
          visible={showToast}
          onTimeout={() => setShowToast(false)}
        >{`You removed ${lastRemovedMedName} from your cart`}</ToastMessage>
      )}
    </>
  )
}

const Cart: React.FC = () => {
  const {
    orderMode,
    medicationsInCart,
    removeMed,
    deleteRemovedMeds,
    medsRemoved,
    getMedicationByRxNumber,
    undoRemoveMed,
    priceDetails,
    orderHasControlledSubstance,
  } = useCart()
  const { loading, seo } = useContentfulCartContent()
  const { customerProfile } = useCustomerProfile()
  const { theme } = useContentfulTheme()
  const { microcopy: cartBannerMicroCopy, isLoadingContent } = useContentfulMicrocopy<CartBannerMicrocopy>(MicroCopyResourceType.CartBanner)

  if (loading || isLoadingContent) {
    return <LoadingAnimation size='sm' />
  }

  return (
    <CartContainer>
      {seo?.fields.title && <SEO title={seo.fields.title || customerProfile.customerName} description={seo.fields.description} useDefaults />}
      <CartDetailsContainer>
        <StyledBackLink to={PRESCRIPTION_MANAGEMENT_PATH} color={theme.colors['typography-medium']}>
          « back
        </StyledBackLink>
        <StyledHeader data-testid='cart-header'>Cart</StyledHeader>
        <CartBody
          addedMedicationsInCart={medicationsInCart}
          orderMode={orderMode}
          remove={removeMed}
          undoRemove={undoRemoveMed}
          deleteRemovedItems={deleteRemovedMeds}
          getMedicationByRxNumber={getMedicationByRxNumber}
          medsRemoved={medsRemoved}
        />
        <StyledCheckoutButton
          orderHasControlledSubstance={orderHasControlledSubstance}
          hideForMobile
          isDisabled={!medicationsInCart.length}
          location='cart-body'
        />
      </CartDetailsContainer>
      <OrderSummaryContainer>
        <OrderSummary priceDetails={priceDetails} />
        <StyledCheckoutButton
          orderHasControlledSubstance={orderHasControlledSubstance}
          isDisabled={!medicationsInCart.length}
          fullWidth
          location='cart-order-summary'
        />
        {cartBannerMicroCopy && <CartBanner customerName={customerProfile.customerName} {...cartBannerMicroCopy} />}
      </OrderSummaryContainer>
    </CartContainer>
  )
}

export default Cart
