import { useState } from 'react'

import { transferOutService } from '../../../services/transferOutService'
import { SelectedPrescription } from '../useTransferOut'

interface UsePrescriptionSelectorProps {
  patientToken: string
  customerToken: string
  onPrescriptionSelect: (selectedPrescription: SelectedPrescription | null) => void
  preSelectedRxToken?: string
}

export interface UsePrescriptionReturnValues {
  fetchTransferOutPrescriptionOptions: () => Promise<PrescriptionOption[]>
  setPrescriptionOption: (prescriptionOption: PrescriptionOption | null) => void
  selectedOption: PrescriptionOption | null
}

export interface PrescriptionOption {
  value: string
  rxNumber: string
  label: string
  medName: string
}

export const usePrescriptionSelector = ({
  patientToken,
  customerToken,
  onPrescriptionSelect,
  preSelectedRxToken,
}: UsePrescriptionSelectorProps): UsePrescriptionReturnValues => {
  const [selectedOption, setSelectedOption] = useState<PrescriptionOption | null>(null)

  const fetchTransferOutPrescriptionOptions = async (): Promise<PrescriptionOption[]> => {
    const prescriptions = await transferOutService.getEligibleTransferOutPrescriptions(patientToken, customerToken)
    if (preSelectedRxToken) {
      const selectedRx = prescriptions.find((p) => p.prescriptionToken === preSelectedRxToken)
      if (selectedRx) {
        setSelectedOption({
          label: `${selectedRx.rxNumber && `Rx: ${selectedRx.rxNumber} -`} ${selectedRx.brandName}`,
          value: selectedRx.prescriptionToken,
          rxNumber: selectedRx.rxNumber,
          medName: selectedRx.brandName,
        })
      }
    }
    return prescriptions.map((p) => ({
      label: `${p.rxNumber && `Rx: ${p.rxNumber} -`} ${p.brandName}`,
      value: p.prescriptionToken,
      rxNumber: p.rxNumber,
      medName: p.brandName,
    }))
  }

  const setPrescriptionOption = (prescriptionOption: PrescriptionOption | null): void => {
    setSelectedOption(prescriptionOption)
    if (prescriptionOption?.value) {
      onPrescriptionSelect({
        prescriptionToken: prescriptionOption.value,
        rxNumber: prescriptionOption.rxNumber,
      })
    } else {
      onPrescriptionSelect(null)
    }
  }

  return {
    fetchTransferOutPrescriptionOptions,
    setPrescriptionOption,
    selectedOption,
  }
}
