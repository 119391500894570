import { useEffect, useState } from 'react'

import { type IPrescriptionManagerPageFields, ISeo } from '../../types/generated/contentful'
import { getPageConfiguration } from './contentfulService'
import { getPageMicrocopy } from './helpers'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

type ReturnValues = {
  loading: boolean
  error: boolean
  content?: IPrescriptionManagerPageFields
  seo?: ISeo
}

export const useGetPrescriptionManagerContent = (): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<IPrescriptionManagerPageFields | undefined>()
  const [seo, setSeo] = useState<ISeo | undefined>()
  const customerName = useGetCustomerNameFromSubdomain()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, 'prescriptionManagerPage')
        const microcopy = getPageMicrocopy(page?.fields.microcopy)
        setContent(microcopy as IPrescriptionManagerPageFields)
        setSeo(page?.fields.seo)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName])

  return {
    loading,
    error,
    content,
    seo,
  }
}
