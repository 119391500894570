import { CartBannerMicrocopy } from '../types/microcopy'
import { StyledCartBanner } from './styledComponent'

export type CartBannerProps = CartBannerMicrocopy & {
  customerName: string
}

const CartBanner = ({ cartBannerLogo, cartBannerSubtext, customerName }: CartBannerProps): React.ReactElement => {
  return (
    <StyledCartBanner.Container>
      <StyledCartBanner.MainImage alt={`${customerName} Logo`} src={cartBannerLogo} />
      <StyledCartBanner.Subtext dangerouslySetInnerHTML={{ __html: cartBannerSubtext }} />
    </StyledCartBanner.Container>
  )
}

export default CartBanner
