import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement, ReactNode, useRef } from 'react'

import { useContentfulTheme } from '../../../hooks'
import { CheckedCircle } from './CheckedCircle'
import {
  CheckIconContainer,
  Chip,
  DefaultButton,
  DeleteButton,
  DeleteWrap,
  DetailHeaderContainer,
  Details,
  EditButton,
  HeaderSelectedAndDefaultContainer,
  Icon,
  IsDefaultBadge,
  ItemCardContainer,
  MainWrapper,
  Options,
  OptionsWrap,
  SelectedAndDefaultContainer,
  Wrapper,
} from './styledComponents'

export interface Props {
  itemSubject: string
  heading: string
  line1: ReactNode
  line2?: ReactNode
  line3?: ReactNode
  line4?: ReactNode
  isDefault?: boolean
  defaultChipText?: string
  editButtonText?: string
  deleteButtonText?: string
  setDefault?: () => void
  editHandler?: () => void
  deleteHandler?: () => void
  iconUrl?: string
  onClick?: () => void
  isSelected?: boolean
  activeBorderKey?: 'isDefault' | 'isSelected'
  hidden?: boolean
  isHighlighted?: boolean
}

export const ItemCard = ({
  itemSubject,
  heading,
  line1,
  line2,
  line3,
  line4,
  isDefault,
  defaultChipText = 'Default',
  editButtonText = 'Edit',
  deleteButtonText = 'Delete',
  setDefault,
  editHandler,
  deleteHandler,
  iconUrl,
  onClick,
  isSelected,
  activeBorderKey = 'isDefault',
  hidden = false,
  isHighlighted,
}: Props): ReactElement => {
  const { theme } = useContentfulTheme()
  const elementRef = useRef<HTMLDivElement | null>(null)

  if (isHighlighted && elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <ItemCardContainer data-testid='card' hidden={hidden} ref={elementRef}>
      <MainWrapper
        data-testid='default-border'
        isDefault={isDefault}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        activeBorderKey={activeBorderKey}
        vpTheme={theme}
      >
        <Wrapper onClick={onClick} clickable={!!onClick}>
          <Details>
            <DetailHeaderContainer>
              {iconUrl && (
                <>
                  <Icon src={iconUrl} />
                  <SelectedAndDefaultContainer>
                    {isDefault && (
                      <IsDefaultBadge>
                        <Chip data-testid='isDefault-badge' vpTheme={theme}>
                          {defaultChipText}
                        </Chip>
                      </IsDefaultBadge>
                    )}
                    {isSelected && (
                      <CheckIconContainer>
                        <CheckedCircle ariaLabel={'Item selected'} vpTheme={theme} />
                      </CheckIconContainer>
                    )}
                  </SelectedAndDefaultContainer>
                </>
              )}
            </DetailHeaderContainer>
            <HeaderSelectedAndDefaultContainer>
              <Text data-testid={`${itemSubject}-heading`} bold>
                {heading}
              </Text>
              {!iconUrl && (
                <SelectedAndDefaultContainer>
                  {isDefault && (
                    <IsDefaultBadge>
                      <Chip data-testid='isDefault-badge' vpTheme={theme}>
                        {defaultChipText}
                      </Chip>
                    </IsDefaultBadge>
                  )}
                  {isSelected && (
                    <CheckIconContainer>
                      <CheckedCircle ariaLabel={'Item selected'} vpTheme={theme} />
                    </CheckIconContainer>
                  )}
                </SelectedAndDefaultContainer>
              )}
            </HeaderSelectedAndDefaultContainer>
            <Spacer size='xs' />
            <span data-testid={`${itemSubject}-line1`}>{line1}</span>
            <Spacer size='2xs' />
            {line2 && (
              <span data-testid={`${itemSubject}-line2`}>
                {line2}
                <Spacer size='2xs' />
              </span>
            )}
            {line3 && (
              <span data-testid={`${itemSubject}-line3`}>
                {line3}
                <Spacer size='2xs' />
              </span>
            )}
            {line4 && <span data-testid={`${itemSubject}-line4`}>{line4}</span>}
            <Spacer size='sm' />
          </Details>
        </Wrapper>
        {(setDefault || editHandler || deleteHandler) && (
          <Options data-testid={`${itemSubject}-buttons`} vpTheme={theme}>
            <OptionsWrap>
              {setDefault && (
                <DefaultButton
                  data-testid='click-default-btn'
                  role='button'
                  aria-label='set as default'
                  onClick={setDefault}
                  disabled={isDefault}
                  isDefault={isDefault}
                  vpTheme={theme}
                >
                  Set as default
                </DefaultButton>
              )}
              {editHandler && (
                <EditButton data-testid='click-edit-btn' role='button' onClick={editHandler} aria-label={`edit ${itemSubject}`} vpTheme={theme}>
                  {editButtonText}
                </EditButton>
              )}
            </OptionsWrap>
            {deleteHandler && (
              <DeleteWrap>
                <DeleteButton
                  data-testid='click-delete-btn'
                  role='button'
                  onClick={deleteHandler}
                  aria-label={`delete ${itemSubject}`}
                  isDefault={isDefault}
                  vpTheme={theme}
                >
                  {deleteButtonText}
                </DeleteButton>
              </DeleteWrap>
            )}
          </Options>
        )}
      </MainWrapper>
    </ItemCardContainer>
  )
}
