import { CardExpiryElement } from '@stripe/react-stripe-js'
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js'
import React, { PropsWithChildren, ReactElement, useState } from 'react'

import { useContentfulTheme } from '../../hooks'
import FieldContainer from './common/FieldContainer'
import StripeElementContainer from './common/StripeElementContainer'
import StripeElementLabel from './common/StripeElementLabel'

interface Props {
  onChange: (isComplete: boolean) => void
}

const CreditCardExpiration = (props: PropsWithChildren<Props>): ReactElement => {
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)
  const { theme } = useContentfulTheme()

  const onChange = (event: StripeCardExpiryElementChangeEvent) => {
    if (event.error) {
      setError(true)
    } else {
      setError(false)
    }
    props.onChange(event.complete)
  }

  return (
    <FieldContainer>
      <StripeElementLabel required={false} vpTheme={theme}>
        Expiration date
      </StripeElementLabel>
      <StripeElementContainer isFocus={focused} error={error} isDisabled={false}>
        <CardExpiryElement
          onBlur={() => setFocused(false)}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          options={{
            placeholder: 'MM/YY',
            style: { base: { fontFamily: 'Lato', fontSize: '16px' } },
          }}
        />
      </StripeElementContainer>
    </FieldContainer>
  )
}

export default CreditCardExpiration
