import { LockIcon } from 'assets/Icons'
import { useContentfulTheme, usePatientProfile } from 'hooks'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAnalytics } from '../../hooks/analytics-context'
import { CheckoutButton, LockIconWrapper, SecureCheckoutText } from './styledComponents'

interface SecureCheckoutButtonProps {
  location: string
  className?: string
  isDisabled?: boolean
  fullWidth?: boolean
  orderHasControlledSubstance: boolean
}

const SecureCheckoutButton: React.FC<SecureCheckoutButtonProps> = ({
  isDisabled: disabled,
  className,
  fullWidth,
  location,
  orderHasControlledSubstance,
}) => {
  const history = useHistory()
  const { patientProfile } = usePatientProfile()
  const { theme } = useContentfulTheme()
  const { trackButtonClickEvent } = useAnalytics()

  const SecureCheckoutButtonOnClick = () => {
    trackButtonClickEvent(`checkout-from-${location}`, ' Secure Checkout')

    // Need to check if the order contains controlled substance
    if (!patientProfile?.idUploaded && orderHasControlledSubstance) {
      history.push('/checkout/upload-government-id')
    } else {
      history.push('/checkout/patient-urac')
    }
  }

  return (
    <CheckoutButton role='button' onClick={SecureCheckoutButtonOnClick} {...{ className, disabled, fullWidth }} vpTheme={theme}>
      <LockIconWrapper>
        <LockIcon vpTheme={theme} />
      </LockIconWrapper>
      <SecureCheckoutText data-testid='secure-checkout-button' vpTheme={theme}>
        Secure checkout
      </SecureCheckoutText>
    </CheckoutButton>
  )
}

SecureCheckoutButton.defaultProps = {
  isDisabled: false,
  fullWidth: false,
}

export default SecureCheckoutButton
