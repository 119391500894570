import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const PrefilledSyringe = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405944)'>
        <path d='M58.8202 31.5151H37.2002V38.4751H58.8202V31.5151Z' fill={vpTheme.colors.grayWhite} />
        <path
          d='M59.3302 38.9751H36.7002V31.0151H59.3202V38.9751H59.3302ZM37.7102 37.9751H58.3302V32.0151H37.7002V37.9751H37.7102Z'
          fill={vpTheme.colors.iconDefault}
        />
        <path d='M37.1998 37.115L33.5698 36.485V33.515L37.1998 32.885V37.115Z' fill={vpTheme.colors['gray-300']} />
        <path
          d='M37.7001 37.7049L33.0601 36.9049V33.0849L37.7001 32.2849V37.7049V37.7049ZM34.0601 36.0649L36.7001 36.5249V33.4749L34.0601 33.9349V36.0649Z'
          fill={vpTheme.colors.iconDefault}
        />
        <path d='M33.5701 33.5449H31.6001V36.4349H33.5701V33.5449Z' fill={vpTheme.colors['gray-300']} />
        <path
          d='M34.0701 36.9349H31.1001V33.0449H34.0701V36.9349ZM32.1001 35.9349H33.0701V34.0449H32.1001V35.9349Z'
          fill={vpTheme.colors.iconDefault}
        />
        <path d='M31.6001 34.4951H21.6201V35.4951H31.6001V34.4951Z' fill={vpTheme.colors.iconDefault} />
        <path
          d='M62.1101 31.515H58.8301V38.475H62.1101C63.4301 38.475 64.6101 39.355 64.9301 40.645C65.7601 44.045 67.3301 46.345 69.1201 46.345V23.655C67.3301 23.655 65.7601 25.945 64.9301 29.355C64.6201 30.635 63.4401 31.525 62.1101 31.525V31.515Z'
          fill={vpTheme.colors['gray-300']}
        />
        <path
          d='M69.6201 46.845H69.1201C67.1201 46.845 65.3801 44.575 64.4401 40.765C64.1801 39.715 63.2201 38.975 62.1101 38.975H58.3301V31.015H62.1101C63.2201 31.015 64.1801 30.285 64.4401 29.225C65.3701 25.415 67.1201 23.145 69.1201 23.145H69.6201V46.845ZM59.3301 37.975H62.1101C63.6901 37.975 65.0401 39.025 65.4101 40.525C66.1101 43.385 67.3301 45.355 68.6201 45.765V24.235C67.3401 24.645 66.1201 26.615 65.4101 29.475C65.0401 30.975 63.6901 32.025 62.1101 32.025H59.3301V37.985V37.975Z'
          fill={vpTheme.colors.iconDefault}
        />
        <path
          d='M91.7501 28.915C91.6901 28.915 91.6201 28.925 91.5601 28.935V28.915C90.6501 28.915 89.8601 30.075 89.4401 31.795C89.3001 32.375 88.8001 32.795 88.2101 32.875H69.1201V36.425H88.2101C88.8001 36.505 89.2901 36.925 89.4401 37.505C89.8601 39.225 90.6501 40.385 91.5601 40.385V40.365C91.6201 40.375 91.6901 40.385 91.7501 40.385C92.1601 40.385 92.5501 40.125 92.8801 39.665V29.635C92.5401 29.175 92.1601 28.915 91.7501 28.915V28.915Z'
          fill={vpTheme.colors['gray-300']}
        />
        <path
          d='M91.7503 40.895C91.7503 40.895 91.6903 40.895 91.6503 40.895H91.5604C90.4304 40.895 89.4504 39.675 88.9504 37.635C88.8604 37.265 88.5403 36.985 88.1403 36.925H68.6104V32.375H88.2004C88.5304 32.325 88.8503 32.045 88.9403 31.675C89.4403 29.635 90.4104 28.405 91.5504 28.405L92.0504 28.435C92.4104 28.515 92.8504 28.745 93.2804 29.325L93.3804 29.455V39.835L93.2804 39.965C92.8504 40.545 92.4104 40.775 92.0504 40.855L91.9804 40.865C91.8904 40.875 91.8104 40.885 91.7404 40.885L91.7503 40.895ZM91.2603 39.815L91.6604 39.885C91.9604 39.925 92.2104 39.705 92.3804 39.505V29.805C92.1904 29.575 91.9203 29.385 91.6503 29.425L91.2304 29.505C90.7204 29.785 90.2303 30.685 89.9303 31.915C89.7403 32.695 89.0904 33.265 88.2804 33.375H69.6203V35.935H88.2103C89.0903 36.045 89.7304 36.625 89.9204 37.395C90.2304 38.645 90.7403 39.555 91.2503 39.815H91.2603Z'
          fill={vpTheme.colors.iconDefault}
        />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke={vpTheme.colors['gray-300']} />
      <defs>
        <clipPath id='clip0_19220_405944'>
          <rect width='71.76' height='23.69' fill={vpTheme.colors.grayWhite} transform='translate(21.6201 23.155)' />
        </clipPath>
      </defs>
    </svg>
  )
}
