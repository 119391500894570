import { Header, Text } from '@truepill/react-capsule'

import PillBannerLayout from '../../../Components/Banner/PillBannerLayout'
import { useContentfulTheme } from '../../../hooks'
import { InsuranceBannerMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { ContentContainer, HeaderContainer, Icon, InsuranceBannerContainer } from './styledComponents'

export type InsuranceBannerProps = InsuranceBannerMicrocopy

const InsuranceBanner = ({ mainHeader, subtext, iconUrl, backgroundColor }: InsuranceBannerProps): React.ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <InsuranceBannerContainer>
      <PillBannerLayout isPillReversed backgroundColor={backgroundColor} maxWidth='625px'>
        <HeaderContainer vpTheme={theme}>
          {iconUrl && <Icon src={iconUrl} />}
          {mainHeader && (
            <Header variant='2xl' bold>
              {mainHeader}
            </Header>
          )}
        </HeaderContainer>
        <ContentContainer vpTheme={theme}>{subtext && <Text>{<div dangerouslySetInnerHTML={{ __html: subtext }} />}</Text>}</ContentContainer>
      </PillBannerLayout>
    </InsuranceBannerContainer>
  )
}

export default InsuranceBanner
