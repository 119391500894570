import { EstimatedDeliveryWindow, OrderDetails } from '@vpharm-platform/shared'
import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { PRESCRIPTION_MANAGEMENT_PATH } from '../../../../../constants'
import { PaymentCardType, useCustomerProfile, useUpdateCart, useUserPayment, useUserProfile } from '../../../../../hooks'
import { MicroCopyResourceType } from '../../../../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../../../../hooks/contentful/useContentfulMicrocopy'
import { selectedPatientTokenAtom } from '../../../../../persistRecoil'
import { orderService } from '../../../../../services'

interface OrderConfirmationMedication {
  brandDisplayName: string | null
  genericDisplayName: string | null
  rxNumber: string
  retailPrice: number | null
  copayPrice: number | null
  priceWithSavings: number | null
  savingsAmount: number | null
  copayAmount: number | null
}

export interface UseOrderConfirmationReturnType {
  shipmentInfo: {
    estimatedArrivalTime: string | EstimatedDeliveryWindow | null
    shipmentPrice: number
  }
  orderDetails: {
    total: number | null
    subtotal: number | null
    taxAmount: number | null
    orderToken: string | null
    isAutoRefillEligible: boolean
    medications: OrderConfirmationMedication[]
  }
  patientInfo: {
    firstName: string
  }
  paymentInfo: {
    brand: string | null
    last4: string | null
  }
  isLoading: boolean
  clearCart: () => void
  setSelectedPayment: (card: PaymentCardType) => void
  dynamicContent?: OrderConfirmationMicrocopy
  allowPriceTransparency: boolean
}

export interface OrderConfirmationMicrocopy {
  savingsPriceText: string | null
}

export const useOrderConfirmation = (): UseOrderConfirmationReturnType => {
  const [orderInfo, setOrderInfo] = useState<OrderDetails | null>(null)
  const [isOrderLoading, setIsOrderLoading] = useState(false)
  const { customerProfile } = useCustomerProfile()
  const allowPriceTransparency = customerProfile.allowPriceTransparency
  const { params } = useRouteMatch<{ order_id: string }>()
  const history = useHistory()
  const { microcopy: orderConfirmationPageContent, isLoadingContent } = useContentfulMicrocopy<OrderConfirmationMicrocopy>(
    MicroCopyResourceType.OrderConfirmationPage,
  )

  const { authorizedPatients } = useUserProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)

  const { setSelectedPayment } = useUserPayment()
  const { clearCart } = useUpdateCart()

  useEffect(() => {
    if (!params.order_id) {
      return history.push(PRESCRIPTION_MANAGEMENT_PATH)
    }

    const fetchOrderDetails = async () => {
      setIsOrderLoading(true)
      try {
        const orderData = await orderService.getOrderById({
          orderId: params.order_id,
          customerToken: customerProfile.vpharmCustomerToken,
          patientToken: selectedPatientToken,
        })
        setOrderInfo(orderData)
      } finally {
        setIsOrderLoading(false)
      }
    }

    fetchOrderDetails()
  }, [params.order_id, history, customerProfile.vpharmCustomerToken, selectedPatientToken])

  return {
    isLoading: isLoadingContent || isOrderLoading,
    shipmentInfo: {
      estimatedArrivalTime: orderInfo?.shipping.eta ?? null,
      shipmentPrice: orderInfo?.price_summary.shipping ?? 0,
    },
    orderDetails: {
      total: orderInfo?.price_summary.total ?? null,
      subtotal: orderInfo?.price_summary.subtotal ?? null,
      taxAmount: orderInfo?.price_summary.tax ?? null,
      orderToken: orderInfo?.order_identifier ?? null,
      isAutoRefillEligible: orderInfo?.is_auto_refill_eligible ?? false,
      medications:
        orderInfo?.items.map((med) => ({
          rxNumber: med.rx_number,
          brandDisplayName: med.brand_display_name ?? null,
          genericDisplayName: med.generic_display_name ?? null,
          retailPrice: med.retail_price,
          copayPrice: med.insurance_copay_amount,
          priceWithSavings: med.total_price_after_savings,
          savingsAmount: med.savingsAmount,
          copayAmount: med.copay_amount,
        })) ?? [],
    },
    patientInfo: {
      firstName: authorizedPatients?.[selectedPatientToken]?.firstName ?? '',
    },
    paymentInfo: {
      brand: orderInfo?.payment_method.brand ?? null,
      last4: orderInfo?.payment_method.last4 ?? null,
    },
    clearCart,
    setSelectedPayment,
    dynamicContent: orderConfirmationPageContent,
    allowPriceTransparency,
  }
}
