import '@reach/tooltip/styles.css'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import './styles/styles.css'

import { datadogRum } from '@datadog/browser-rum'
import { GlobalStyles, ResetStyles } from 'common/styles'
import Header from 'Components/Header'
import Layout from 'Components/Layout'
import LoadingAnimation from 'Components/LoadingAnimation'
import Profile from 'Components/Profile'
import ThemeProvider from 'Components/ThemeProvider'
import { LDEvaluationDetail, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'
import CartExpirationCheck from 'pages/Cart/CartExpirationCheck'
import SlidingCart from 'pages/Cart/SlidingCart'
import React, { lazy, PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import Routes from 'Routes'

import { BigErrorComponent } from './Components/BigErrorComponent'
import CustomerConfigProvider, { useCustomerConfigContext } from './Components/CustomerConfigProvider'
import { ErrorComponent } from './Components/ErrorComponent'
import LDProvider, { useLDContextWithLocalStorage } from './Components/LDProvider'
import SessionTimeout from './Components/SessionTimeout'
import { VpErrorBoundary } from './Components/VpErrorBoundary'
import { DISPLAY_MAINTENANCE_PAGE } from './constants'
import { AuthProvider, useAuth } from './context/auth-context'
import { useContentfulTheme } from './hooks'
import { AnalyticsProvider, useAnalytics } from './hooks/analytics-context'
import { useFive9 } from './hooks/usefive9'
import MaintenancePage from './pages/Maintenance'
import { BackgroundColor, WidthLimiter } from './styledComponents'
import { getSubdomain } from './utils'

const Footer = lazy(() => import('Components/Footer'))

const isLDset = !!process.env.REACT_APP_LD_CLIENT_SIDE_ID

type ProfileControllerProps = {
  children?: React.ReactNode
}

const LaunchDarklyIdentityProvider = ({ children }: PropsWithChildren<any>) => {
  const {
    authState: { user },
  } = useAuth()
  const ldClient = useLDClient()

  useEffect(() => {
    if (user && ldClient) {
      ldClient.identify({
        key: user.sub,
        custom: {
          customer: getSubdomain(),
        },
      })
    }
  }, [ldClient, user])

  return <>{children}</>
}

const ProfileController: React.FC = ({ children }: ProfileControllerProps) => {
  const {
    getAccessTokenSilently,
    authState: { isAuthenticated, isLoading, user },
  } = useAuth()
  const { isLoading: isAnalyticsLoading } = useAnalytics()

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({ cacheMode: false })
    }
    if (user) {
      datadogRum.setUser({
        id: user.sub,
      })
    }
  }, [getAccessTokenSilently, isAuthenticated, user])

  if (isLoading || isAnalyticsLoading) {
    return <LoadingAnimation />
  }

  return <Profile>{children}</Profile>
}

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const Favicon: React.FC = () => {
  const { pharmCustomer } = useCustomerConfigContext()

  useEffect(() => {
    if (pharmCustomer?.favicon) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement
      if (favicon) {
        favicon.href = `https:${pharmCustomer.favicon.fields.file.url}`
      }
    }
  }, [pharmCustomer])

  return null
}

const MaintenanceAware = ({ children }: { children: React.ReactElement }) => {
  const ldClient = useLDClient()
  const [ldInitialized, setLDInitialized] = useState(false)
  const { [DISPLAY_MAINTENANCE_PAGE]: displayMaintenancePage } = useLDContextWithLocalStorage()

  useEffect(() => {
    if (ldClient) {
      const listenForLDInitialization = async () => {
        try {
          await ldClient.waitForInitialization()
        } catch (err) {
        } finally {
          setLDInitialized(true)
        }
      }
      listenForLDInitialization()
    }
  }, [ldClient])

  if (isLDset && !ldInitialized) {
    return null
  }

  if (displayMaintenancePage) {
    return <MaintenancePage />
  }

  return children
}

const AppContent: React.FC = () => {
  const { theme } = useContentfulTheme()
  useFive9()
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<LoadingAnimation />}>
        <AnalyticsProvider>
          <AuthProvider>
            <LaunchDarklyIdentityProvider>
              <ProfileController>
                <BackgroundColor background={theme.colors['gray-100']}>
                  <WidthLimiter vpTheme={theme}>
                    <Header />
                    <Layout>
                      <VpErrorBoundary fallback={<ErrorComponent />}>
                        <Routes />
                      </VpErrorBoundary>
                    </Layout>
                    <Suspense fallback={<></>}>
                      <Footer />
                    </Suspense>
                  </WidthLimiter>
                </BackgroundColor>
                <SlidingCart />
              </ProfileController>
              <SessionTimeout />
              <CartExpirationCheck />
            </LaunchDarklyIdentityProvider>
          </AuthProvider>
        </AnalyticsProvider>
      </Suspense>
    </Router>
  )
}

const App: React.FC = () => {
  datadogRum.startView()
  return (
    <VpErrorBoundary fallback={<BigErrorComponent />}>
      <Suspense fallback={<></>}>
        <LDProvider>
          <CustomerConfigProvider>
            <Favicon />
            <ResetStyles />
            <ThemeProvider>
              <GlobalStyles />
              <MaintenanceAware>
                <AppContent />
              </MaintenanceAware>
            </ThemeProvider>
          </CustomerConfigProvider>
        </LDProvider>
      </Suspense>
    </VpErrorBoundary>
  )
}

const appExport = isLDset
  ? withLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string,
      options: {
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value)
            },
          },
        ],
      },
    })(App as React.ComponentType)
  : (App as React.ComponentType)

export default appExport
