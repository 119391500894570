import { Header } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'
import { PlusCircle } from '../Fragments/ButtonCard'

const Wrapper = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  border-radius: 0.5rem;
`

const StyledHeader = styled(Header)`
  padding-right: 3.125rem;
  max-width: 360px;

  ${mediaLargerThan.tablet} {
    max-width: 420px;
  }
`

const StyledActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  ${mediaLargerThan.mobile} {
    flex-flow: row;
    justify-content: flex-end;
  }
`

const LoadingAnimationWrapper = styled.div<ThemedComponent>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite}
  opacity: 0.2;
  z-index: 1000;
`

const StyledPlusCircle = styled(PlusCircle)<ThemedComponent>`
  width: 22.5px;
  margin-right: 0.625rem;
  top: 0.375rem;
  position: relative;
  color: ${({ vpTheme }) => vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
  display: inline-block;
`

export { LoadingAnimationWrapper, StyledActionContainer, StyledHeader, StyledPlusCircle, Wrapper }
