import { useRecoilState, useResetRecoilState } from 'recoil'

import { adminLogin, selectedPatientTokenAtom, userProfileAtom, userProfileState } from '../persistRecoil'

export const useResetBeforeLogout = (): { resetBeforeLogout: () => void } => {
  const resetPatientToken = useResetRecoilState(selectedPatientTokenAtom)
  const [, setIsAdminLogin] = useRecoilState(adminLogin)
  const resetUserProfileAtom = useResetRecoilState(userProfileAtom)
  const resetUserProfileState = useResetRecoilState(userProfileState)

  const resetBeforeLogout = () => {
    setIsAdminLogin(false)
    sessionStorage.clear()
    localStorage.clear()
    resetPatientToken()
    resetUserProfileAtom()
    resetUserProfileState()
  }
  return {
    resetBeforeLogout,
  }
}
