import { Header, Spacer, Text } from '@truepill/react-capsule'
import { sharedConstants } from '@vpharm-platform/shared'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { breakpoint } from '../../common/styles/variables'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'

const { RoutePaths } = sharedConstants

const ActionConfirmation: React.FC = () => {
  const history = useHistory()
  const { params } = useRouteMatch<{ action: string }>()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const handleContinue = () => {
    trackButtonClickEvent('landing_message_continue', 'Continue')
    history.replace(RoutePaths.PRESCRIPTION_MANAGEMENT_PATH)
  }

  if (!params.action) {
    return <Redirect to={RoutePaths.PRESCRIPTION_MANAGEMENT_PATH} />
  }

  return (
    <Container>
      <Header variant='4xl'>Thanks for your response!</Header>
      <Spacer size='lg' />
      <Text>Now you can continue to the site.</Text>
      <Spacer size='2xl' />
      <ButtonContainer>
        <ThemedButton onClick={handleContinue} vpTheme={theme}>
          Continue
        </ThemedButton>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 670px;
  padding: 0 24px;
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  margin-top: 10rem;

  ${breakpoint.mobile} {
    margin-top: 2rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

export default ActionConfirmation
