import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const Vial = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405947)'>
        <path
          d='M55.835 21.0649H59.175C63.585 21.0649 67.175 24.6449 67.175 29.0649V55.3049C67.175 56.5549 66.155 57.5749 64.905 57.5749H50.105C48.855 57.5749 47.835 56.5549 47.835 55.3049V29.0649C47.835 24.6549 51.415 21.0649 55.835 21.0649Z'
          fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
        />
        <path
          d='M64.905 58.0749H50.105C48.575 58.0749 47.335 56.8349 47.335 55.3049V29.0649C47.335 24.3749 51.145 20.5649 55.835 20.5649H59.175C63.865 20.5649 67.675 24.3749 67.675 29.0649V55.3049C67.675 56.8349 66.435 58.0749 64.905 58.0749ZM55.835 21.5649C51.705 21.5649 48.335 24.9249 48.335 29.0649V55.3049C48.335 56.2749 49.125 57.0749 50.105 57.0749H64.905C65.875 57.0749 66.675 56.2849 66.675 55.3049V29.0649C66.675 24.9349 63.315 21.5649 59.175 21.5649H55.835Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M61.5451 17.9651H53.4551V21.4051H61.5451V17.9651Z' fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
        <path
          d='M62.0451 21.9051H52.9551V17.4651H62.0451V21.9051ZM53.9551 20.9051H61.0451V18.4651H53.9551V20.9051Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M63.2249 12.425H51.7749V17.965H63.2249V12.425Z' fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
        <path
          d='M63.7249 18.465H51.2749V11.925H63.7249V18.465ZM52.2749 17.465H62.7249V12.925H52.2749V17.465Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M67.095 28.5452H47.835V53.3452H67.095V28.5452Z' fill={vpTheme.colors.grayWhite} />
        <path
          d='M67.595 53.8452H47.335V28.0452H67.595V53.8452ZM48.335 52.8452H66.595V29.0452H48.335V52.8452Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
      <defs>
        <clipPath id='clip0_19220_405947'>
          <rect width='20.33' height='46.15' fill={vpTheme.colors.grayWhite} transform='translate(47.335 11.925)' />
        </clipPath>
      </defs>
    </svg>
  )
}
