import { Header, Text } from '@truepill/react-capsule'
import { formatToPhone, VpTheme } from '@vpharm-platform/shared'
import { DateTime } from 'luxon'

import { TransferInArrow, TransferOut } from '../../../../assets/Icons'
import { useContentfulTheme } from '../../../../hooks'
import { TransferMedication, TransferMedicationStatus } from '../types'
import { StyledPrescriptionTransferCard } from './styledComponents'

interface PrescriptionTransferCardProps {
  med: TransferMedication
}

const parseTransferStatus = (status: TransferMedicationStatus, theme: VpTheme): React.ReactElement => {
  let transferStatusText: string
  switch (status) {
    case 'COMPLETED':
      transferStatusText = 'Complete'
      break
    case 'PENDING':
      transferStatusText = 'In progress'
      break
    case 'REJECTED':
      transferStatusText = 'Failed'
      break
  }

  return (
    <StyledPrescriptionTransferCard.StatusChip
      $backgroundPendingColor={theme.colors['functional-info-dark']}
      $backgroundRejectedColor={theme.colors['functional-error-dark']}
      $backgroundCompletedColor={theme.colors['functional-success-dark']}
      status={status}
    >
      {transferStatusText}
    </StyledPrescriptionTransferCard.StatusChip>
  )
}

const PrescriptionTransferCard = ({ med }: PrescriptionTransferCardProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const formattedMedName = `${med.medicationName.split(' ')[0]} ${med.strength}`

  return (
    <StyledPrescriptionTransferCard.Container borderColor={theme.colors['gray-300']} boxShadowColor={theme.colors['gray-300']}>
      <StyledPrescriptionTransferCard.HeaderContainer>
        <StyledPrescriptionTransferCard.MedNameContainer>
          <div>
            <Header css={{ lineHeight: '1.625rem' }}>{formattedMedName}</Header>
            {med.genericName && (
              <StyledPrescriptionTransferCard.TransferCardTextStyle lineHeight={'1.25rem'} fontSize={'0.875rem'} marginBottom={'0.625rem'}>
                Generic: {med.genericName}
              </StyledPrescriptionTransferCard.TransferCardTextStyle>
            )}
          </div>
          <StyledPrescriptionTransferCard.TransferCardTextStyle fontWeight={'700'} fontSize={'0.875rem'}>
            {parseTransferStatus(med.status, theme)}
          </StyledPrescriptionTransferCard.TransferCardTextStyle>
        </StyledPrescriptionTransferCard.MedNameContainer>
        <StyledPrescriptionTransferCard.TransferCardTextStyle lineHeight={'1.25rem'} fontSize={'0.875rem'}>
          Transfer #{med.transferToken} • {DateTime.fromJSDate(med.transferDate).toFormat('dd LLL yyyy')}
        </StyledPrescriptionTransferCard.TransferCardTextStyle>
      </StyledPrescriptionTransferCard.HeaderContainer>
      <StyledPrescriptionTransferCard.PharmacyInfo borderColor={theme.colors['gray-300']} fillColor={theme.colors['typography-dark']}>
        <StyledPrescriptionTransferCard.TypeText>
          {med.type === 'TRANSFER_OUT' ? (
            <>
              <TransferOut vpTheme={theme} />
              <Text bold>Transfer to:</Text>
            </>
          ) : (
            <>
              <TransferInArrow vpTheme={theme} />
              <Text bold>Transfer from:</Text>
            </>
          )}
        </StyledPrescriptionTransferCard.TypeText>
        <Text style={{ fontWeight: '400', fontSize: '1rem', lineHeight: '1.5rem' }}>
          {med.pharmacy.name} {med.pharmacy.address && '• '} {med.pharmacy.address} {med.pharmacy.phone && '• '}
          {med.pharmacy.phone && (
            <StyledPrescriptionTransferCard.PharmacyNumber color={theme.colors['functional-info-dark']} href={`tel:+${med.pharmacy.phone}`}>
              {formatToPhone(med.pharmacy.phone)}
            </StyledPrescriptionTransferCard.PharmacyNumber>
          )}
        </Text>
      </StyledPrescriptionTransferCard.PharmacyInfo>
    </StyledPrescriptionTransferCard.Container>
  )
}

export default PrescriptionTransferCard
