import { Divider } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../common/styledComponents/types'
import { mediaLargerThan } from '../../common/styles/variables'

export const BorderBox = styled.div<ThemedComponent>`
  box-sizing: border-box;

  padding: 0;
  margin: 1rem 0;

  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
  border-radius: 0.5rem;
  overflow: hidden;
`

export const GeneralInformationContainer = styled.div<ThemedComponent>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  width: 100%;

  flex: none;
  order: 1;
  flex-grow: 1;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

export const Separator = styled(Divider)<ThemedComponent>`
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  width: 100%;

  border: 0.5px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
`

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;

  ${mediaLargerThan.mobile} {
    flex-direction: row;
    align-items: center;
  }
`

export const CheckboxContainer = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
`
