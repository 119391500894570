import { Text } from '@truepill/react-capsule'

import { OrderMode } from '../../../../persistRecoil/orderMode'
import { StyledPrescriptionPrice } from './styledComponents'

interface PrescriptionPriceProps {
  checkoutPrice: number
  retailPrice: number | null
  savingsCardPrice: number | null
  orderMode: OrderMode
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

interface InsurancePriceViewProps {
  checkoutPrice: number
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

interface CashPriceViewProps {
  checkoutPrice: number
  savingsCardPrice: number | null
  retailPrice: number | null
  // below prop will be removed after we remove ff for price transparency
  allowPriceTransparency: boolean
}

const InsurancePriceView = ({ checkoutPrice, allowPriceTransparency }: InsurancePriceViewProps): React.ReactElement => (
  <StyledPrescriptionPrice.CheckoutPriceHeader data-testid='insurancePrice' allowPriceTransparency={allowPriceTransparency}>
    ${checkoutPrice.toFixed(2)}
  </StyledPrescriptionPrice.CheckoutPriceHeader>
)

const CashPriceView = ({ checkoutPrice, savingsCardPrice, retailPrice, allowPriceTransparency }: CashPriceViewProps): React.ReactElement => {
  // No savings card was applied to the medication
  const isMedPriceEqualToRetailPrice = checkoutPrice === retailPrice

  if (!savingsCardPrice || !isMedPriceEqualToRetailPrice) {
    return (
      <StyledPrescriptionPrice.CheckoutPriceHeader allowPriceTransparency={allowPriceTransparency}>
        ${checkoutPrice.toFixed(2)}
      </StyledPrescriptionPrice.CheckoutPriceHeader>
    )
  }

  return (
    <>
      <StyledPrescriptionPrice.CheckoutPriceHeader allowPriceTransparency={allowPriceTransparency}>
        ${savingsCardPrice.toFixed(2)}
      </StyledPrescriptionPrice.CheckoutPriceHeader>
      <Text variant='body' bold>
        with savings card*
      </Text>
      <StyledPrescriptionPrice.RetailPriceText variant='body'>{`cash price: $${retailPrice.toFixed(2)}`}</StyledPrescriptionPrice.RetailPriceText>
    </>
  )
}

const PrescriptionPrice = ({
  checkoutPrice,
  orderMode,
  retailPrice,
  savingsCardPrice,
  allowPriceTransparency,
}: PrescriptionPriceProps): React.ReactElement => {
  const renderPriceView = () => {
    switch (orderMode) {
      case OrderMode.INSURANCE:
        return <InsurancePriceView checkoutPrice={checkoutPrice} allowPriceTransparency={allowPriceTransparency} />
      case OrderMode.CASH:
        return (
          <CashPriceView
            checkoutPrice={checkoutPrice}
            retailPrice={retailPrice}
            savingsCardPrice={savingsCardPrice}
            allowPriceTransparency={allowPriceTransparency}
          />
        )
    }
  }

  return <div data-testid='prescription-price'>{renderPriceView()}</div>
}

export default PrescriptionPrice
