import { Grid, GridItem } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { ThemedComponent } from '../../common/styledComponents/types'
import { media } from '../../common/styles/variables'

const SearchBarWrapper = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['functional-warning-light'] ?? defaultTheme.colors['functional-warning-light']};
  width: 100%;
`

const SearchContainer = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 50% auto;
  grid-column-gap: 1em;
  align-items: end;
  align-self: baseline;
  justify-content: left;
  width: 100%;
  margin 0 auto;
  @media (min-width: ${media.desktopSm}) {
    max-width: 70rem;
  }
`
const SearchResultContainer = styled.div`
  width: 100%;
  margin 0 auto;
  @media (min-width: ${media.desktopSm}) {
    max-width: 70rem;
  }
`

const SelectedPatientInfo = styled.div`
  padding: 0 16px;
`

const SelectedPatientDetails = styled.div`
  display: flex;
`

const StyledDropdownContainer = styled(Grid)`
  padding: 0 16px;
`

const StyledDropdownElement = styled(GridItem)<ThemedComponent>`
  padding: 8px;
  min-width: 230px;
  font-size: 14px;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-300']};
  :nth-child(even) {
    background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
  }
  :first-of-type {
    padding: 0 8px;
    border-bottom: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  }
`

const StyledDropdownButton = styled.div<ThemedComponent>`
  padding: 16px;
  display: flex;
  svg {
    margin: auto 8px;
    path {
      stroke: ${({ vpTheme }) => vpTheme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']};
    }
  }
`

const ElementHeader = styled.div`
  display: grid;
  grid-template-columns: 75% 15% auto;
  p,
  a {
    margin: auto 0;
  }
`

const TextLink = styled.a<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']};
  text-decoration-line: underline;
  cursor: pointer;
`

export {
  ElementHeader,
  SearchBarWrapper,
  SearchContainer,
  SearchResultContainer,
  SelectedPatientDetails,
  SelectedPatientInfo,
  StyledDropdownButton,
  StyledDropdownContainer,
  StyledDropdownElement,
  TextLink,
}
