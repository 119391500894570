import { sharedConstants, VpTheme } from '@vpharm-platform/shared'

import { Book, CartIconMobile, CogIcon, CreditCard, FileText, RightLeftArrow, RxIcon, Shield, Shipping } from '../assets/Icons'
import { ArrowLoop } from '../assets/Icons/ArrowLoop'
import { CustomerProfile } from '../interfaces'
import { NavigationLink } from '../interfaces/Header'

const { RoutePaths } = sharedConstants

export const {
  ABOUT_PATH,
  ACCOUNT_ADDRESSES_PATH,
  ACCOUNT_PAYMENTS_PATH,
  ACCOUNT_CREATION_PATH,
  ACCOUNT_INSURANCE_PATH,
  CART_PATH,
  CHECKOUT_PATH,
  CONTACT_PATH,
  FAQ_PATH,
  FORGOT_PASSWORD_PATH,
  HIPAA_PRACTICES_PATH,
  IDENTITY_VERIFICATION,
  ID_UPLOAD_PATH,
  INFORMED_CONSENT_PATH,
  LOGIN_PATH,
  NOT_FOUND_PATH,
  ORDERS_PATH,
  ORDER_CONFIRMATION_PATH,
  ORDER_DETAILS_PATH,
  OUTGOING_INFO_PATH,
  PATIENT_RECORD_VERIFICATION_PATH,
  PRESCRIPTION_MANAGEMENT_PATH,
  PRIVACY_POLICY_PATH,
  SELF_ENROLLMENT_PATH,
  TERMS_OF_SERVICE_PATH,
  TRANSFERS_PATH,
  TRANSFER_REQUEST_PATH,
  URAC_PATH,
  TRANSFERS_OUT_PATH,
  AUTO_REFILLS_PATH,
} = sharedConstants.RoutePaths

export const MARKETING_PAGES = ['/', ABOUT_PATH, CONTACT_PATH, FAQ_PATH, OUTGOING_INFO_PATH]

export const PRODUCT_FLOW_PAGES = [ID_UPLOAD_PATH.toString(), CHECKOUT_PATH.toString()]

export const orderHistoryLinks = (vpTheme: VpTheme): NavigationLink[] => [
  {
    type: 'NavigationPath',
    name: 'Orders',
    path: ORDERS_PATH,
    icon: <Book vpTheme={vpTheme} />,
  },
]

export const enum AccountPage {
  Cart = 'Cart',
  HealthProfile = 'Health Profile',
  Insurance = 'Insurance',
  Addresses = 'Addresses',
  Payments = 'Payments',
  Settings = 'Settings',
}

export const accountLinks = (vpTheme: VpTheme): NavigationLink[] => [
  {
    type: 'NavigationPath',
    name: AccountPage.Cart,
    path: CART_PATH,
    icon: <CartIconMobile vpTheme={vpTheme} />,
  },
  {
    type: 'NavigationPath',
    name: AccountPage.HealthProfile,
    path: RoutePaths.ACCOUNT_HEALTH_PROFILE_PATH,
    icon: <FileText vpTheme={vpTheme} />,
  },
  {
    type: 'NavigationPath',
    name: AccountPage.Insurance,
    path: ACCOUNT_INSURANCE_PATH,
    icon: <Shield vpTheme={vpTheme} />,
  },
  {
    type: 'NavigationPath',
    name: AccountPage.Addresses,
    path: RoutePaths.ACCOUNT_ADDRESSES_PATH,
    icon: <Shipping vpTheme={vpTheme} />,
  },
  {
    type: 'NavigationPath',
    name: AccountPage.Payments,
    path: RoutePaths.ACCOUNT_PAYMENTS_PATH,
    icon: <CreditCard vpTheme={vpTheme} />,
  },
  {
    type: 'NavigationPath',
    name: AccountPage.Settings,
    path: RoutePaths.ACCOUNT_SETTINGS_PATH,
    icon: <CogIcon vpTheme={vpTheme} />,
  },
]

export const enum PrescriptionManagementPage {
  CurrentMedications = 'Current medications',
  PrescriptionTransfer = 'Prescription transfer',
  AutoRefills = 'Auto refills',
}

export const retrieveNavigationLinks = (
  customerProfile: CustomerProfile,
  isTransfersAllowed: boolean,
  isAutoRefillsAllowed: boolean,
  vpTheme: VpTheme,
): NavigationLink[] => {
  const navigationLinks: NavigationLink[] = [
    {
      type: 'NavigationPath',
      name: PrescriptionManagementPage.CurrentMedications,
      path: PRESCRIPTION_MANAGEMENT_PATH,
      icon: <RxIcon vpTheme={vpTheme} />,
    },
  ]

  if ((customerProfile.allowPrescriptionTransfers || customerProfile.transferOut) && isTransfersAllowed) {
    navigationLinks.push({
      type: 'NavigationPath',
      name: PrescriptionManagementPage.PrescriptionTransfer,
      path: TRANSFERS_PATH,
      icon: <RightLeftArrow vpTheme={vpTheme} />,
    })
  }

  if (customerProfile.autoRefills && isAutoRefillsAllowed) {
    navigationLinks.push({
      type: 'NavigationPath',
      name: PrescriptionManagementPage.AutoRefills,
      path: AUTO_REFILLS_PATH,
      icon: <ArrowLoop vpTheme={vpTheme} />,
    })
  }

  return navigationLinks
}
