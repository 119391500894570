import { Text } from '@truepill/react-capsule'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Webcam from 'react-webcam'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { useContentfulTheme } from '../../../hooks'
import { dataUrlToFile, processImageCompression } from '../../../utils'
import LoadingAnimation from '../../LoadingAnimation'
import SupportLink from '../../SupportLink'
import ToastMessage from '../../ToastMessage'
import {
  DeleteActionContainer,
  ImageActionableContainer,
  ImageButtonContainer,
  InsuranceImageUploadContainer,
  PhotoActionContainer,
  SpinnerContainer,
  TextOptional,
  UploadedImage,
  UploadedImageContainer,
  UploadInsuranceImageInput,
} from './styledComponents'
import { InsuranceFormFields } from './types'

interface Props {
  insuranceCardImageUrl?: string
  isUploadingInsuranceImage: boolean
  uploadInsuranceImage: (image: File) => Promise<string>
  setIsInsuranceImageLoading: (value: boolean) => void
}

interface InsuranceUploadToastState {
  toastType: 'error' | 'success'
  toastMessage: string
}

export const InsuranceImageUpload = ({
  insuranceCardImageUrl,
  isUploadingInsuranceImage,
  uploadInsuranceImage,
  setIsInsuranceImageLoading,
}: Props): ReactElement => {
  const webcamRef = useRef<Webcam>(null)
  const insuranceImageNameRef = useRef<HTMLInputElement | null>(null)
  const [takePhoto, setTakePhoto] = useState(false)
  const [isWebcamLoading, setIsWebcamLoading] = useState(false)
  const [isLoadingUploadedImage, setIsLoadingUploadedImage] = useState(false)
  const [image, setImage] = useState<string>(insuranceCardImageUrl ?? '')
  const [insuranceToastState, setInsuranceToastState] = useState<InsuranceUploadToastState | null>(null)

  const { setValue } = useFormContext<InsuranceFormFields>()

  const isInsuranceImageLoading = isLoadingUploadedImage || isWebcamLoading || isUploadingInsuranceImage

  const { theme: vpTheme } = useContentfulTheme()

  useEffect(() => {
    if (insuranceCardImageUrl) {
      setIsLoadingUploadedImage(true)
    }
  }, [insuranceCardImageUrl])

  useEffect(() => {
    setIsInsuranceImageLoading(isInsuranceImageLoading)
  }, [isInsuranceImageLoading, setIsInsuranceImageLoading])

  const handleInsuranceImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setTakePhoto(false)
    setImage('')
    setValue('insurance_image_name', undefined)
    try {
      if (e.target.files?.[0]) {
        const insuranceFileImage = e.target.files[0]
        const base64Image = await processImageCompression(insuranceFileImage)
        const compressedInsuranceImageFile = await dataUrlToFile(base64Image, insuranceFileImage.name)
        const insuranceImageKey = await uploadInsuranceImage(compressedInsuranceImageFile)
        setImage(base64Image)
        setValue('insurance_image_name', insuranceImageKey)
        setInsuranceToastState({ toastMessage: 'Image successfully uploaded', toastType: 'success' })
      }
    } catch (e) {
      setInsuranceToastState({ toastMessage: 'Unable to upload insurance card image. Please try again later or', toastType: 'error' })
    }
  }

  const handleTakeInsuranceImagePhoto = async () => {
    setTakePhoto(!takePhoto)
    setImage('')
    setValue('insurance_image_name', undefined)
    if (takePhoto) {
      const screenshot = webcamRef.current?.getScreenshot()
      if (screenshot) {
        try {
          const insuranceFileImage = await dataUrlToFile(screenshot, 'insuranceCard.jpeg')
          const insuranceImageKey = await uploadInsuranceImage(insuranceFileImage)
          setImage(screenshot)
          setValue('insurance_image_name', insuranceImageKey)
        } catch (e) {
          setInsuranceToastState({ toastMessage: 'Unable to upload insurance card image. Please try again later or', toastType: 'error' })
        }
      }
    } else {
      setIsWebcamLoading(true)
    }
  }

  const handleDeletePhoto = () => {
    setImage('')
    setValue('insurance_image_name', null)
  }

  return (
    <InsuranceImageUploadContainer vpTheme={vpTheme}>
      <UploadInsuranceImageInput accept='image/*' type='file' ref={insuranceImageNameRef} onChange={handleInsuranceImageUpload} />

      {isInsuranceImageLoading && (
        <SpinnerContainer>
          <LoadingAnimation size='auto' borderWidth='8px' backgroundColor='none' />
        </SpinnerContainer>
      )}
      <UploadedImageContainer showImage={!isLoadingUploadedImage && !isUploadingInsuranceImage && !!image}>
        <UploadedImage onLoad={() => setIsLoadingUploadedImage(false)} src={image} />
      </UploadedImageContainer>
      {takePhoto && (
        <Webcam
          videoConstraints={{
            facingMode: 'environment',
          }}
          onUserMedia={() => setIsWebcamLoading(false)}
          style={{ maxHeight: '25vh', justifySelf: 'center', paddingBottom: '1rem' }}
          ref={webcamRef}
        />
      )}
      <ImageActionableContainer>
        {image ? (
          <DeleteActionContainer>
            <ThemedButton onClick={handleDeletePhoto} disabled={isInsuranceImageLoading} vpTheme={vpTheme} size='sm' dangerOutline={true}>
              Delete photo
            </ThemedButton>
          </DeleteActionContainer>
        ) : (
          <TextOptional>Optional: Upload a photo of your insurance card</TextOptional>
        )}
        <ImageButtonContainer>
          <PhotoActionContainer>
            <ThemedButton
              onClick={handleTakeInsuranceImagePhoto}
              size='sm'
              type='button'
              variant='primary-outline'
              disabled={isInsuranceImageLoading}
              vpTheme={vpTheme}
            >
              {image ? 'Take a new photo' : 'Take Photo'}
            </ThemedButton>
            <ThemedButton
              onClick={() => {
                insuranceImageNameRef.current?.click()
              }}
              size='sm'
              type='button'
              variant='primary-outline'
              disabled={isInsuranceImageLoading}
              vpTheme={vpTheme}
            >
              {image ? 'Upload new' : 'Upload Photo'}
            </ThemedButton>
          </PhotoActionContainer>
        </ImageButtonContainer>
      </ImageActionableContainer>
      {insuranceToastState && (
        <ToastMessage
          state={insuranceToastState.toastType}
          visible={!!insuranceToastState}
          timeout={3000}
          onTimeout={() => setInsuranceToastState(null)}
          onDismiss={() => setInsuranceToastState(null)}
          className='capsule-toast-container'
        >
          <Text>
            {insuranceToastState.toastMessage} {insuranceToastState.toastType === 'error' ? <SupportLink>contact Patient Support</SupportLink> : null}
          </Text>
        </ToastMessage>
      )}
    </InsuranceImageUploadContainer>
  )
}
