import { useEffect, useState } from 'react'

import { IAccountManagementPaymentsFields } from '../../types/generated/contentful'
import { getPageConfiguration, useGetCustomerNameFromSubdomain } from '..'

type ReturnValues = {
  loading: boolean
  error: boolean
  content?: IAccountManagementPaymentsFields
}

export const useGetAccountManagementPaymentContent = (): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<IAccountManagementPaymentsFields | undefined>()

  const customerName = useGetCustomerNameFromSubdomain()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, 'accountManagementPayments')
        setContent(page?.fields)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName])

  return {
    loading,
    error,
    content,
  }
}
