import { Divider, Header, Spacer, Text } from '@truepill/react-capsule'
import { PrescriptionCopayStatusType } from '@vpharm-platform/shared'
import { CloseButton } from 'assets/Icons'
import RichTextRenderer from 'Components/RichTextRenderer'
import { CART_PATH } from 'constants/navigation-links'
import { useContentfulCartContent, useContentfulTheme } from 'hooks/contentful'
import { medicationsInCart, OrderMode, orderModeAtom, pricingDetails } from 'persistRecoil'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { showSlidingCart } from 'recoil/atoms'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { useAnalytics } from '../../hooks/analytics-context'
import {
  CloseIconContainer,
  MedsContainer,
  SlidingCartContainer,
  SlidingCartDetailsContainer,
  SlidingCartOrderSummaryContainer,
  StyledCard,
  StyledCloseButton,
  StyledPane,
} from './styledComponents'

const CartBody = () => {
  const medications = useRecoilValue(medicationsInCart)
  const { orderMode } = useRecoilValue(orderModeAtom)
  const { theme } = useContentfulTheme()

  return (
    <>
      <MedsContainer>
        {medications.map((medication) => {
          const medPrice =
            orderMode === OrderMode.CASH
              ? Number(medication.unitPrice)
              : Number((medication.copay?.type === PrescriptionCopayStatusType.SUCCESS && medication.copay.amount) || null)
          return (
            <>
              <StyledCard shadow='sm' width='auto' key={`medication-${medication.brandDisplayName}`} data-testid='medication-card' vpTheme={theme}>
                <Text bold>
                  {medication.brandDisplayName} {medication.genericDisplayName ? `(${medication.genericDisplayName})` : ''}
                </Text>
                <Text>{`Quantity: ${medication.quantity}${medication.quantityUnits}`}</Text>
                <Text>{`Days supply: ${medication.daysSupply}`}</Text>
                <Text>{`Rx number: ${medication.rxNumber}`}</Text>
                <Header bold>${`${medPrice.toFixed(2)}`}</Header>
              </StyledCard>
              <Spacer size='sm' />
            </>
          )
        })}
      </MedsContainer>
    </>
  )
}

const SlidingCart: React.FC = () => {
  const history = useHistory()
  const priceDetails = useRecoilValue(pricingDetails)
  const [showCart, setShowCart] = useRecoilState(showSlidingCart)
  const { content } = useContentfulCartContent()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const handleClose = () => {
    trackButtonClickEvent('close-sliding-cart-from-urac', 'x')
    setShowCart(false)
  }

  return (
    <StyledPane
      from='right'
      title='title'
      overlayClassName='sliding-cart-overlay'
      className='cart-sliding-pane'
      hideHeader
      isOpen={showCart}
      onRequestClose={handleClose}
    >
      <SlidingCartContainer>
        <CloseIconContainer>
          <StyledCloseButton onClick={handleClose}>
            <CloseButton />
          </StyledCloseButton>
        </CloseIconContainer>
        <SlidingCartDetailsContainer>
          <Header variant='2xl' bold>
            Cart
          </Header>
          <Spacer size='sm' />
          <Divider css={{ backgroundColor: theme.colors['gray-300'] }} />
          <Spacer size='sm' />
          <CartBody />
        </SlidingCartDetailsContainer>
        <Spacer size='md' />
        <SlidingCartOrderSummaryContainer>
          <Header bold>Subtotal</Header>
          <Header data-testid='subtotal' bold>{`$${(priceDetails.subTotal ?? 0).toFixed(2)}`}</Header>
        </SlidingCartOrderSummaryContainer>
        <Spacer size='lg' />
        {content?.expandedDescription ? (
          <RichTextRenderer document={content.expandedDescription} />
        ) : (
          <Text css={{ color: '$typography-medium' }}>To view insurance information unique to each prescription, please go to cart.</Text>
        )}

        <Spacer size='lg' />
        <ThemedButton
          variant='primary-outline'
          onClick={() => {
            trackButtonClickEvent('view-cart-from-sliding-cart', 'View cart')
            handleClose()
            history.push(CART_PATH)
          }}
          vpTheme={theme}
        >
          View cart
        </ThemedButton>
      </SlidingCartContainer>
    </StyledPane>
  )
}

export default SlidingCart
