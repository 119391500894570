import { Text } from '@truepill/react-capsule'
import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../common/styles/variables'

const Container = styled.div<{ backgroundColor?: string }>`
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor ?? defaultTheme.colors['gray-100']};
  padding: 2rem 1rem;

  ${mediaLargerThan.tablet} {
    padding: 2rem;
  }
`

const HeaderIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row-reverse;
    justify-content: start;
  }
`

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  ${mediaLargerThan.tablet} {
    padding-top: 0;
  }
`

const HeaderToggleContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${mediaLargerThan.tablet} {
    flex-direction: row;
    align-items: center;
  }
`

const StatusText = styled(Text)<{ vpTheme: VpTheme; isAutoRefillOn: boolean }>`
  font-weight: 700;
  color: ${(props) =>
    props.isAutoRefillOn
      ? props.vpTheme.colors['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']
      : props.vpTheme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']};
`

const HeaderContainer = styled.div``

const MedInfoText = styled(Text)<{ textColor?: string }>`
  color: ${(props) => props.textColor ?? defaultTheme.colors['typography-medium']};
`

const MedNameArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    cursor: pointer;
    width: 18px;
    height: 12px;
  }
`

const MedIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`

const ExpandableContainer = styled.div`
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  ${(props) =>
    props['aria-expanded'] === true &&
    `
      max-height: 1000px;
      transition: max-height 0.5s ease-in-out;
    `}
`

const MedicationNameContainer = styled.div``

const Divider = styled.div``

const MedicationExtraInfoContainer = styled.div<{ borderColor?: string }>`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const PriceWithNextFillDate = styled.div`
  display: flex;
  gap: 1rem;
`

export const StyledAutoRefillCard = {
  Container,
  MedIconContainer,
  HeaderIconContainer,
  HeaderContainer,
  MedInfoText,
  StatusContainer,
  StatusText,
  ToggleContainer,
  HeaderToggleContainer,
  MedNameArrowContainer,
  ExpandableContainer,
  MedicationNameContainer,
  MedicationExtraInfoContainer,
  PriceWithNextFillDate,
  Divider,
}
