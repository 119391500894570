import { sharedConstants } from '@vpharm-platform/shared'
import moment from 'moment'
import * as Yup from 'yup'

import { GenderResponses } from '../../pages/SelfEnrollment/sections/types'
import { isOver18, isValidDate } from './helpers'

const { VPHARM_VALIDATION_PATIENT_INSURANCE } = sharedConstants

const nameRegex = /^[A-Za-z][A-Za-z-.,'\s]+$/
const middleInitialRegex = /^[A-Za-z]*$/
const zipRegex = /^[0-9]*$/
const addressRegex = /^[a-zA-Z0-9/\s,'-.#]*$/
const phoneRegex = /^[0-9-() ]{14}$/

export const PatientIdentityValidationSchema = Yup.object().shape({
  year: Yup.number().required().positive().integer().min(1900).max(moment().year()),
  month: Yup.number()
    .required()
    .test({
      test: (month, ctx) => {
        if (month && ctx.parent.year) {
          if (ctx.parent.year === moment().year()) {
            return month <= moment().month() + 1
          }
          return true
        }
        return true
      },
    }),
  day: Yup.number()
    .required()
    .positive()
    .integer()
    .min(1)
    .test({
      test: (value, ctx) => {
        if (value && ctx.parent?.month && !ctx.parent?.year) {
          return value <= moment(`${ctx.parent.month}`, 'MM').daysInMonth()
        }
        if (value && ctx.parent?.month && ctx.parent?.year) {
          if (value > moment(`${ctx.parent.month} ${ctx.parent.year}`, 'MM YYYY').daysInMonth()) {
            return false
          }

          if (ctx.parent.year === moment().year() && ctx.parent.month === moment().month() + 1) {
            return value <= moment().date()
          }
          return true
        }
        return true
      },
    }),
})

// Required because optional fields are still sent as empty strings, which notRequired does not omit
const removeEmptyStrings = (value?: string) => (value?.length ? value : undefined)

export const InsuranceFieldValidationSchema = Yup.object().shape({
  cardholder_id: Yup.string()
    .max(25, 'Cardholder ID is too long')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.cardholder_id, { message: 'Enter a valid cardholder ID' })
    .required(),
  rx_bin: Yup.string()
    .min(6, 'Rx BIN is too short (should be six numbers)')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.rx_bin, { message: 'Enter a valid Rx BIN' })
    .required(),
  pcn: Yup.string()
    .max(15, 'RX PCN is too long')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.pcn, { message: 'Enter a valid Rx PCN' })
    .notRequired()
    .transform(removeEmptyStrings),
  rx_group: Yup.string()
    .max(15, 'RX Group is too long')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.rx_group, { message: 'Enter a valid Rx Group' })
    .notRequired()
    .transform(removeEmptyStrings),
  insurance_image_name: Yup.string().nullable().notRequired(),
  relationship_to_primary_cardholder: Yup.string().oneOf(['Cardholder', 'Spouse', 'Child', 'Other']).required(),
})

// This will change
export const SavingsCardFieldValidationSchema = Yup.object().shape({
  cardholder_id: Yup.string()
    .max(25)
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.cardholder_id, { message: 'Enter a valid cardholder ID' })
    .required(),
  rx_bin: Yup.string()
    .min(6, 'Rx BIN is too short (should be six numbers)')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.rx_bin, { message: 'Enter a valid Rx BIN' })
    .required(),
  pcn: Yup.string()
    .max(15, 'RX PCN is too long')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.pcn, { message: 'Enter a valid Rx PCN' })
    .notRequired()
    .transform(removeEmptyStrings),
  rx_group: Yup.string()
    .max(15, 'RX Group is too long')
    .matches(VPHARM_VALIDATION_PATIENT_INSURANCE.rx_group, { message: 'Enter a valid Rx Group' })
    .notRequired()
    .transform(removeEmptyStrings),
  savings_card_image_key: Yup.string().nullable().notRequired(),
})

export const LegalGuardianValidation = Yup.object().shape({
  guardianFirstName: Yup.string().min(1).required(),
  guardianLastName: Yup.string().min(1).required(),
})

export const ShippingValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter Full Name').required(),
  address1: Yup.string().min(1).max(200).matches(addressRegex).required(),
  address2: Yup.string().max(200).matches(addressRegex).notRequired(),
  city: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter a valid city').required(),
  state: Yup.string().min(2).required(),
  zip: Yup.string()
    .min(5, 'Please enter a valid 5 digit zip code')
    .max(5, 'Please enter a valid 5 digit zip code')
    .matches(zipRegex, 'Please enter a valid zip code')
    .required(),
  isDefault: Yup.bool().notRequired(),
  fullAddress: Yup.string().min(5).matches(addressRegex).required(),
})

export const AddManualAddressValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter Full Name').required(),
  address1: Yup.string().min(1).max(200).matches(addressRegex).required(),
  address2: Yup.string().max(200).matches(addressRegex).notRequired(),
  city: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter a valid city').required(),
  state: Yup.string().min(2).required(),
  zip: Yup.string()
    .min(5, 'Please enter a valid 5 digit zip code')
    .max(5, 'Please enter a valid 5 digit zip code')
    .matches(zipRegex, 'Please enter a valid zip code')
    .required(),
  isDefault: Yup.bool().notRequired(),
})

export const EditAddressValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter Full Name').required(),
  address1: Yup.string().min(1).max(200).matches(addressRegex).required(),
  address2: Yup.string().max(200).matches(addressRegex).notRequired(),
  city: Yup.string().min(1).max(200).matches(nameRegex, 'Please enter a valid city').required(),
  state: Yup.string().min(2).required(),
  zip: Yup.string()
    .min(5, 'Please enter a valid 5 digit zip code')
    .max(5, 'Please enter a valid 5 digit zip code')
    .matches(zipRegex, 'Please enter a valid zip code')
    .required(),
  isDefault: Yup.bool().notRequired(),
  fullAddress: Yup.string().min(5).matches(addressRegex).required(),
})

export const PaymentValidationSchema = Yup.object().shape({
  name: Yup.string().when('cardRequired', {
    is: true,
    then: Yup.string().min(1).max(200).matches(nameRegex).required(),
    otherwise: Yup.string().notRequired(),
  }),
  cardNumberCompleted: Yup.boolean().when('cardRequired', {
    is: true,
    then: Yup.boolean().oneOf([true]).required(),
    otherwise: Yup.boolean().notRequired(),
  }),
  cardExpiryCompleted: Yup.boolean().when('cardRequired', {
    is: true,
    then: Yup.boolean().oneOf([true]).required(),
    otherwise: Yup.boolean().notRequired(),
  }),
  cardCvvCompleted: Yup.boolean().when('cardRequired', {
    is: true,
    then: Yup.boolean().oneOf([true]).required(),
    otherwise: Yup.boolean().notRequired(),
  }),
  postalCode: Yup.string().length(5).matches(zipRegex).required(),
  isDefault: Yup.bool().notRequired(),
})

const defaultPharmacyError = 'Please enter a valid pharmacy name'

export const ContactFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(80).required(),
  email: Yup.string().email().min(1).max(80).required(),
  phone: Yup.string().max(14).optional(),
  subject: Yup.string().min(1).max(80).required(),
  message: Yup.string().min(10).max(1000).required(),
  topic: Yup.string().min(1).max(80).required(),
})

export const TransferDetailsValidationSchema = Yup.object().shape({
  medicationDetails: Yup.object().shape({
    medication: Yup.object().shape({
      formattedName: Yup.string()
        .min(1, 'Please enter a valid medication name')
        .max(200, 'Please enter a valid medication name')
        .required('Please enter a valid medication name'),
    }),
  }),
  pharmacyDetails: Yup.object().shape({
    pharmacyName: Yup.string().min(1, defaultPharmacyError).max(200, defaultPharmacyError).required(defaultPharmacyError),
    pharmacyPhoneNumber: Yup.string().max(200).matches(phoneRegex, { message: 'Please enter a valid phone number' }).required(),
  }),
})

export const SelfEnrollmentPatientInfo = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'First name must be at least 2 characters')
    .max(50, 'First name must be less than 50 characters')
    .matches(nameRegex, 'Invalid first name format')
    .required('First name is required'),
  middleInitial: Yup.string().max(1, 'Middle initial should be 1 character').matches(middleInitialRegex).notRequired(),
  suffix: Yup.string().max(5, 'Suffix cannot be more than 5 characters').notRequired(),
  lastName: Yup.string()
    .min(2, 'Last name must be at least 2 characters')
    .max(50, 'Last name must be less than 50 characters')
    .matches(nameRegex, 'Invalid last name format')
    .required('Last name is required'),
  gender: Yup.mixed<GenderResponses>().oneOf(Object.values(GenderResponses)).required(),
  dob: Yup.object()
    .shape({
      year: Yup.number().required('Birth year is required'),
      month: Yup.number().required('Birth month is required'),
      day: Yup.number().required('Birth day is required'),
    })
    .test({
      test: (userDob) => {
        if (userDob.day && userDob.month && userDob.year) {
          return isValidDate(userDob.year, userDob.month, userDob.day)
        }
        return true
      },
      message: 'Please enter a valid date of birth',
    })
    .test({
      test: (userDob) => {
        if (userDob.day && userDob.month && userDob.year) {
          return isOver18(userDob.year, userDob.month, userDob.day)
        }
        return true
      },
      message: 'Patients under 18 cannot be served.',
    }),
  address1: Yup.string()
    .min(1, 'Address must be at least 1 character')
    .max(200, 'Address cannot exceed 200 characters')
    .matches(addressRegex, 'Address contains invalid characters')
    .required(),
  address2: Yup.string().max(200, 'Address cannot exceed 200 characters').matches(addressRegex, 'Address contains invalid characters').notRequired(),
  city: Yup.string().min(1).max(200).matches(nameRegex, 'City contains invalid characters').required(),
  state: Yup.string().min(2).required(),
  zip: Yup.string()
    .min(5, 'Please enter a valid 5 digit zip code')
    .max(5, 'Please enter a valid 5 digit zip code')
    .matches(zipRegex, 'Please enter a valid zip code')
    .required(),
  patientPhoneNumber: Yup.string().matches(phoneRegex, { message: 'Please enter a valid phone number' }).required('Phone number is required'),
})

export const SelfEnrollmentContactInfo = Yup.object().shape({
  patientPhoneNumber: Yup.string().matches(phoneRegex, { message: 'Please enter a valid phone number' }).required('Phone number is required'),
  enrollCommunications: Yup.boolean().optional(),
  patientEmailAddress: Yup.string().email('Please enter a valid email address').required('Email address is required'),
})

export const AccountSettingEditPhone = Yup.object().shape({
  patientPhoneNumber: Yup.string().matches(phoneRegex, { message: 'Please enter a valid phone number' }).required('Phone number is required'),
})

export const LoginInfo = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const PasswordCheck = Yup.object().shape({
  password: Yup.string().required('Password is required'),
})

export const ForgotPasswordInfo = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
})

export const SignUpInfo = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
  confirmEmail: Yup.string()
    .required('Email is required')
    .oneOf([Yup.ref('email')], 'Email does not match'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords does not match'),
})

export const AccountSettingEditEmail = Yup.object().shape({
  patientEmail: Yup.string().email('Enter a valid email address').required('Email address is required'),
  patientEmailConfirmation: Yup.string()
    .required('Please retype your email.')
    .oneOf([Yup.ref('patientEmail')], 'Your email addresses do not match. Please try again.'),
})
