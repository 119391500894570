import { EstimatedDeliveryWindow } from '@vpharm-platform/shared'
import { utc } from 'moment'

export function toUtcString(date: string | null, outFormat: string, inFormat?: string): string | null {
  if (!date) {
    return date
  }

  const result = utc(date, inFormat)

  return result.format(outFormat)
}

export function last2YearDigits(year: number): string {
  const strYear = year.toString()
  if (strYear.length < 4) {
    return strYear
  }
  return year.toString().slice(-2)
}

export const formatArrivalDate = (arrivalDate: string | EstimatedDeliveryWindow | null): string => {
  if (!arrivalDate) {
    return ''
  }

  if (typeof arrivalDate === 'string') {
    return toUtcString(arrivalDate, 'MMMM D, YYYY') as string
  }

  const fromDate = toUtcString(arrivalDate.fromDate, 'ddd, MMM. D')
  const toDate = toUtcString(arrivalDate.toDate, 'ddd, MMM. D')
  return `${fromDate} - ${toDate}`
}

export const formatArrivalDateLocal = (arrivalDate: string | EstimatedDeliveryWindow | null): string => {
  if (!arrivalDate) {
    return ''
  }

  if (typeof arrivalDate === 'string') {
    return toUtcString(arrivalDate, 'MMMM D, YYYY') as string
  }

  const fromDate = utc(arrivalDate.fromDate).local().format('ddd, MMM. D')
  const toDate = utc(arrivalDate.toDate).local().format('ddd, MMM. D')
  return `${fromDate} - ${toDate}`
}
