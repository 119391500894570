import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const AutoInjector = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405945)'>
        <path
          d='M24.3654 30.3051L18.7554 26.4351V43.5651L24.3754 39.6651H38.8754V30.3051H24.3654Z'
          fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
        />
        <path
          d='M18.2554 44.5151V25.4851L24.5254 29.8051H39.3754V40.1651H24.5354L18.2654 44.5151H18.2554ZM19.2554 27.3851V42.6051L24.2154 39.1651H38.3754V30.8051H24.2154L19.2554 27.3851Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path
          d='M14.4852 23.885H17.3152C18.1252 23.885 18.7852 24.545 18.7852 25.355V44.645C18.7852 45.455 18.1252 46.115 17.3152 46.115H14.4852C13.9652 46.115 13.5352 45.695 13.5352 45.165V24.835C13.5352 24.315 13.9552 23.885 14.4852 23.885Z'
          fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}
        />
        <path
          d='M17.3152 46.615H14.4852C13.6852 46.615 13.0352 45.965 13.0352 45.165V24.835C13.0352 24.035 13.6852 23.385 14.4852 23.385H17.3152C18.3952 23.385 19.2752 24.265 19.2752 25.355V44.645C19.2752 45.725 18.3952 46.615 17.3152 46.615ZM14.4852 24.385C14.2352 24.385 14.0352 24.585 14.0352 24.835V45.165C14.0352 45.415 14.2352 45.615 14.4852 45.615H17.3152C17.8452 45.615 18.2752 45.185 18.2752 44.645V25.355C18.2752 24.825 17.8452 24.385 17.3152 24.385H14.4852Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M84.0552 30.3149H38.8652V39.6749H84.0552V30.3149Z' fill={vpTheme.colors.grayWhite} />
        <path
          d='M84.5652 40.1749H38.3652V29.8149H84.5552V40.1749H84.5652ZM39.3752 39.1749H83.5652V30.8149H39.3652V39.1749H39.3752Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M99.0854 30.3149H83.9854V39.6749H99.0854V30.3149Z' fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
        <path
          d='M99.5851 40.1749H83.4951V29.8149H99.5851V40.1749ZM84.4951 39.1749H98.5851V30.8149H84.4951V39.1749Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M101.465 30.9949H99.0254V39.0049H101.465V30.9949Z' fill={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
        <path
          d='M101.965 39.5049H98.5254V30.4949H101.965V39.5049ZM99.5254 38.5049H100.965V31.4949H99.5254V38.5049Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke={vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']} />
      <defs>
        <clipPath id='clip0_19220_405945'>
          <rect width='88.93' height='23.23' fill={vpTheme.colors.grayWhite} transform='translate(13.0352 23.385)' />
        </clipPath>
      </defs>
    </svg>
  )
}
