import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import { patientInsurance, selectedPatientTokenAtom } from 'persistRecoil'
import { useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil'
import { patientInsuranceService } from 'services/patientInsuranceService'

import { orderModeAtom } from '../persistRecoil/orderMode'
import { useCustomerProfile, usePatientProfile, useRefreshPrescriptionList, useUpdateCart } from '.'

export interface UsePatientInsurance {
  isErrorLoading: boolean
  isLoadingInsurance: boolean
  isDeletingInsurance: boolean
  patientInsuranceList?: PatientInsurance[]
  submitPatientInsurance: (values: PatientInsuranceSubmitPayload, id?: string) => Promise<boolean>
  deletePatientInsurance: (id: string) => Promise<boolean>
  setShouldRefetchPatientInsurance: () => void
}

export const usePatientInsurance = (): UsePatientInsurance => {
  const updateCart = useUpdateCart()
  const { customerProfile } = useCustomerProfile()
  const { resetPatientProfile } = usePatientProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [patientInsuranceList, setPatientInsuranceList] = useRecoilState<PatientInsurance[] | undefined>(patientInsurance)
  const orderModeRefresh = useRecoilRefresher_UNSTABLE(orderModeAtom)
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const [isLoadingInsurance, setIsLoadingInsurance] = useState<boolean>(false)
  const [isDeletingInsurance, setIsDeletingInsurance] = useState<boolean>(false)
  const [shouldRefetchPatientInsurance, setShouldRefetchPatientInsurance] = useState<boolean>(false)
  const [isErrorLoading, setIsErrorLoading] = useState<boolean>(false)

  const submitPatientInsurance = async (values: PatientInsuranceSubmitPayload, truepillInsuranceToken?: string): Promise<boolean> => {
    let result = true
    try {
      await patientInsuranceService.submitPatientInsurance(customerProfile.vpharmCustomerToken, selectedPatientToken, values, truepillInsuranceToken)
      setShouldRefetchPatientInsurance(true)
      updateCart.clearCart()
      resetPatientProfile()
    } catch (e) {
      result = false
    } finally {
      orderModeRefresh()
      refreshPrescriptionList()
    }
    return result
  }

  const deletePatientInsurance = async (id: string): Promise<boolean> => {
    let result = true
    setIsDeletingInsurance(true)
    try {
      await patientInsuranceService.deletePatientInsurance(customerProfile.vpharmCustomerToken, selectedPatientToken, id)
      setShouldRefetchPatientInsurance(true)
      updateCart.clearCart()
      resetPatientProfile()
    } catch (e) {
      result = false
    } finally {
      orderModeRefresh()
      refreshPrescriptionList()
      setIsDeletingInsurance(false)
    }
    return result
  }

  useEffect(() => {
    async function fetchPatientInsurance() {
      setShouldRefetchPatientInsurance(false)
      setIsLoadingInsurance(true)
      setIsErrorLoading(false)
      if (selectedPatientToken) {
        try {
          const patientInsuranceResponse = await patientInsuranceService.getPatientInsurances(
            customerProfile.vpharmCustomerToken,
            selectedPatientToken,
          )
          setPatientInsuranceList(patientInsuranceResponse)
        } catch (e) {
          setIsErrorLoading(true)
          setPatientInsuranceList([])
        } finally {
          setIsLoadingInsurance(false)
          setShouldRefetchPatientInsurance(false)
        }
      }
    }

    if (typeof patientInsuranceList === 'undefined' || shouldRefetchPatientInsurance) {
      fetchPatientInsurance()
    }
  }, [patientInsuranceList, selectedPatientToken, shouldRefetchPatientInsurance, customerProfile.vpharmCustomerToken, setPatientInsuranceList])

  return {
    isErrorLoading,
    isLoadingInsurance,
    isDeletingInsurance,
    patientInsuranceList,
    submitPatientInsurance,
    deletePatientInsurance,
    setShouldRefetchPatientInsurance: () => setShouldRefetchPatientInsurance(true),
  }
}
