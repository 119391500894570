import { Header } from '@truepill/react-capsule'
import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import { media, mediaLargerThan } from 'common/styles/variables'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../common/styledComponents/types'

const Wrapper = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${({ background }) => background ?? defaultTheme.colors['gray-100']};
  border-radius: 0.5rem;
`

const StyledHeader = styled(Header)`
  max-width: 360px;
  @media (min-width: ${media.tablet}) {
    max-width: 420px;
  }
`

const StyledDeleteAddressActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  > *:first-child {
    margin-top: 0.5rem;
  }
  @media (min-width: 540px) {
    flex-flow: row;
    flex-wrap: nowrap;
    > *:first-child {
      margin-top: 0;
      margin-right: 1.5rem;
    }
    justify-content: flex-end;
  }
`

const AddressAutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`

const ToggleAddressEntry = styled(ThemedButton)`
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`

const HiddenIdInput = styled.input`
  visibility: hidden;
`

const DetailWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: ${media.tablet}) {
    flex-wrap: nowrap;
  }
`

const CityWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  @media (min-width: ${media.tablet}) {
    width: 45%;
    padding-bottom: 0;
    margin-right: 1rem;
  }
`

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: ${media.tablet}) {
    width: 65%;
  }
`

const ZipWrapper = styled.div`
  &:last-child {
    margin-left: 1rem;
  }
  @media (min-width: ${media.tablet}) {
    &:last-child {
      margin-left: 1rem;
    }
    margin-left: 1rem;
    width: 80%;
  }
`

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const Hideable = styled.div<{ hidden?: boolean }>`
  ${({ hidden }) => hidden && 'height: 0; overflow: hidden;'}
`

const ToggleShowAddressLine2 = styled(ThemedButton)<{ hidden?: boolean }>`
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  ${({ hidden }) => hidden && 'display: none;'}
`

const GreyBackgroundContainer = styled.div<{ border?: boolean; padding?: boolean; vpTheme: VpTheme }>`
  display: flex;
  align-items: center;
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100'] ?? defaultTheme.colors['gray-100']};
  ${({ border, vpTheme }) => border && `border: 1px solid ${vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};`}
  ${({ border }) => border && `border-radius: 0.5rem;`}
  ${({ padding }) =>
    padding &&
    `
    > div {
      padding: 1rem;
    }
  `}
`

const WhiteBackgroundContainer = styled.div<ThemedComponent>`
  display: flex;
  align-items: center;
  background-color: ${({ vpTheme }) => vpTheme.colors.grayWhite};
`

const SplitContainer = styled.div<{ border?: string }>`
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  border: 1px solid ${({ border }) => border ?? defaultTheme.colors['gray-300']};
  border-radius: 0.5rem;

  > div {
    padding: 1rem;
  }

  ${mediaLargerThan.tablet} {
    grid-template-columns: 8fr 4fr;
  }
`

export {
  AddressAutocompleteContainer,
  CityWrapper,
  DetailWrapper,
  GreyBackgroundContainer,
  HiddenIdInput,
  Hideable,
  MainWrapper,
  SplitContainer,
  StyledActionContainer,
  StyledDeleteAddressActionContainer,
  StyledHeader,
  ToggleAddressEntry,
  ToggleShowAddressLine2,
  WhiteBackgroundContainer,
  Wrapper,
  ZipWrapper,
}
