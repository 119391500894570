import { VpTheme } from '@vpharm-platform/shared'
import React from 'react'

import { AutoInjector, Cartridge, Generic, NasalPowder, PenInjector, PrefilledSyringe, Vial } from '../../../../assets/Icons'
import { useContentfulTheme } from '../../../../hooks'

const getDrugIllustration = (form: string | null, vpTheme: VpTheme): React.ReactElement => {
  if (!form) {
    return <Generic vpTheme={vpTheme} />
  }

  const lowerCaseForm = form.toLowerCase()
  switch (lowerCaseForm) {
    case 'solution pen-injector':
    case 'suspension peninjector':
      return <PenInjector vpTheme={vpTheme} />
    case 'solution prefilled syringe':
      return <PrefilledSyringe vpTheme={vpTheme} />
    case 'solution auto-injector':
      return <AutoInjector vpTheme={vpTheme} />
    case 'solution cartridge':
      return <Cartridge vpTheme={vpTheme} />
    case 'suspension':
    case 'solution':
      return <Vial vpTheme={vpTheme} />
    case 'powder':
      return <NasalPowder vpTheme={vpTheme} />
    default:
      return <Generic vpTheme={vpTheme} />
  }
}

interface PrescriptionCardIconProps {
  form: string | null
}

const PrescriptionCardIcon = ({ form }: PrescriptionCardIconProps): React.ReactElement => {
  const { theme } = useContentfulTheme()
  return getDrugIllustration(form, theme)
}

export default PrescriptionCardIcon
