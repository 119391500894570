import type { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

let stripePromise: Promise<Stripe | null>

const initializeStripe = (): Promise<Stripe | null> => {
  if (stripePromise === undefined) {
    // Deactivate the User's behavior logging
    loadStripe.setLoadParameters({ advancedFraudSignals: false })
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || 'pk_test_AmAeEKD3lESGKEWh49TB6SQe')
  }
  return stripePromise
}

export { initializeStripe }
