import { useEffect, useState } from 'react'

import type { IPageFields, ISeo } from '../../types/generated/contentful'
import { getPageConfiguration } from './contentfulService'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

interface ReturnValues {
  loading: boolean
  error: boolean
  content?: IPageFields | null
  seo?: ISeo
}

export const useGetOrdersPageContent = (): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<IPageFields | undefined>()
  const [seo, setSeo] = useState<ISeo | undefined>()
  const customerName = useGetCustomerNameFromSubdomain()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, 'orderPage')
        setContent(page?.fields)
        setSeo(page?.fields.seo as ISeo)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName])

  return {
    content,
    loading,
    error,
    seo,
  }
}
