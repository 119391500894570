import { BannerState, Header, SEO, Spacer, Toast } from '@truepill/react-capsule'
import { AccountLayout } from 'Components/PageLayoutWithSidebar'
import React, { useState } from 'react'

import SavedAddresses from '../../../Components/Addresses/SavedAddresses'
import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { AccountPage } from '../../../constants'
import { useUserAddresses } from '../../../hooks/useUserAddresses'
import { PatientAddress } from '../../../interfaces'
import Container from '../Container'
import StyledHeader from '../StyledHeader'

export interface BannerMessage {
  message: string
  type: BannerState
  timeout?: number
}

const AccountAddresses: React.FunctionComponent = () => {
  const [bannerAlert, setBannerAlert] = useState<BannerMessage | null>(null)
  const { addressList, isLoading, addNewAddress, updateAddress, updateDefaultAddress, deleteAddress } = useUserAddresses()
  const { pharmCustomer } = useCustomerConfigContext()

  const handleAddAddress = async (address: PatientAddress) => {
    const result = await addNewAddress(address)
    if (result.data) {
      setBannerAlert({ message: 'Your shipping address has been successfully added', type: 'success' })
    }
    return result
  }

  const handleUpdateAddress = async (address: PatientAddress) => {
    const result = await updateAddress(address)
    if (result.data) {
      setBannerAlert({ message: 'Successfully updated shipping address', type: 'success' })
    }
    return result
  }

  const handleUpdateDefaultAddress = async (address: PatientAddress) => {
    const result = await updateDefaultAddress(address)
    if (result.error) {
      setBannerAlert({ message: result.error, type: 'error' })
    } else {
      setBannerAlert({ message: 'Your default shipping address has been updated', type: 'success' })
    }
    return result
  }

  const handleDeleteAddress = async (address: PatientAddress) => {
    const result = await deleteAddress(address)
    if (result.error) {
      setBannerAlert({ message: result.error, type: 'error' })
    } else {
      setBannerAlert({ message: 'Your shipping address has been successfully deleted', type: 'success' })
    }
    return result
  }

  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <AccountLayout selected={AccountPage.Addresses}>
      <SEO title={`${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <Container>
        <StyledHeader>
          <Header as='h1' variant='3xl' data-testid='address-title'>
            Addresses
          </Header>
        </StyledHeader>
        <Spacer size='xl' />
        <SavedAddresses
          addressList={addressList}
          onAddAddress={handleAddAddress}
          onEditAddress={handleUpdateAddress}
          onSetDefaultAddress={handleUpdateDefaultAddress}
          onDeleteAddress={handleDeleteAddress}
        />

        <Toast
          rootClassName='capsule-toast-container'
          timeout={bannerAlert?.timeout ?? 5000}
          position={{ vertical: 'top', horizontal: 'center' }}
          state={bannerAlert?.type}
          color='pastel'
          icon
          onTimeout={() => setBannerAlert(null)}
          visible={!!bannerAlert}
          css={{
            paddingTop: 5,
            paddingBottom: 5,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderRight: '1px solid',
          }}
        >
          {bannerAlert?.message}
        </Toast>
      </Container>
    </AccountLayout>
  )
}

export default AccountAddresses
