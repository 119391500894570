import { Radio, Text } from '@truepill/react-capsule'
import { AvailableShippingOptionsResponse } from '@vpharm-platform/shared'

import { useContentfulTheme } from '../../../hooks'
import { DeliveryDaysText, ShippingMethodContainer, ShippingMethodDetails, ShippingMethodItem, ShippingPrice } from './styledComponents'

interface Props {
  selectedMethodId: number | undefined
  shippingMethods: AvailableShippingOptionsResponse[]
  onSelectedMethod: (selectedMethod: AvailableShippingOptionsResponse) => void
}

const ShippingMethodSelector: React.FC<Props> = ({ shippingMethods, selectedMethodId, onSelectedMethod }) => {
  const { theme } = useContentfulTheme()

  return (
    <>
      {shippingMethods.map((method) => {
        const { id, max_delivery_days, min_delivery_days, shipping_name, shipping_price } = method
        return (
          <ShippingMethodContainer
            key={id}
            ischecked={selectedMethodId === method.id}
            onClick={() => onSelectedMethod(method)}
            checkedColor={theme.colors['primary-100']}
            checkedBorder={theme.colors['primary-500']}
          >
            <ShippingMethodDetails>
              <ShippingMethodItem>
                <Radio value={shipping_name} label={shipping_name} checked={selectedMethodId === method.id} readOnly />
                {!method.shipping_code.toLowerCase().includes('fedex_priority_overnight') && (
                  <DeliveryDaysText as='span'>{`(${min_delivery_days}-${max_delivery_days} business days)`}</DeliveryDaysText>
                )}
              </ShippingMethodItem>
              <ShippingPrice>
                <Text bold>{`$${shipping_price?.toFixed(2)}`} </Text>
              </ShippingPrice>
            </ShippingMethodDetails>
          </ShippingMethodContainer>
        )
      })}
    </>
  )
}

export default ShippingMethodSelector
