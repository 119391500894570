import { LoadingSpinner, Spacer } from '@truepill/react-capsule'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { StyledContainer, StyledLoaderWrapper, StyledProcessingText, StyledText } from './styledComponents'

export interface SpinnerScreenProps {
  description?: string
  processingText?: string
  subDescription?: string
  spinnerSize?: 'sm' | 'md' | 'lg' | 'auto'
}

const SpinnerScreen = ({ description, processingText, subDescription, spinnerSize = 'auto' }: SpinnerScreenProps): React.ReactElement => {
  const { theme } = useContentfulTheme()

  return (
    <StyledContainer processingText={processingText} subDescription={subDescription} vpTheme={theme}>
      {processingText && (
        <>
          <StyledProcessingText bold variant='4xl' data-testid='spinner-processing-text'>
            {processingText}
          </StyledProcessingText>
          <Spacer size='md' />
        </>
      )}
      {description && <StyledText data-testid='spinner-desc'>{description}</StyledText>}

      {subDescription && (
        <>
          <StyledText data-testid='spinner-sub-desc'>{subDescription}</StyledText>
          <Spacer size='2xl' />
        </>
      )}
      <StyledLoaderWrapper>
        <LoadingSpinner size={spinnerSize} />
      </StyledLoaderWrapper>
    </StyledContainer>
  )
}

export default SpinnerScreen
