import { Checkbox, Spacer, Text } from '@truepill/react-capsule'
import { formatStringToLongDateString, PatientIdentityResponse, VerifyIdentityResult } from '@vpharm-platform/shared'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import LoadingAnimation from '../../Components/LoadingAnimation'
import SupportLink from '../../Components/SupportLink'
import ToastMessage from '../../Components/ToastMessage'
import { ACCOUNT_CREATION_PATH } from '../../constants/navigation-links'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { patientService } from '../../services/patientService'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import { GeneralInformationItem } from '../AccountManagement/HealthProfile/GeneralInformationItem'
import CheckoutPage from '../CheckoutFlow/CheckoutPage'
import { BorderBox, ButtonsWrapper, CheckboxContainer, CtaWrapper, GeneralInformationContainer, Separator } from './styledComponents'

const PatientRecordVerification: React.FC = () => {
  const { theme } = useContentfulTheme()
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [smsOptIn, setSmsOptIn] = useState(false)
  const { trackButtonClickEvent, trackErrorShownEvent } = useAnalytics()

  const history = useHistory()
  const location = useLocation()

  const patientData = location.state as PatientIdentityResponse
  const stringDate = patientData.month ? formatStringToLongDateString(`${patientData.year}${patientData.month}${patientData.day}`) : 'not available'
  const legalNameTooltip = (
    <p>
      In the event of a legal name change, please <SupportLink> contact Patient Support </SupportLink>
    </p>
  )
  const sexAtBirthTooltip = (
    <p>
      We recognize that this information may not represent who you are. We are committed to inclusivity within our health and safety requirements for
      all of our services. <br />
      <br /> Our pharmacy certification requires us to ask for this information to keep you safe. Our pharmacists will use this information to check
      for drug interactions and any other safety concerns.
    </p>
  )

  const handleSubmit = async () => {
    setShowLoadingScreen(true)
    trackButtonClickEvent('confirm patient record', 'Continue', 'go to account creation')
    try {
      let result: VerifyIdentityResult | null = null

      if (!patientData.identity_verification_token) {
        const finalPatientData = {
          ...patientData,
          sms_opt_in: smsOptIn,
        }
        result = await patientService.verifyPatientIdentity(finalPatientData)
      }

      if (patientData.identity_verification_token || (result?.isValid && result?.identity_verification_token)) {
        if (result) {
          history.replace(`${ACCOUNT_CREATION_PATH}/?registration_token=${result.identity_verification_token}`)
        }
      } else {
        setShowError(true)
        setShowLoadingScreen(false)
        trackErrorShownEvent('Unable to verify patient identity', 'submit patient record verification form')
      }
    } catch {
      trackErrorShownEvent('Unable to verify patient identity', 'submit patient record verification form')
      setShowLoadingScreen(false)
    }
  }

  if (showLoadingScreen) {
    return <LoadingAnimation />
  }

  return (
    <CheckoutPage
      header='We found a matching patient record'
      instructions='Please confirm the patient record below to associate prescriptions with your account'
    >
      <BorderBox vpTheme={theme}>
        <GeneralInformationContainer vpTheme={theme}>
          <GeneralInformationItem
            label='Legal name'
            text={`${patientData.first_name} ${patientData.last_name}`}
            testId='legal-name'
            tooltipLabel='Legal name'
            tooltip={legalNameTooltip}
          />
          <Separator vpTheme={theme} />
          <GeneralInformationItem label='Date of birth' text={stringDate} testId='dob' />
          <Separator vpTheme={theme} />
          <GeneralInformationItem
            label='Sex assigned at birth'
            text={patientData.gender ? capitalizeFirstLetter(patientData.gender) : 'Not available'}
            testId='sex'
            tooltipLabel='Sex at birth'
            tooltip={sexAtBirthTooltip}
          />
        </GeneralInformationContainer>
      </BorderBox>
      <Spacer />
      <CheckboxContainer vpTheme={theme}>
        <Checkbox
          checked={smsOptIn}
          onCheckedChange={() => setSmsOptIn(!smsOptIn)}
          data-testid='order-review__agree-terms__checkbox'
          aria-label={'Send me SMS updates about my prescriptions'}
        />
        <Text>Send me SMS updates about my prescriptions</Text>
      </CheckboxContainer>
      <CtaWrapper>
        <Text>
          If something doesn’t look right,{' '}
          <SupportLink href='/contact?issue=account_creation' style={{ color: theme.colors['primary-500'] }}>
            contact Patient Support
          </SupportLink>
        </Text>
        <Spacer />
        <ButtonsWrapper>
          <ThemedButton disabled={false} onClick={handleSubmit} vpTheme={theme}>
            {'Continue'}
          </ThemedButton>
        </ButtonsWrapper>
      </CtaWrapper>
      <ToastMessage
        timeout={60000}
        onDismiss={() => {
          setShowError(false)
        }}
        state={'error'}
        visible={showError}
        onTimeout={() => {
          setShowError(false)
        }}
      >
        {' '}
        <Text>
          Unfortunately, we could not activate your account. Please try again. If the issue persists, email our <SupportLink />
        </Text>
      </ToastMessage>
    </CheckoutPage>
  )
}

export default PatientRecordVerification
