import { Spacer, Text } from '@truepill/react-capsule'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { insuranceAccountPageDefaults } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { PatientInsurance } from '../../interfaces'
import { IAccountManagementInsuranceFields } from '../../types/generated/contentful'
import LoadingAnimation from '../LoadingAnimation'
import { LoadingAnimationWrapper, StyledActionContainer, StyledHeader, Wrapper } from './styledComponents'

interface Props {
  currentInsurance: PatientInsurance
  isDeletingInsurance: boolean
  pageContent: IAccountManagementInsuranceFields | undefined
  cancel: () => void
  handleDeleteInsurance: (insurance: PatientInsurance) => Promise<void>
}

const DeleteInsurance = ({ currentInsurance, isDeletingInsurance, pageContent, cancel, handleDeleteInsurance }: Props): ReactElement => {
  const { theme: vpTheme } = useContentfulTheme()
  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    handleDeleteInsurance(currentInsurance)
  }, [currentInsurance, handleDeleteInsurance])

  return (
    <>
      {isDeletingInsurance && (
        <LoadingAnimationWrapper vpTheme={vpTheme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <StyledHeader variant='4xl'>{pageContent?.deleteInsuranceModalTitle}</StyledHeader>
      <Spacer size='lg' />
      <Wrapper vpTheme={vpTheme}>
        <Text bold>{`${pageContent?.cardholderIdLabel || insuranceAccountPageDefaults.cardholderIdLabel}: ${currentInsurance.cardholder_id}`}</Text>
        <Text>{`${pageContent?.rxBinLabel || insuranceAccountPageDefaults.rxBinLabel}: ${currentInsurance.rx_bin}`}</Text>
        <Text>
          {pageContent?.rxPcnLabel || insuranceAccountPageDefaults.rxPcnLabel}: {currentInsurance.pcn}
        </Text>
        <Text>{`${pageContent?.rxGroupLabel || insuranceAccountPageDefaults.rxGroupLabel}: ${currentInsurance.rx_group}`}</Text>
      </Wrapper>
      <Spacer size='lg' />
      <Text css={{ color: vpTheme.colors['typography-medium'] }}>{pageContent?.deleteInsuranceModalDescription}</Text>
      <Spacer size='md' />
      <StyledActionContainer>
        <ThemedButton variant='dark-text' size='lg' onClick={handleCancel} disabled={isDeletingInsurance} vpTheme={vpTheme}>
          {pageContent?.deleteInsuranceModalCancelButton}
        </ThemedButton>
        <Spacer />
        <ThemedButton danger={true} size='lg' onClick={handleDelete} disabled={isDeletingInsurance} vpTheme={vpTheme}>
          {pageContent?.deleteInsuranceModalConfirmButton}
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default DeleteInsurance
