import { Header, Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { mediaLargerThan } from 'common/styles/variables'
import { X } from 'react-feather'
import styled from 'styled-components/macro'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedComponent } from '../../../common/styledComponents/types'

const TextFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const ContainerGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${mediaLargerThan.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.2rem;
    justify-content: space-between;
  }
`

const ErrorMessage = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
`

const Form = styled.form`
  margin: 2rem 0;
`

const SubmitButton = styled(ThemedButton)`
  margin-top: 2rem;
  height: 64px;
  width: 100%;
  ${mediaLargerThan.tablet} {
    max-width: 16rem;
    float: right;
  }
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  ${mediaLargerThan.tablet} {
    display: flex;
  }
  justify-content: center;
`

const StepTitleContainer = styled.div`
  display: flex;
  align-items: center;
`
const StepTitleText = styled(Header)`
  padding-left: 0.5rem;
  font-size: 1.5rem;
`
const StepDescriptionText = styled.p`
  padding: 0.5rem 0 1rem 0;
`

const HrSpacer = styled.hr<ThemedComponent>`
  margin: 3rem 0;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300'] ?? defaultTheme.colors['gray-300']};
`

const ToggleManualPharmacyEntry = styled(ThemedButton)`
  color: ${({ vpTheme }) => vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']};
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  text-decoration: underline;
  svg {
    height: 18px;
  }
`

const FormGroup = styled.div`
  ${mediaLargerThan.tablet} {
    justify-content: space-between;
  }
`

const AddPrescriptionButton = styled(ThemedButton)`
  display: flex;
  padding: 12px 24px 12px 24px;
  margin: 24px 0 24px 0;
  width: 10rem !important;
  height: 48px !important;
  align-items: center;
  justify-content: flex-end;

  ${mediaLargerThan.tablet} {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ButtonFieldContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  ${mediaLargerThan.tablet} {
    justify-content: flex-end;
  }

  justify-content: center;
`

const PrescriptionInfo = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  padding: 16px 16px 1px 16px;
  border-radius: 8px;
`

const MedicationListContainer = styled.div`
  margin: 1rem 0;
`

const StyledMedicationContainer = styled.div<ThemedComponent>`
  background: ${({ vpTheme }) => vpTheme.colors['functional-info-light']};
  border-left: 0.25rem solid ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  border-radius: ${({ vpTheme }) => vpTheme.radii?.sm};
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`

const CloseButtonIcon = styled(X)`
  font-size: 1.25rem;
  padding: none !important;
  display: inline-block;
`
const StyledButton = styled(ThemedButton)`
  padding: 15px;
  width: 6rem;
  height: 6rem;
  ${mediaLargerThan.tablet} {
    width: 4.5rem;
    height: 4.5rem;
  }
`

export {
  AddPrescriptionButton,
  ButtonFieldContainer,
  CloseButtonIcon,
  ContainerGroup,
  ErrorMessage,
  Form,
  FormGroup,
  HrSpacer,
  MedicationListContainer,
  PrescriptionInfo,
  StepDescriptionText,
  StepTitleContainer,
  StepTitleText,
  StyledButton,
  StyledMedicationContainer,
  SubmitButton,
  SubmitButtonContainer,
  TextFieldContainer,
  ToggleManualPharmacyEntry,
}
