import { Text } from '@truepill/react-capsule'
import React from 'react'

import { SnowFlakeIcon } from '../../assets/Icons'
import { VP_5632_2_DAY_SHIPPING } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { ColdChainNotificationContainer, IconContainer, TextContainer } from './styledComponents'

const ColdChainNotification: React.FunctionComponent = (): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const { [VP_5632_2_DAY_SHIPPING]: vp56322DayShipping } = useLDFlagsWithLocalStorage([VP_5632_2_DAY_SHIPPING])

  return (
    <ColdChainNotificationContainer vpTheme={theme}>
      <IconContainer>
        <SnowFlakeIcon vpTheme={theme} />
      </IconContainer>
      <TextContainer vpTheme={theme}>
        <Text bold>
          {vp56322DayShipping
            ? 'A medication in your cart requires refrigeration and must be shipped with 2-Day or overnight delivery.'
            : 'A medication in your cart requires refrigeration and must ship via Expedited shipping.'}
        </Text>
        <Text variant='body-sm'>
          {vp56322DayShipping
            ? 'Due to carrier restrictions, most orders will be shipped Monday - Wednesday except for federal or state holidays.'
            : 'Due to carrier restrictions, orders received on Thursday (or near a federal holiday) may be shipped out the next available business day.'}
        </Text>
      </TextContainer>
    </ColdChainNotificationContainer>
  )
}

export default ColdChainNotification
