import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import { ReactElement } from 'react'
import { components, DropdownIndicatorProps, GroupBase, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

import { SelectDownArrowIcon } from '../../../assets/Icons'
import { useContentfulTheme } from '../../../hooks'
import { SelectedPrescription } from '../useTransferOut'
import { StyledPrescriptionSelector } from './styledComponents'
import { PrescriptionOption, usePrescriptionSelector } from './usePrescriptionSelector'

export interface PrescriptionSelectorProps {
  preSelectedToken?: string
  patientToken: string
  customerToken: string
  onPrescriptionSelect: (selectedPrescription: SelectedPrescription | null) => void
}

const Option = (props: OptionProps<PrescriptionOption, false, GroupBase<PrescriptionOption>>) => {
  return (
    <components.Option {...props}>
      <StyledPrescriptionSelector.OptionContainer>
        <Text>{props.data.label}</Text>
      </StyledPrescriptionSelector.OptionContainer>
    </components.Option>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps<PrescriptionOption, false, GroupBase<PrescriptionOption>>) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectDownArrowIcon />
    </components.DropdownIndicator>
  )
}

const PrescriptionSelector = ({ patientToken, customerToken, onPrescriptionSelect, preSelectedToken }: PrescriptionSelectorProps): ReactElement => {
  const { theme } = useContentfulTheme()
  const { fetchTransferOutPrescriptionOptions, selectedOption, setPrescriptionOption } = usePrescriptionSelector({
    patientToken,
    customerToken,
    onPrescriptionSelect,
    preSelectedRxToken: preSelectedToken,
  })

  const componentConfig: Partial<SelectComponents<PrescriptionOption, false, GroupBase<PrescriptionOption>>> = {
    Option,
    IndicatorSeparator: () => null,
    DropdownIndicator,
  }

  const gray = theme.colors['gray-700'] ?? defaultTheme.colors['gray-700']
  const blue = theme.colors['functional-info-dark'] ?? defaultTheme.colors['functional-info-dark']

  return (
    <StyledPrescriptionSelector.Container>
      <AsyncSelect
        aria-label='Select prescription to transfer'
        components={componentConfig}
        defaultOptions
        onChange={setPrescriptionOption}
        value={selectedOption}
        isSearchable={false}
        loadOptions={fetchTransferOutPrescriptionOptions}
        styles={{
          input: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          singleValue: (base) => ({
            ...base,
            padding: '0.5rem 0',
          }),
          control: (base, state) => ({
            ...base,
            padding: '0.5rem 0.75rem',
            fontFamily: 'Lato, sans-serif',
            border: state.isFocused ? `1px solid ${blue}` : `1px solid ${gray}`,
            borderRadius: '8px',
            boxShadow: state.isFocused ? `0 0 0 2px ${blue}` : 'none',
          }),
          option: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          valueContainer: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
          }),
          menuList: (base) => ({
            ...base,
            fontFamily: 'Lato, sans-serif',
            boxShadow: `0px 6px 8px 0px ${theme.colors['gray-300'] ?? defaultTheme.colors['gray-300']}`,
            borderRadius: '8px',
          }),
        }}
      />
    </StyledPrescriptionSelector.Container>
  )
}

export default PrescriptionSelector
