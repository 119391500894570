import { Document } from '@contentful/rich-text-types'
import { theme } from '@truepill/react-capsule'
import { OrderDetails, OrderRejectionInfo, OrderTriageInfo } from '@vpharm-platform/shared'

import { OrderErrorMessageMicrocopy, OrderTriageMessageMicrocopy } from '../pages/Orders/microcopy'

const triageCodes: { [key: string]: string } = {
  clinicalOutreachToMD: 'T006',
  contraindications: 'T032',
  customerClarificationNeeded: 'T004',
  customerRequestedHold: 'T008',
  drugDrugInteraction: 'T033',
  drugUtilizationReview: 'T034',
  doseClarification: 'T035',
  drugAllergy: 'T036',
  drugDisease: 'T037',
  highCopay: 'T024',
  incorrectMD: 'T038',
  incorrectDaySupply: 'T039',
  incorrectDrug: 'T040',
  incorrectPayerPaymentMethod: 'T042',
  incorrectQuantity: 'T043',
  incorrectSig: 'T044',
  invalidAddress: 'T001',
  orderContainsControlledSubstance: 'T010',
  other: 'T007',
  outOfStock: 'T005',
  patientNameDOBMismatch: 'T045',
  rxClarificationRequired: 'T013',
  rPhClarificationRequired: 'T012',
  unableToAutomaticallyConfirmCopay: 'T025',
  replacementOrder: 'T011',
  controlledRxUtilizationHold: 'T014',
  formularyCheckFailed: 'T046',
  offFormularyPrescribedNDC: 'T018',
  prescriptionExpired: 'T056',
  shippingPriorityChange: 'T054',
  noGenderEnteredForPatient: 'T015',
  patientOutreachRequired: 'T021',
  refillTooSoon: 'T009',
  expiredDate: 'T026',
  effectiveDateInTheFuture: 'T027',
  locationIsNotLicensedToShipToThisState: 'T016',
  rxOrderDiscrepancy: 'T053',
}

export const rejectionCodes: { [key: string]: string } = {
  orderCancelledByCustomer: 'R0',
  customerCancellationRequest: 'R005',
  orderRejectedByTruepillForCustomer: 'R005',
  priorAuthorizationRequired: '75',
  mailOrderFillsExceeded: 'R010',
  outOfStockUnavailableToOrder: 'R3',
  badAddressDetails: 'R2',
  prescriptionExpired: 'R024',
  dobDateOfBirthMismatch: '9',
  nameMismatch: '62',
  refillTooSoon: '79',
  duplicateTherapy: 'DT',
  invalidReplacementOrder: 'ORDER_INVALID_REPLACEMENT',
  noEscriptMissingErx: 'PC',
  autoRefillConsentRequired: 'PRIMARY_INSURANCE_REQUIRED',
  primaryInsuranceRequired: 'PRIMARY_INSURANCE_REQUIRED',
  billedIncorrectly: 'R0',
  cancellationRequest: 'R0',
  returnToSender: 'R4',
  closedNetwork: 'R003',
  coverageNotEffective: 'R004',
  qtyLimitedByInsurance: 'R015',
  testOrder: 'R017',
  otherOtherReason: 'R019',
  coverageTerminated: 'R025',
  drugInteractions: 'DI',
  damagedDuringShipping: 'R0',
  lostDuringShipping: 'R0',
  badInsuranceDetails: '12',
  noRefillsQtyRemaining: 'NR',
  highCopay: 'R009',
  clinicalOutreachToMD: 'R022',
  sigClarificationNeeded: 'R022',
  nonFormulary: 'R023',
  quantityMismatch: 'R026',
  rXExpired: 'R035',
  copayAmountChanged: 'R050',
  duplicateOrder: 'R1',
  unavailableToOrder: 'R3',
  transferOutOfPharmacy: 'R5',
  contraindicationDrugDiseaseAllergy: 'TEMPTEMPCONTRA',
}

export interface PrescriptionDisplayInfo {
  statusTag?: string
  statusMessage?: string
  additionalDetails?: string
  issue?: string
  richTextMessage?: Document
}

export const parseTriageMessage = (
  triageInfo?: OrderTriageInfo,
  orderTriageMessage?: OrderTriageMessageMicrocopy | null,
): PrescriptionDisplayInfo => {
  const response: PrescriptionDisplayInfo = {
    statusTag: 'This order is currently under review',
  }

  if (triageInfo) {
    const { code } = triageInfo

    const contentfulKey = Object.keys(triageCodes).find((key) => triageCodes[key] === code)

    if (typeof contentfulKey === 'string') {
      const contentfulInfo = orderTriageMessage && orderTriageMessage[contentfulKey]
      response.statusMessage = contentfulInfo?.message || `${orderTriageMessage?.other.message}`
      response.issue = contentfulInfo?.displayDescription ? triageInfo.description || undefined : undefined
      response.additionalDetails = contentfulInfo?.displayAdditionalInformation ? triageInfo.additionalInfo || undefined : undefined
      response.richTextMessage = contentfulInfo?.richTextMessage || undefined
    }
  }
  return response
}

export const parseRejectionMessage = (
  rejectionInfo?: OrderRejectionInfo,
  orderErrorMessage?: OrderErrorMessageMicrocopy | null,
): PrescriptionDisplayInfo => {
  const response: PrescriptionDisplayInfo = {
    statusTag: 'This order was rejected',
  }

  if (rejectionInfo) {
    const { rejectionCode } = rejectionInfo

    const contentfulKey = Object.keys(rejectionCodes).find((key) => rejectionCodes[key] === rejectionCode)

    if (typeof contentfulKey === 'string') {
      const contentfulInfo = orderErrorMessage && orderErrorMessage[contentfulKey]
      response.statusMessage = contentfulInfo?.displayMessage ? contentfulInfo?.message : undefined
      response.issue = contentfulInfo?.displayDescription ? rejectionInfo.description || undefined : undefined
      response.additionalDetails = contentfulInfo?.displayAdditionalInformation ? rejectionInfo.message || undefined : undefined
      response.richTextMessage = contentfulInfo?.richTextMessage || undefined
    }
  }

  return response
}

export const parseColorFromStatus = (status: OrderDetails['status']): string => {
  switch (status) {
    case 'REJECTED':
    case 'FAILED':
      return theme.colors['functional-error-dark'].computedValue
    case 'TRIAGE':
      return theme.colors['functional-warning-dark'].computedValue
    case 'SUCCESS':
      return theme.colors['typography-medium'].computedValue
    case 'PENDING':
      return theme.colors['functional-info-dark'].computedValue
  }
}
