import { Header, Spacer, Text } from '@truepill/react-capsule'
import { CardType, isNotNullOrUndefined, OrderDetails as OrderDetailsResponse, OrderItemsResponse } from '@vpharm-platform/shared'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { CreditCardBrandIcon, PrintIcon } from '../../../assets/Icons'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { PrescriptionCardMicrocopy } from '../../../Components/MedicationCard/microcopy'
import SupportLink from '../../../Components/SupportLink'
import ToastMessage from '../../../Components/ToastMessage'
import { ORDERS_PATH } from '../../../constants'
import { TOAST_DEFAULT_TIMEOUT, ToastState } from '../../../constants/toastConstants'
import { getOrdersPage, useContentfulTheme, useCustomerProfile } from '../../../hooks'
import { LinkOrigin, useAnalytics } from '../../../hooks/analytics-context'
import { MicroCopyResourceType } from '../../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../../hooks/contentful/useContentfulMicrocopy'
import { selectedPatientTokenAtom } from '../../../persistRecoil'
import { orderService } from '../../../services'
import { formatArrivalDate, toUtcString } from '../../../utils/dateUtilities'
import { capitalizeFirstLetter } from '../../../utils/stringUtilities'
import {
  BackLink,
  BorderBox,
  BoxNoBorder,
  CancelWarning,
  CardDetails,
  CardDetailsSection,
  CardIcon,
  FilledOnText,
  MedicationDetails,
  MedicationInfoContainer,
  MedicationPriceDetails,
  MedicationPricingDetails,
  OrderBreakdownSection,
  OrderBreakdownShippingName,
  OrderDetailsContainer,
  OrderedOnSection,
  OrderId,
  OrderReceiptSection,
  PatientInfoContainer,
  PaymentDetailsSection,
  PharmacyDetailsSection,
  PriceContainer,
  PriceContainerNoMargin,
  PrintReceipt,
  PrintReceiptText,
  SeparatorPipe,
  ShippingDetailsSection,
  ShippingMinMaxText,
  TrackingNumberBorderBox,
  TrackingNumberText,
  TwoPriceContainer,
} from './styledComponents'

enum ShippingStatus {
  'TRANSIT' = 'SHIPPED',
  'DELIVERED' = 'DELIVERED',
  'PROCESSING' = 'PROCESSING',
}

interface OrderDetailDynamicContent {
  savingsPriceText: string
  totalSavingsText: string
}

const DEFAULT_CANCELLATION_MESSAGE = 'To cancel or receive further assistance with your order, please'

const CancellationMessage = ({ color }: { color?: string }): React.ReactElement => {
  const [cancellationMessage, setCancellationMessage] = useState('')

  useEffect(() => {
    const setMessageFromContentful = async () => {
      try {
        const orderPageDetails = await getOrdersPage()
        setCancellationMessage(orderPageDetails?.fields.cancellationMessage || DEFAULT_CANCELLATION_MESSAGE)
      } catch {}
    }
    setMessageFromContentful()
  }, [])

  return (
    <CancelWarning variant='body' color={color}>
      {cancellationMessage} <SupportLink>contact Patient Support</SupportLink>.
    </CancelWarning>
  )
}

const OrderDetails: React.FunctionComponent = () => {
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { customerProfile } = useCustomerProfile()
  const [etaMessage, setEtaMessage] = useState<string | null>(null)
  const [cardMessage, setCardMessage] = useState<string | null>(null)
  const [orderDetails, setOrderDetails] = useState<OrderDetailsResponse | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showError, setShowError] = useState<boolean>(false)
  const [orderStatus, setOrderStatus] = useState<ShippingStatus | ''>('')
  const isPriceTransparencyEnabled = customerProfile.allowPriceTransparency
  const { isLoadingContent: isLoadingOrderDetailContent, microcopy: dynamicContent } = useContentfulMicrocopy<OrderDetailDynamicContent>(
    MicroCopyResourceType.OrderDetailPage,
  )
  const { microcopy: content, isLoadingContent: isLoadingRxCardContent } = useContentfulMicrocopy<PrescriptionCardMicrocopy>(
    MicroCopyResourceType.PrescriptionCard,
  )
  const { trackButtonClickEvent, trackLinkClickEvent } = useAnalytics()

  const history = useHistory()

  const { theme } = useContentfulTheme()

  const params = useParams<{ order_id: string }>()

  const getMedicationPrice = (item: OrderItemsResponse): string => {
    if (isNotNullOrUndefined(item.copay_amount)) return '$' + item.copay_amount?.toFixed(2)
    else return 'Medication price not available'
  }

  useEffect(() => {
    async function getOrderDetails() {
      const customerToken = customerProfile.vpharmCustomerToken
      try {
        const order = await orderService.getOrderById({
          customerToken: customerToken,
          orderId: params.order_id,
          patientToken: selectedPatientToken,
        })

        setOrderDetails(order)
        setCardMessage(
          order.payment_method.brand && order.payment_method.last4
            ? `${capitalizeFirstLetter(order.payment_method.brand)} ending in ${order.payment_method.last4}`
            : 'Not Required',
        )
        setOrderStatus(ShippingStatus[order.shipping?.status as keyof typeof ShippingStatus] || 'PROCESSING')

        const eta = formatArrivalDate(order.shipping?.delivered_at || order.shipping.eta)
        setEtaMessage(eta)
      } catch (ex) {
        setShowError(true)
      } finally {
        setIsLoading(false)
      }
    }

    getOrderDetails()
  }, [params, customerProfile.vpharmCustomerToken, selectedPatientToken])

  useEffect(() => {
    if (orderDetails && !(orderDetails.status === 'SUCCESS')) {
      history.push(ORDERS_PATH)
    }
  }, [history, orderDetails])

  const handlePrintReceipt = () => {
    trackButtonClickEvent('print-receipt-button', 'print receipt')
    try {
      // Workaround for Safari
      // Handle silently failing for Firefox
      // document.execCommand is deprecated but not replacement in place yet
      if (!document.execCommand('print', false)) {
        window.print()
      }
    } catch {
      window.print()
    }
  }

  const contentIsLoading = isLoadingOrderDetailContent || isLoadingRxCardContent
  if (isLoading || contentIsLoading) {
    return <LoadingAnimation />
  }

  return (
    <OrderDetailsContainer>
      <Spacer size='2xl'></Spacer>
      <BackLink
        to='/orders'
        color={theme.colors['typography-medium']}
        onClick={() => trackLinkClickEvent('Back', 'return to orders page')}
        aria-label='back'
      >
        {'< Back'}
      </BackLink>
      <Spacer size='md'></Spacer>
      <Header bold variant='4xl' data-testid='header-order-receipt'>
        Order receipt
      </Header>
      <ToastMessage
        timeout={TOAST_DEFAULT_TIMEOUT}
        onDismiss={() => {
          setShowError(false)
        }}
        state={ToastState.ERROR}
        visible={showError}
        onTimeout={() => {
          setShowError(false)
        }}
      >
        An unexpected error occurred, failed to retrieve the order
      </ToastMessage>
      <OrderReceiptSection>
        <OrderedOnSection>
          <Text data-testid='ordered-on'>Ordered on {toUtcString(orderDetails?.created_at as string, 'MMMM DD, YYYY')}</Text>
          <SeparatorPipe data-testid='ordered-on-separator'>|</SeparatorPipe>
          <OrderId color={theme.colors['typography-medium']} data-testid='order-id'>
            ORDER # {orderDetails?.order_identifier}
          </OrderId>
        </OrderedOnSection>
        <PrintReceipt variant={'primary-outline'} vpTheme={theme} data-testid='print-receipt-button' onClick={handlePrintReceipt}>
          <PrintReceiptText>
            <PrintIcon vpTheme={theme} />
            Print receipt
          </PrintReceiptText>
        </PrintReceipt>
      </OrderReceiptSection>
      {orderDetails?.filled_at && (
        <FilledOnText color={theme.colors['typography-medium']} data-testid='filled-on'>
          Filled on: {toUtcString(orderDetails.filled_at, 'MMMM DD, YYYY')}
        </FilledOnText>
      )}
      <TrackingNumberBorderBox vpTheme={theme}>
        <Text bold>{orderStatus}</Text>
        {['SHIPPED', 'DELIVERED'].includes(orderStatus) ? (
          <TrackingNumberText
            color={theme.colors['functional-info-dark']}
            data-testid='tracking-number'
            href={orderDetails?.shipping?.tracking_url as string}
            onClick={() => trackLinkClickEvent('Tracking #', 'navigate to track shipment link')}
            target='_blank'
          >
            Tracking # {orderDetails?.shipping?.tracking_number}
          </TrackingNumberText>
        ) : (
          <TrackingNumberText color={theme.colors['functional-info-dark']} data-testid='tracking-number'>
            Tracking number will show here when shipped
          </TrackingNumberText>
        )}
      </TrackingNumberBorderBox>
      {orderDetails?.pharmacy && (
        <PharmacyDetailsSection color={theme.colors['functional-info-dark']} data-testid='pharmacy-details-section'>
          <Header bold variant='xl' data-testid='header-shipping-details'>
            Pharmacy
          </Header>
          <BorderBox vpTheme={theme}>
            {orderDetails.pharmacy.name && <Text data-testid='pharmacy-name'>{orderDetails.pharmacy.name}</Text>}
            {orderDetails.pharmacy.address && <Text data-testid='pharmacy-address'>{orderDetails.pharmacy.address}</Text>}
            {orderDetails.pharmacy.phone && (
              <Text data-testid='pharmacy-phone'>
                <a
                  href={`tel:${orderDetails.pharmacy.phone}`}
                  onClick={() => trackLinkClickEvent(`pharmacy phone number: ${orderDetails.pharmacy?.phone}`, 'call pharmacy', LinkOrigin.Body)}
                >
                  {orderDetails.pharmacy.phone}
                </a>
              </Text>
            )}
            {orderDetails.pharmacy.email && (
              <Text data-testid='pharmacy-email'>
                <a
                  href={`mailto:${orderDetails.pharmacy.email}`}
                  onClick={() => trackLinkClickEvent(`pharmacy email: ${orderDetails.pharmacy?.email}`, 'email pharmacy', LinkOrigin.Body)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {orderDetails.pharmacy.email}
                </a>
              </Text>
            )}
          </BorderBox>
        </PharmacyDetailsSection>
      )}
      {orderDetails?.shipping && (
        <ShippingDetailsSection>
          <Header bold variant='xl' data-testid='header-shipping-details'>
            Shipping details
          </Header>
          <BorderBox vpTheme={theme}>
            <Text bold data-testid='header-address'>
              Address
            </Text>
            <Text data-testid='shipping-details-patient-name'>{orderDetails.shipping.address.name}</Text>
            <Text data-testid='address-line-1'>{orderDetails.shipping.address.address1}</Text>
            <Text data-testid='address-line-2'>
              {orderDetails.shipping.address.city}, {orderDetails.shipping.address.state} {orderDetails.shipping.address.zip}
            </Text>
          </BorderBox>

          <BorderBox vpTheme={theme}>
            <Text bold data-testid='header-shipping-method'>
              Method
            </Text>
            <Text data-testid='shipping-method'>
              {orderDetails.shipping.method.name}
              {orderDetails.shipping.method.min_delivery_days && orderDetails.shipping.method.max_delivery_days && (
                <ShippingMinMaxText color={theme.colors['typography-medium']} as='span'>
                  {' '}
                  ({orderDetails.shipping.method.min_delivery_days}-{orderDetails.shipping.method.max_delivery_days} business days)
                </ShippingMinMaxText>
              )}
            </Text>
            {etaMessage && (
              <Text data-testid='eta'>
                {orderDetails.shipping.delivered_at ? `Delivered:` : `Estimated delivery:`} {etaMessage}
              </Text>
            )}
          </BorderBox>
        </ShippingDetailsSection>
      )}

      <Spacer size='md'></Spacer>
      <OrderBreakdownSection>
        <Header bold variant='xl' data-testid='header-order-breakdown'>
          Order details
        </Header>

        {orderDetails?.items.map((item) => {
          const retailPriceLineThrough =
            (item.insurance_copay_amount && item.insurance_copay_amount > 0) || (item.total_price_after_savings && item.total_price_after_savings > 0)
              ? 'line-through'
              : 'none'
          const insuranceLineThrough =
            item.insurance_copay_amount && item.insurance_copay_amount > 0 && item.total_price_after_savings && item.total_price_after_savings > 0
              ? 'line-through'
              : 'none'
          return (
            <BorderBox key={item.rx_number} vpTheme={theme} data-testid={`medication-container-${item.rx_number}`}>
              <PriceContainer>
                <Text bold data-testid='medication-name'>
                  {item.brand_display_name} {item.generic_display_name ? `(${item.generic_display_name})` : ''}
                </Text>
                <Text bold data-testid='medication-price'>
                  {getMedicationPrice(item)}
                </Text>
              </PriceContainer>

              <MedicationInfoContainer>
                <Text style={{ fontSize: '0.875rem', lineHeight: '20px' }} data-testid='quantity'>
                  Quantity: {item.quantity} count ({item.days_supply} day supply)
                </Text>
                <MedicationDetails color={theme.colors['typography-medium']}>
                  <Text data-testid='rx-number'>Rx #: {item.rx_number}</Text>
                  <Text data-testid='npi'>NPI: {item.prescriber_npi}</Text>
                  <Text data-testid='ndc'>NDC: {item.prescribed_ndc}</Text>
                </MedicationDetails>
              </MedicationInfoContainer>
              <PatientInfoContainer color={theme.colors['typography-medium']}>
                <Text data-testid='order-details-patient-name'>Patient: {item.patient_name}</Text>
              </PatientInfoContainer>
              {isPriceTransparencyEnabled && (
                <MedicationPricingDetails>
                  {item.retail_price !== null && (
                    <MedicationPriceDetails>
                      <Text>Retail price</Text>
                      <Text style={{ textDecoration: `${retailPriceLineThrough}` }}>${item.retail_price.toFixed(2)}</Text>
                    </MedicationPriceDetails>
                  )}
                  {item.insurance_copay_amount !== null && (
                    <MedicationPriceDetails>
                      <Text>{content?.priceAfterInsurance}</Text>
                      <Text style={{ textDecoration: `${insuranceLineThrough}` }}>${item.insurance_copay_amount.toFixed(2)}</Text>
                    </MedicationPriceDetails>
                  )}
                  {item.total_price_after_savings !== null && (
                    <MedicationPriceDetails>
                      <Text>{dynamicContent?.savingsPriceText}</Text>
                      <Text>${item.total_price_after_savings.toFixed(2)}</Text>
                    </MedicationPriceDetails>
                  )}
                  {item.savingsAmount !== null && item.savingsAmount > 0 && (
                    <MedicationPriceDetails>
                      <Text style={{ color: `${theme.colors['functional-success-dark']}` }}>{dynamicContent?.totalSavingsText}</Text>
                      <Text style={{ color: `${theme.colors['functional-success-dark']}` }}>${item.savingsAmount.toFixed(2)}</Text>
                    </MedicationPriceDetails>
                  )}
                </MedicationPricingDetails>
              )}
            </BorderBox>
          )
        })}

        <BoxNoBorder vpTheme={theme}>
          <PriceContainerNoMargin>
            <Text data-testid='subtotal-label'>Subtotal</Text>
            <Text data-testid='subtotal'>${orderDetails?.price_summary.subtotal.toFixed(2)}</Text>
          </PriceContainerNoMargin>
          <PriceContainerNoMargin>
            <Text data-testid='shipping-label'>
              Shipping{' '}
              {orderDetails?.shipping?.method.name && (
                <OrderBreakdownShippingName color={theme.colors['typography-medium']}>
                  ({orderDetails.shipping.method.name})
                </OrderBreakdownShippingName>
              )}
            </Text>
            <TwoPriceContainer>
              <Text data-testid='shipping-price'>${orderDetails?.price_summary.shipping.toFixed(2)}</Text>
            </TwoPriceContainer>
          </PriceContainerNoMargin>

          <PriceContainerNoMargin>
            <Text>Payment processing fee</Text>
            <TwoPriceContainer>
              <Text>$0.00</Text>
            </TwoPriceContainer>
          </PriceContainerNoMargin>
          <PriceContainerNoMargin>
            <Text data-testid='tax-label'>Tax</Text>
            <Text data-testid='tax'>${orderDetails?.price_summary.tax.toFixed(2)}</Text>
          </PriceContainerNoMargin>
          <PriceContainerNoMargin>
            <Text data-testid='order-total-label'>Total</Text>
            <Text data-testid='order-total'>${orderDetails?.price_summary.total.toFixed(2)}</Text>
          </PriceContainerNoMargin>
        </BoxNoBorder>
      </OrderBreakdownSection>
      <Spacer size='md'></Spacer>
      <PaymentDetailsSection>
        <Header bold variant='xl' data-testid='header-order-breakdown'>
          Payment details
        </Header>
        <BorderBox vpTheme={theme}>
          <Text bold data-testid='header-payment-method'>
            Payment method
          </Text>
          <PriceContainerNoMargin>
            <CardDetailsSection>
              {orderDetails?.payment_method.brand && (
                <CardIcon>
                  <CreditCardBrandIcon cardBrand={orderDetails?.payment_method.brand as CardType} />
                </CardIcon>
              )}
              <CardDetails data-testid='card'>{cardMessage}</CardDetails>
            </CardDetailsSection>

            <Text data-testid='card-order-total'>${orderDetails?.price_summary.total.toFixed(2)}</Text>
          </PriceContainerNoMargin>
        </BorderBox>

        <CancellationMessage color={theme.colors['functional-info-dark']} />
      </PaymentDetailsSection>
    </OrderDetailsContainer>
  )
}

export default OrderDetails
