import { createContext, useContext } from 'react'

import { DISPLAY_MAINTENANCE_PAGE, VP1192_DISPLAY_HOMEPAGE } from '../../constants'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'

export const LDContextWithLocalStorage = createContext<Record<string, boolean>>({})
export const useLDContextWithLocalStorage = (): Record<string, boolean> => useContext(LDContextWithLocalStorage)

const LDProvider: React.FunctionComponent = ({ children }) => {
  const flags = useLDFlagsWithLocalStorage([VP1192_DISPLAY_HOMEPAGE, DISPLAY_MAINTENANCE_PAGE])

  return <LDContextWithLocalStorage.Provider value={flags}>{children}</LDContextWithLocalStorage.Provider>
}

export default LDProvider
