import { useEffect, useState } from 'react'

import type { IAccountSettingsPageFields } from '../../types/generated/contentful'
import { getPageConfiguration } from './contentfulService'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

interface UseAccountSettingContent {
  loading: boolean
  error: boolean
  content?: IAccountSettingsPageFields | null
}

export const useAccountSettingsContent = (): UseAccountSettingContent => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<IAccountSettingsPageFields | undefined>()
  const customerName = useGetCustomerNameFromSubdomain()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, 'accountManagementSettings')
        setContent(page?.fields)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName])

  return {
    content,
    loading,
    error,
  }
}
