import { defaultTheme } from '@vpharm-platform/shared'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components/macro'

import { Modal } from '../../../common/styledComponents/StyledModal'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const StyledModal = styled(Modal)`
  [data-cap-modal-close-button='true'] {
    top: 1.8rem;
  }
  [data-reach-dialog-content] {
    padding: 1.5rem;
  }
`

const InsuranceFormModal = styled(StyledModal)`
  [data-reach-dialog-content] {
    max-width: 800px;
  }
`

const DeleteInsuranceModal = styled(StyledModal)`
  [data-reach-dialog-content] {
    max-width: 31.25rem;
  }
`

const InsuranceBannerContainer = styled.div``

const HeaderContainer = styled.div<ThemedComponent>`
  h5 {
    -webkit-text-fill-color: ${({ vpTheme }) => vpTheme.colors['primary-700'] ?? defaultTheme.colors['primary-700']};
    padding: 0.5rem 0;
  }

  ${mediaLargerThan.tablet} {
    display: flex;
    align-items: center;
    gap: 1rem;

    h5 {
      font-size: 1.375rem;
      padding: 0 0 0.5rem 0;
    }
  }
`
const ContentContainer = styled.div<ThemedComponent>`
  a {
    color: ${({ vpTheme }) => vpTheme.colors['functional-error-dark'] ?? defaultTheme.colors['functional-error-dark']};
  }
`

const Icon = styled(ReactSVG)`
  width: 2.25rem;
  height: 2.25rem;
`

export { ContentContainer, DeleteInsuranceModal, HeaderContainer, Icon, InsuranceBannerContainer, InsuranceFormModal }
