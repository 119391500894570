import { Elements } from '@stripe/react-stripe-js'
import { BannerState, Header, SEO, Spacer, Toast } from '@truepill/react-capsule'
import React, { useCallback, useEffect, useState } from 'react'
import { initializeStripe } from 'services/StripeService'

import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { AccountLayout } from '../../../Components/PageLayoutWithSidebar'
import SavedPaymentMethods from '../../../Components/Payments/SavedPaymentMethods'
import { AccountPage } from '../../../constants'
import { useGetAccountManagementPaymentContent } from '../../../hooks/contentful/useGetAccountManagementPaymentContent'
import useUserPayments, { CreatePaymentData, Payment } from '../../../hooks/useUserPayments'
import Container from '../Container'
import StyledHeader from '../StyledHeader'

export interface BannerMessage {
  message: string
  type: BannerState
  timeout?: number
}

const PaymentPageContent: React.FunctionComponent = () => {
  const { content } = useGetAccountManagementPaymentContent()
  const [bannerMessage, setBannerMessage] = useState<BannerMessage | null>(null)
  const { paymentMethods, isLoading, isError, addPaymentMethod, setDefaultPaymentMethod, deletePaymentMethod } = useUserPayments()
  const { pharmCustomer } = useCustomerConfigContext()

  const setErrorMessage = useCallback(() => {
    const genericError = content?.failureBannerText || 'Something went wrong. Please refresh your browser and try again.'
    setBannerMessage({ message: genericError, type: 'error' })
  }, [content])

  useEffect(() => {
    if (isError) {
      setErrorMessage()
    }
  }, [isError, setErrorMessage])

  const handleAddPaymentMethod = async (data: CreatePaymentData) => {
    const paymentMethod = await addPaymentMethod(data)
    if (paymentMethod) {
      const message = content?.successBannerText || 'Your payment method has been successfully saved.'
      setBannerMessage({ message, type: 'success' })
    }
    return paymentMethod
  }

  const handleSetDefaultPaymentMethod = async (payment: Payment) => {
    const isSuccess = await setDefaultPaymentMethod(payment.paymentMethodId)
    if (!isSuccess) {
      setErrorMessage()
    }
  }

  const handleDeletePaymentMethod = async (id: string) => {
    const isSuccess = await deletePaymentMethod(id)
    if (isSuccess) {
      setBannerMessage({ message: 'Your payment method has been successfully deleted.', type: 'success' })
    } else {
      setErrorMessage()
    }
  }

  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <AccountLayout selected={AccountPage.Payments}>
      <SEO title={`${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <Container>
        <StyledHeader>
          <Header as='h1' variant='3xl' data-testid='payments-title'>
            Payments
          </Header>
        </StyledHeader>
        <Spacer size='xl' />
        <SavedPaymentMethods
          paymentMethods={paymentMethods}
          onAddPaymentMethod={handleAddPaymentMethod}
          onSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
          onDeletePaymentMethod={handleDeletePaymentMethod}
        />
      </Container>
      <Toast
        rootClassName='capsule-toast-container'
        timeout={bannerMessage?.timeout ?? 5000}
        position={{ vertical: 'top', horizontal: 'center' }}
        state={bannerMessage?.type}
        color='pastel'
        icon
        onTimeout={() => setBannerMessage(null)}
        visible={!!bannerMessage}
        css={{
          paddingTop: 5,
          paddingBottom: 5,
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderRight: '1px solid',
        }}
      >
        {bannerMessage?.message}
      </Toast>
    </AccountLayout>
  )
}

const AccountPayments: React.FunctionComponent = () => {
  const stripePromise = initializeStripe()
  return (
    <Elements options={{ fonts: [{ family: 'Lato', cssSrc: 'https://use.typekit.net/ysv7wrb.css' }] }} stripe={stripePromise}>
      <PaymentPageContent />
    </Elements>
  )
}

export default AccountPayments
