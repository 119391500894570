import React, { useEffect, useState } from 'react'

type fieldName = 'ccNumber' | 'expDate' | 'cvv'
type ValidatedFields = 'zip' | 'cardholderName'

interface PaymentModal {
  allCompleted: boolean
  nameIsValid: boolean
  zipIsValid: boolean
  cardholderName: string
  zip: string
  isDefault: boolean
  isSaved: boolean
  handleBlur: (field: ValidatedFields) => void
  onDefaultCheckedChange: (isComplete: boolean) => void
  onSavedCheckedChange: (isComplete: boolean) => void
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onZipChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  resetState: () => void
  setCardFieldCompleted: (isComplete: boolean, field: fieldName) => void
}

const usePaymentForm = (): PaymentModal => {
  const [completedState, setCompletedState] = useState({
    cardholderName: false,
    ccNumber: false,
    expDate: false,
    cvv: false,
    zip: false,
  })
  const [allCompleted, setAllCompleted] = useState(false)
  const [formFields, setFormFields] = useState({
    cardholderName: '',
    zip: '',
  })
  const [isDefault, setIsDefault] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [validFields, setValidFields] = useState({
    cardholderName: true,
    zip: true,
  })

  useEffect(() => {
    setAllCompleted(!Object.values(completedState).filter((completed) => !completed).length)
  }, [completedState])

  const handleBlur = (field: ValidatedFields) => {
    if (formFields[field] === '') {
      return
    }
    if (!completedState[field]) {
      setValidFields({ ...validFields, [field]: false })
    } else {
      setValidFields({ ...validFields, [field]: true })
    }
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    setFormFields({ ...formFields, cardholderName: name })
    if (/^[a-z ,.'-]+$/i.exec(name)) {
      setCompletedState({
        ...completedState,
        cardholderName: true,
      })
      setValidFields({ ...validFields, cardholderName: true })
    } else {
      setCompletedState({
        ...completedState,
        cardholderName: false,
      })
    }
  }

  const onDefaultCheckedChange = (isChecked: boolean) => {
    if (isChecked) {
      setIsSaved(true)
    }

    setIsDefault(isChecked)
  }
  const onSavedCheckedChange = (isChecked: boolean) => setIsSaved(isChecked)

  const onZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    let value = e.target.value
    if (e.target.value === '' || (re.test(e.target.value) && value.length <= 5)) {
      setFormFields({ ...formFields, zip: value })
      setValidFields({ ...validFields, zip: true })
    } else {
      value = formFields.zip
    }
    if (/^\b\d{5}\b/g.exec(value)) {
      setCompletedState({
        ...completedState,
        zip: true,
      })
    } else {
      setCompletedState({
        ...completedState,
        zip: false,
      })
    }
  }

  const resetState = () => {
    setValidFields({ ...validFields, zip: true })
    setValidFields({ ...validFields, cardholderName: true })
  }

  const setCardFieldCompleted = (isCompleted: boolean, field: string) => setCompletedState({ ...completedState, [field]: isCompleted })

  return {
    allCompleted,
    nameIsValid: validFields.cardholderName,
    zipIsValid: validFields.zip,
    cardholderName: formFields.cardholderName,
    zip: formFields.zip,
    isDefault,
    isSaved,
    handleBlur,
    onDefaultCheckedChange,
    onNameChange,
    onZipChange,
    resetState,
    setCardFieldCompleted,
    onSavedCheckedChange,
  }
}

export default usePaymentForm
