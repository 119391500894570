import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

import { CartData, Medication } from '../interfaces/'
import { cartData } from '../persistRecoil/cartData'
import { useAnalytics } from './analytics-context'

interface UseUpdateCartReturnType {
  cart: CartData
  clearCart: () => void
  addItem: (item: Medication) => void
  remove: (rxNumber: string) => void
  undoRemove: (rxNumber: string) => void
  setData: (data: CartData) => void
  deleteRemovedItems: () => void
  deleteRemovedItem: (rxNumber: string) => void
}

/**
 * Hook which exposes methods to remove / undo-remove medication items
 * as well as to clear and hydrate cart state.
 *
 * Note that the remove method marks the item as removed without actually removing it
 * from medications array. The undoRemove method reverses the change done by remove.
 */

export const useUpdateCart = (): UseUpdateCartReturnType => {
  const [cart, setCartData] = useRecoilState(cartData)
  const { trackButtonClickEvent } = useAnalytics()

  const markRemovalState = (state: boolean) => (rxNumber: string) => {
    trackButtonClickEvent('remove-rx-from-cart', state ? 'Remove' : 'Undo')
    setCartData((prevCart) => ({
      ...prevCart,
      medications: prevCart.medications.map(({ wasRemovedFromCart, ...restMed }) => ({
        ...restMed,
        wasRemovedFromCart: rxNumber === restMed.rxNumber ? state : wasRemovedFromCart,
      })),
    }))
  }

  const clearCart = useCallback(() => {
    setCartData({ medications: [], price: {} })
  }, [setCartData])

  const addItem = (item: Medication) => {
    setCartData((prevCart) => ({ ...prevCart, medications: [...prevCart.medications, item] }))
  }

  const deleteRemovedItems = useCallback(() => {
    setCartData((prevCart) => {
      const medications = prevCart.medications.filter((m) => !m.wasRemovedFromCart)

      return { ...prevCart, medications }
    })
  }, [setCartData])

  const deleteRemovedItem = useCallback(
    (rxNumber: string) => {
      setCartData((prevCart) => {
        const medications = prevCart.medications.filter((m) => m.rxNumber !== rxNumber)

        return { ...prevCart, medications }
      })
    },
    [setCartData],
  )

  return {
    cart,
    clearCart,
    addItem,
    setData: setCartData,
    remove: markRemovalState(true),
    undoRemove: markRemovalState(false),
    deleteRemovedItems,
    deleteRemovedItem,
  }
}
