import { ReactElement, useState } from 'react'

import { insuranceAccountPageDefaults } from '../../../constants'
import { useContentfulTheme } from '../../../hooks'
import { IAccountManagementInsuranceFields } from '../../../types/generated/contentful'
import { InfoBox, SampleCardLink, SampleInsuranceCardModal } from './styledComponents'

interface Props {
  pageContent: IAccountManagementInsuranceFields | undefined
}

export const SampleInsuranceCard = ({ pageContent }: Props): ReactElement => {
  const [showSampleInsuranceCard, setShowSampleInsuranceCard] = useState(false)
  const { theme: vpTheme } = useContentfulTheme()

  const handleShowSampleInsuranceCard = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowSampleInsuranceCard(true)
  }

  return (
    <>
      <InfoBox vpTheme={vpTheme}>
        <h4>{pageContent?.whereToFindInsuranceInfoHeader || insuranceAccountPageDefaults.whereToFindInsuranceInfoHeader}</h4>
        <p>
          {pageContent?.whereToFindInsuranceInfoText || insuranceAccountPageDefaults.whereToFindInsuranceInfoText}
          {pageContent?.sampleInsuranceCard?.fields?.file?.url ? (
            <SampleCardLink onClick={handleShowSampleInsuranceCard} vpTheme={vpTheme}>
              Show sample card
            </SampleCardLink>
          ) : (
            <></>
          )}
        </p>
      </InfoBox>
      <SampleInsuranceCardModal
        isOpen={showSampleInsuranceCard}
        onDismiss={() => setShowSampleInsuranceCard(false)}
        aria-label={'sample insurance card'}
      >
        <img src={pageContent?.sampleInsuranceCard?.fields?.file?.url} alt='Sample insurance card' />
      </SampleInsuranceCardModal>
    </>
  )
}
