import { useEffect, useState } from 'react'

import type { IIdentityVerificationPageFields } from '../../types/generated/contentful'
import { getPageConfiguration } from './contentfulService'
import { getPageMicrocopy } from './helpers'
import { useGetCustomerNameFromSubdomain } from './useGetCustomerNameFromSubdomain'

type ReturnValues = {
  loading: boolean
  error: boolean
  content?: IIdentityVerificationPageFields
}

export const useContentfulIdentityVerificationContent = (): ReturnValues => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [content, setContent] = useState<IIdentityVerificationPageFields | undefined>()
  const customerName = useGetCustomerNameFromSubdomain()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const page = await getPageConfiguration(customerName, 'identityVerificationPage')
        const fields = getPageMicrocopy(page?.fields.microcopy)
        setContent(fields as IIdentityVerificationPageFields)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [customerName])

  return {
    loading,
    error,
    content,
  }
}
