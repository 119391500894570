import { Header, SEO, Text } from '@truepill/react-capsule'
import { selectedPatientTokenAtom } from 'persistRecoil'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { AccountLayout } from '../../../Components/PageLayoutWithSidebar'
import SupportLink from '../../../Components/SupportLink'
import ToastMessage from '../../../Components/ToastMessage'
import { AccountPage, SEPARATE_URAC_SURVEY_PREGNANCY_FIELD } from '../../../constants'
import { TOAST_DEFAULT_TIMEOUT, ToastState } from '../../../constants/toastConstants'
import { useContentfulTheme, useCustomerProfile } from '../../../hooks'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { pharmacyService, surveyService } from '../../../services'
import { mapToLabel } from '../../../utils/pregnancyQuestionHelpers'
import { buildPatientSettings, PatientSettings } from './data/patientSettingsBuilder'
import { buildUracSettings, UracSettings } from './data/uracSettingsBuilder'
import { GeneralInformationItem } from './GeneralInformationItem'
import { HealthInformationItem } from './HealthInformationItem'
import {
  BorderBox,
  GeneralInformationContainer,
  HealthProfileContainer,
  HealthProfileSection,
  LastUpdatedText,
  Separator,
  SurveyInfoBox,
} from './styledComponents'

const HealthProfileSettings: React.FunctionComponent = () => {
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { [SEPARATE_URAC_SURVEY_PREGNANCY_FIELD]: separateUracSurveyPregnancyFieldFF } = useLDFlagsWithLocalStorage([
    SEPARATE_URAC_SURVEY_PREGNANCY_FIELD,
  ])
  const { theme } = useContentfulTheme()
  const { pharmCustomer } = useCustomerConfigContext()

  const [patientSettings, setPatientSettings] = useState<PatientSettings>(buildPatientSettings(null))
  const [uracSettings, setUracSettings] = useState<UracSettings | null>(buildUracSettings(null, separateUracSurveyPregnancyFieldFF))

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    async function getHealthProfileSettings() {
      const customerToken = customerProfile.vpharmCustomerToken

      try {
        const patient = await pharmacyService.getPatient(customerToken, selectedPatientToken)
        setPatientSettings(buildPatientSettings(patient))

        const urac = await surveyService.getPatientSurvey(customerToken, selectedPatientToken)
        setUracSettings(buildUracSettings(urac, separateUracSurveyPregnancyFieldFF))
      } catch (ex) {
        setShowError(true)
      } finally {
        setIsLoading(false)
      }
    }

    getHealthProfileSettings()
  }, [customerProfile.vpharmCustomerToken, selectedPatientToken, separateUracSurveyPregnancyFieldFF])

  if (isLoading) {
    return <LoadingAnimation />
  }

  const legalNameTooltip = (
    <p>
      In the event of a legal name change, please <SupportLink> contact Patient Support </SupportLink>
    </p>
  )

  const sexTooltip = (
    <p>
      We recognize that this information may not represent who you are. We are committed to inclusivity within our health and safety requirements for
      all of our services. <br />
      <br /> Our pharmacy certification requires us to ask for this information to keep you safe. Our pharmacists will use this information to check
      for drug interactions and any other safety concerns.
    </p>
  )

  return (
    <AccountLayout selected={AccountPage.HealthProfile}>
      <SEO title={`${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <HealthProfileContainer>
        <Header bold variant='4xl' data-testid='header-health-profile'>
          Health Profile
        </Header>
        <ToastMessage
          timeout={TOAST_DEFAULT_TIMEOUT}
          onDismiss={() => {
            setShowError(false)
          }}
          state={ToastState.ERROR}
          visible={showError}
          onTimeout={() => {
            setShowError(false)
          }}
        >
          An unexpected error occurred, failed to retrieve patient settings
        </ToastMessage>
        <HealthProfileSection>
          <Header bold variant='xl' data-testid='header-general-info'>
            General information
          </Header>
          <BorderBox vpTheme={theme}>
            <GeneralInformationContainer>
              <GeneralInformationItem
                label='Legal name'
                text={patientSettings.legalName}
                testId='legal-name'
                tooltipLabel='Name change'
                tooltip={legalNameTooltip}
              />
              <Separator border={theme.colors['gray-300']} />
              <GeneralInformationItem label='Date of birth' text={patientSettings.dob} testId='dob' />
              <Separator border={theme.colors['gray-300']} />
              <GeneralInformationItem
                label='Sex assigned at birth'
                text={patientSettings.gender}
                testId='sex'
                tooltipLabel='Sex at birth'
                tooltip={sexTooltip}
              />
            </GeneralInformationContainer>
          </BorderBox>
        </HealthProfileSection>

        <HealthProfileSection>
          <Header bold variant='xl' data-testid='header-health-info'>
            Health information
          </Header>
          <Text>You can update this information during checkout, before each refill</Text>
          <LastUpdatedText color={theme.colors['typography-medium']} data-testid='urac-last-updated-text'>
            Last updated: {uracSettings ? uracSettings.lastUpdated : 'N/A'}
          </LastUpdatedText>
          {uracSettings ? (
            <>
              <HealthInformationItem label={'Allergies'} text={uracSettings.allergies} testId={'allergies'} />
              <HealthInformationItem label={'Medical conditions'} text={uracSettings.healthConditions} testId={'medical-conditions'} />
              <HealthInformationItem
                label={'Medications, vitamins, or supplements'}
                text={uracSettings.medicationsAndSupplements}
                testId={'medications'}
              />
              {patientSettings.gender === 'Female' &&
                (!separateUracSurveyPregnancyFieldFF ? (
                  <HealthInformationItem
                    label={'Pregnant, lactating, or planning to become pregnant'}
                    text={uracSettings.pregnantOrLactating}
                    testId={'pregnant'}
                  />
                ) : (
                  <>
                    <HealthInformationItem
                      label={'Pregnancy status'}
                      text={mapToLabel(uracSettings.pregnancyStatus || []) || ''}
                      testId={'pregnant'}
                    />
                  </>
                ))}
            </>
          ) : (
            <SurveyInfoBox vpTheme={theme} data-testid='health-survey-info'>
              You will be asked to answer questions about your health history during checkout. Our pharmacists will use this information to make sure
              your medications are safe for you to take.
            </SurveyInfoBox>
          )}
        </HealthProfileSection>
      </HealthProfileContainer>
    </AccountLayout>
  )
}

export default HealthProfileSettings
