export const insuranceAccountPageDefaults = {
  errorMessageText: "We couldn't load your insurance details",
  pageTitle: 'Pharmacy Insurance',
  pageDescription:
    'You have the option to provide supplemental insurance or copay coverage in addition to your primary insurance. You can submit up to three insurances.',
  cardholderIdLabel: 'Cardholder ID',
  rxBinLabel: 'Rx BIN',
  rxGroupLabel: 'Rx Group',
  rxPcnLabel: 'Rx PCN',
  relationshipToCardholderLabel: 'Relationship to Cardholder',
  addInsuranceCardButtonText: 'Add new insurance',
  addInsuranceModalTitleText: 'Add pharmacy insurance',
  addInsuranceSuccessBannerText: 'Your pharmacy insurance has been successfully added',
  addInsuranceErrorBannerText: 'Something went wrong when trying to update your insurance details. Please try again later',
  addInsuranceCardholderIdPlaceholder: 'B03100136180',
  addInsuranceRelationshipToCardholderPlaceholder: 'Select',
  addInsuranceRxBinPlaceholder: '601341',
  addInsuranceRxPcnPlaceholder: 'OHCP',
  addInsuranceRxGroupPlaceholder: 'OH9007041',
  addInsuranceCardholderIdInlineErrorMessage: 'Cardholder ID is too long',
  addInsuranceRxBinInlineErrorMessage: 'RX BIN is too long',
  addInsuranceRxPcnInlineErrorMessage: 'RX PCN is too long',
  addInsuranceRxGroupInlineErrorMessage: 'RX Group is too long',
  addInsuranceRelationshipToCardholderInlineErrorMessage: 'Please select an option',
  editInsuranceModalTitleText: 'Edit pharmacy insurance',
  editInsuranceModalDescriptionText: 'Any new edits will replace existing insurance information.',
  deleteInsuranceSuccessBannerText: 'Your pharmacy insurance has been successfully deleted',
  deleteInsuranceErrorBannerText: 'Oops, something went wrong. Please try again',
  whereToFindInsuranceInfoHeader: 'Where can I find this?',
  whereToFindInsuranceInfoText: 'Typically your prescription insurance card will have an “Rx” symbol, and “BIN” and “PCN” numbers.',
}
