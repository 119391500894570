import { Header, SEO, Spacer, Text } from '@truepill/react-capsule'
import LoadingAnimation from 'Components/LoadingAnimation'
import { AccountLayout } from 'Components/PageLayoutWithSidebar'
import { usePatientInsurance } from 'hooks/usePatientInsurance'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import React, { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import CustomerCopyChange from '../../../Components/CustomerCopyChange'
import { withEmailVerified } from '../../../Components/Hoc/withEmailVerified'
import DeleteInsurance from '../../../Components/Insurances/DeleteInsurance'
import InsuranceForm from '../../../Components/Insurances/InsuranceForm/InsuranceForm'
import SavedInsurances from '../../../Components/Insurances/SavedInsurances'
import SupportLink from '../../../Components/SupportLink'
import ToastMessage from '../../../Components/ToastMessage'
import { AccountPage, insuranceAccountPageDefaults } from '../../../constants'
import { useGetAccountManagementInsuranceContent } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { InsuranceBannerMicrocopy, InsuranceManagerPageMicrocopy, MicroCopyResourceType } from '../../../hooks/contentful/types/microcopy'
import { useContentfulMicrocopy } from '../../../hooks/contentful/useContentfulMicrocopy'
import Container from '../Container'
import StyledHeader from '../StyledHeader'
import InsuranceBanner from './InsuranceBanner'
import { DeleteInsuranceModal, InsuranceFormModal } from './styledComponents'

interface IToastMessage {
  message: string
  type: 'error' | 'success'
  displayCustomerSupport?: boolean
}

const InsurancePageContent: React.FunctionComponent = () => {
  const [toastMessage, setToastMessage] = useState<IToastMessage>()
  const [insuranceFormModalOpen, setInsuranceFormModalOpen] = useState(false)
  const [deleteInsuranceModalOpen, setDeleteInsuranceModalOpen] = useState(false)
  const [currentPatientInsurance, setCurrentPatientInsurance] = useState<PatientInsurance | null>(null)
  const { trackButtonClickEvent } = useAnalytics()
  const { isErrorLoading, isLoadingInsurance, isDeletingInsurance, patientInsuranceList, deletePatientInsurance, submitPatientInsurance } =
    usePatientInsurance()
  const { content: pageContent, error: contentfulError, loading: isLoadingPageContent } = useGetAccountManagementInsuranceContent()
  const { microcopy: insuranceBannerContent, isLoadingContent: isLoadingInsuranceBannerContent } = useContentfulMicrocopy<InsuranceBannerMicrocopy>(
    MicroCopyResourceType.InsuranceBanner,
  )
  const { microcopy: insuranceManagerPageContent, isLoadingContent: isLoadingInsuranceManagerPageContent } =
    useContentfulMicrocopy<InsuranceManagerPageMicrocopy>(MicroCopyResourceType.InsuranceManagerPage)
  const { pharmCustomer } = useCustomerConfigContext()

  useEffect(() => {
    if (contentfulError || isErrorLoading) {
      setToastMessage({
        type: 'error',
        message: pageContent?.errorMessageText || insuranceAccountPageDefaults.errorMessageText,
      })
    }
  }, [contentfulError, isErrorLoading, pageContent])

  const handleClickAddInsurance = () => {
    setCurrentPatientInsurance(null)
    setInsuranceFormModalOpen(true)
    trackButtonClickEvent(
      'open_add_new_insurance_modal',
      pageContent?.addInsuranceCardButtonText || insuranceAccountPageDefaults.addInsuranceCardButtonText,
      'opens new insurance modal',
    )
  }

  const handleClickEditInsurance = (insurance: PatientInsurance) => {
    setCurrentPatientInsurance(insurance)
    setInsuranceFormModalOpen(true)
    trackButtonClickEvent('open_edit_insurance_modal', pageContent?.editDetailsLabel || 'edit details', 'opens edit insurance modal')
  }

  const handleClickDeleteInsurance = (insurance: PatientInsurance) => {
    setCurrentPatientInsurance(insurance)
    setDeleteInsuranceModalOpen(true)
    trackButtonClickEvent('open_delete_insurance', 'delete', 'opens delete insurance modal')
  }

  const handleSubmitPatientInsurance = async (values: PatientInsuranceSubmitPayload) => {
    const keys: (keyof Omit<PatientInsuranceSubmitPayload, 'insurance_image_name'>)[] = [
      'cardholder_id',
      'rx_bin',
      'rx_group',
      'pcn',
      'relationship_to_primary_cardholder',
    ]
    const modifiedFields = keys.filter((key) => {
      const previousValue = currentPatientInsurance ? currentPatientInsurance[key] : null
      return (values[key] && previousValue !== values[key]) || !!values[key] !== !!previousValue
    })

    const isInsuranceImageModified = !!values.insurance_image_name || values.insurance_image_name === null

    // Always call the endpoint if insurance image name is a provided value, or if any fields were modified in the insurance
    if (modifiedFields.length || isInsuranceImageModified) {
      trackButtonClickEvent('save-or-update-patient-insurance', 'Save', currentPatientInsurance ? 'Update insurance' : 'Add new Insurance')
      const isSuccess = await submitPatientInsurance(values, currentPatientInsurance?.truepill_insurance_token as string | undefined)
      if (isSuccess) {
        setToastMessage({
          type: 'success',
          message: pageContent?.addInsuranceSuccessBannerText || insuranceAccountPageDefaults.addInsuranceSuccessBannerText,
        })
      } else {
        setToastMessage({
          type: 'error',
          message: pageContent?.addInsuranceErrorBannerText || insuranceAccountPageDefaults.addInsuranceErrorBannerText,
          displayCustomerSupport: true,
        })
      }
    }

    setInsuranceFormModalOpen(false)
  }

  const handleDeletePatientInsurance = async (insurance: PatientInsurance) => {
    const isSuccess = await deletePatientInsurance(insurance.truepill_insurance_token as string)
    if (isSuccess) {
      setToastMessage({
        type: 'success',
        message: pageContent?.deleteInsuranceSuccessBanner || insuranceAccountPageDefaults.deleteInsuranceSuccessBannerText,
      })
    } else {
      setToastMessage({
        type: 'error',
        message: pageContent?.deleteInsuranceErrorBanner || insuranceAccountPageDefaults.deleteInsuranceErrorBannerText,
        displayCustomerSupport: true,
      })
    }
    setDeleteInsuranceModalOpen(false)
    trackButtonClickEvent('confirm_delete_insurance', 'confirm delete', 'deletes insurance')
  }

  if (isLoadingInsurance || isLoadingPageContent || isLoadingInsuranceBannerContent || isLoadingInsuranceManagerPageContent) {
    return <LoadingAnimation />
  }

  return (
    <AccountLayout selected={AccountPage.Insurance}>
      <SEO title={`${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <Container>
        <StyledHeader>
          <Header as='h1' variant='3xl'>
            {pageContent?.pageTitle || insuranceAccountPageDefaults.pageTitle}
          </Header>
          <Spacer size='xl' />
          <Text>{pageContent?.longPageDescription || pageContent?.pageDescription || insuranceAccountPageDefaults.pageDescription}</Text>
        </StyledHeader>
        <Spacer size='xl' />
        <SavedInsurances
          pageContent={pageContent}
          patientInsuranceList={patientInsuranceList}
          handleClickAddInsurance={handleClickAddInsurance}
          handleClickEditInsurance={handleClickEditInsurance}
          handleClickDeleteInsurance={handleClickDeleteInsurance}
        />
        {insuranceBannerContent && (
          <InsuranceBanner
            iconUrl={insuranceBannerContent?.iconUrl}
            mainHeader={insuranceBannerContent?.mainHeader}
            backgroundColor={insuranceBannerContent?.backgroundColor}
            subtext={insuranceBannerContent?.subtext}
          />
        )}
        <InsuranceFormModal
          isOpen={insuranceFormModalOpen}
          onDismiss={() => setInsuranceFormModalOpen(false)}
          aria-label={currentPatientInsurance ? 'edit insurance modal' : 'add new insurance modal'}
        >
          <InsuranceForm
            pageContent={pageContent}
            currentInsurance={currentPatientInsurance}
            handleSubmitForm={handleSubmitPatientInsurance}
            handleCancelForm={() => {
              setInsuranceFormModalOpen(false)
              trackButtonClickEvent('cancel_add_insurance', 'cancel', 'cancels add insurance')
            }}
          />
        </InsuranceFormModal>
        <DeleteInsuranceModal
          isOpen={deleteInsuranceModalOpen}
          onDismiss={() => {
            setDeleteInsuranceModalOpen(false)
            trackButtonClickEvent('close_delete_insurance_modal', 'x', 'closes delete insurance modal')
          }}
          aria-label='delete insurance modal'
        >
          <DeleteInsurance
            pageContent={pageContent}
            currentInsurance={currentPatientInsurance as PatientInsurance}
            isDeletingInsurance={isDeletingInsurance}
            cancel={() => {
              setDeleteInsuranceModalOpen(false)
              trackButtonClickEvent('cancel_delete_insurance', 'cancel delete', 'cancels delete insurance')
            }}
            handleDeleteInsurance={handleDeletePatientInsurance}
          />
        </DeleteInsuranceModal>
      </Container>
      <ToastMessage
        state={toastMessage?.type}
        visible={!!toastMessage}
        timeout={3000}
        onTimeout={() => setToastMessage(undefined)}
        onDismiss={() => setToastMessage(undefined)}
      >
        <Text>
          {toastMessage?.message}{' '}
          {toastMessage?.displayCustomerSupport ? (
            <>
              or <SupportLink>contact Patient Support</SupportLink>
            </>
          ) : null}
        </Text>
      </ToastMessage>
      {insuranceManagerPageContent?.customerCopyChangeText && (
        <CustomerCopyChange copyChangeText={insuranceManagerPageContent.customerCopyChangeText} />
      )}
    </AccountLayout>
  )
}

export default withEmailVerified(InsurancePageContent)
