import { Divider, Spacer } from '@truepill/react-capsule'
import React from 'react'

import { useContentfulTheme } from '../../hooks'
import { OrderDetailItem, OrderSummaryContainer, StyledText, TitleContainer } from './styledComponents'

interface Item {
  key: string
  label: string
}

const ORDER_SUMMARY_ITEMS_CART_FLOW: Item[] = [
  { key: 'subTotal', label: 'Subtotal' },
  { key: 'estimatedShippingAndTaxes', label: 'Shipping and taxes' },
  { key: 'orderTotal', label: 'Order total' },
]

const ORDER_SUMMARY_ITEMS_CHECKOUT_FLOW: Item[] = [
  { key: 'subTotal', label: 'Subtotal' },
  { key: 'estimatedShipping', label: 'Estimated Shipping' },
  { key: 'estimatedTax', label: 'Estimated Tax' },
  { key: 'orderTotal', label: 'Order total' },
]

const OrderSummary: React.FC<{ priceDetails: Record<string, number>; checkoutFlow?: boolean }> = ({ priceDetails, checkoutFlow }) => {
  const items: Item[] = checkoutFlow ? ORDER_SUMMARY_ITEMS_CHECKOUT_FLOW : ORDER_SUMMARY_ITEMS_CART_FLOW
  const { theme } = useContentfulTheme()

  return (
    <OrderSummaryContainer>
      <TitleContainer data-testid='cart-order-summary' bold variant='2xl'>
        Order summary
      </TitleContainer>
      <Divider variant='mid' css={{ borderColor: theme.colors['gray-300'] }} />
      <Spacer />
      {items.map(({ key, label }) => {
        const showBold = key === 'orderTotal'
        const shippingAndTaxesInCartFlow = key === 'estimatedShippingAndTaxes'
        const estimatedShipping = key === 'estimatedShipping'
        const estimatedTax = key === 'estimatedTax' && checkoutFlow
        const itemValue = priceDetails[key] ?? 0

        if (!checkoutFlow) {
          return (
            <OrderDetailItem key={key}>
              <StyledText $textLabel={true} data-testid={'key-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>
                {label}
              </StyledText>
              {shippingAndTaxesInCartFlow ? (
                <StyledText $textColor={theme.colors['typography-medium']} data-testid={'value-' + key} bold={showBold}>
                  Calculated at checkout
                </StyledText>
              ) : (
                <StyledText data-testid={'value-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>{`$${itemValue.toFixed(
                  2,
                )}`}</StyledText>
              )}
            </OrderDetailItem>
          )
        } else {
          return (
            <OrderDetailItem key={key}>
              <StyledText $textLabel={true} data-testid={'key-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>
                {label}
              </StyledText>
              {estimatedShipping ? (
                <StyledText data-testid={'value-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>
                  {`$${priceDetails.estimatedShipping.toFixed(2)}`}
                </StyledText>
              ) : estimatedTax ? (
                <StyledText data-testid={'value-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>
                  {`$${priceDetails.estimatedTax.toFixed(2)}`}
                </StyledText>
              ) : (
                <StyledText data-testid={'value-' + key} bold={showBold} $textColor={theme.colors['typography-dark']}>{`$${itemValue.toFixed(
                  2,
                )}`}</StyledText>
              )}
            </OrderDetailItem>
          )
        }
      })}
    </OrderSummaryContainer>
  )
}

export default OrderSummary
