import { useCallback, useEffect, useState } from 'react'

import { PatientAddress } from '../interfaces'
import { AddAddressResponse, userService } from '../services'
import { useCustomerProfile } from '.'

export interface Result<T> {
  data?: T
  error?: string
}

export interface UseUserAddressValue {
  addressList: PatientAddress[]
  isError: boolean
  isLoading: boolean
  fetchAddressList: () => Promise<void>
  addNewAddress: (address: PatientAddress) => Promise<Result<AddAddressResponse>>
  updateAddress: (address: PatientAddress) => Promise<Result<boolean>>
  updateDefaultAddress: (address: PatientAddress) => Promise<Result<boolean>>
  deleteAddress: (address: PatientAddress) => Promise<Result<boolean>>
}

export const useUserAddresses = (): UseUserAddressValue => {
  const { customerProfile } = useCustomerProfile()
  const [addressList, setAddressList] = useState<PatientAddress[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetchAddressList = useCallback(async () => {
    setIsLoading(true)
    try {
      const addresses = await userService.getUserAddress(customerProfile.vpharmCustomerToken)
      addresses.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
      setAddressList(addresses)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [customerProfile.vpharmCustomerToken, setAddressList])

  const addNewAddress = useCallback(
    async (address: PatientAddress): Promise<Result<AddAddressResponse>> => {
      let result: Result<AddAddressResponse>
      const addressPayload: PatientAddress = { ...address }

      if (!addressList.length) {
        addressPayload.isDefault = true
      }

      try {
        const response = await userService.addUserAddress(addressPayload, customerProfile.vpharmCustomerToken)
        await fetchAddressList()
        return { data: response }
      } catch (error) {
        result = { error: 'Something went wrong please try again' }
      }
      return result
    },
    [addressList, customerProfile.vpharmCustomerToken, fetchAddressList],
  )

  const updateDefaultAddress = useCallback(
    async (address: PatientAddress): Promise<Result<boolean>> => {
      let result: Result<boolean> = { data: true }
      try {
        await userService.setDefaultAddress(address, customerProfile.vpharmCustomerToken)
        await fetchAddressList()
      } catch (error) {
        result = { error: 'Something went wrong please try again' }
      }
      return result
    },
    [customerProfile.vpharmCustomerToken, fetchAddressList],
  )

  const updateAddress = useCallback(
    async (address: PatientAddress): Promise<Result<boolean>> => {
      let result: Result<boolean> = { data: true }
      const originalAddressIndex = addressList.findIndex((x) => x.id === address.id)
      const modifiedFields: string[] = []
      Object.keys(addressList[originalAddressIndex]).forEach((x) => {
        const key = x as keyof PatientAddress
        const currentAddressFieldValue = address[key]
        const originalAddressFieldValue = addressList[originalAddressIndex][key]

        if (currentAddressFieldValue !== originalAddressFieldValue) {
          modifiedFields.push(key)
        }
      })
      if (!modifiedFields.length) return { data: true }

      const onlyDefaultWasModified = modifiedFields.length === 1 && modifiedFields[0] === 'isDefault'

      if (onlyDefaultWasModified) {
        result = await updateDefaultAddress(address)
      } else {
        try {
          await userService.updateUserAddress(address, customerProfile.vpharmCustomerToken)
          await fetchAddressList()
        } catch {
          result = { error: 'Something went wrong please try again' }
        }
      }
      return result
    },
    [addressList, customerProfile.vpharmCustomerToken, fetchAddressList, updateDefaultAddress],
  )

  const deleteAddress = useCallback(
    async (address: PatientAddress): Promise<Result<boolean>> => {
      setIsLoading(true)
      let result: Result<boolean> = { data: true }
      try {
        await userService.deleteUserAddress(address, customerProfile.vpharmCustomerToken)
        await fetchAddressList()
      } catch {
        result = { error: 'Something went wrong please try again' }
      } finally {
        setIsLoading(false)
      }
      return result
    },
    [customerProfile.vpharmCustomerToken, fetchAddressList],
  )

  useEffect(() => {
    fetchAddressList()
  }, [fetchAddressList])

  return {
    addressList,
    isError,
    isLoading,
    fetchAddressList,
    addNewAddress,
    updateAddress,
    updateDefaultAddress,
    deleteAddress,
  }
}
