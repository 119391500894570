import { Document } from '@contentful/rich-text-types'

export enum MicroCopyResourceType {
  SelfEnrollmentBanner = 'SelfEnrollmentBanner',
  InsuranceBanner = 'InsuranceBanner',
  RxManagerBanner = 'RxManagerBanner',
  SavingCardForm = 'SavingCardForm',
  SelfEnrollmentPage = 'SelfEnrollmentPage',
  RxManagerPage = 'RxManagerPage',
  InsuranceManagerPage = 'InsuranceManagerPage',
  Footer = 'Footer',
  CartBanner = 'CartBanner',
  PriceTransparencyBanner = 'PriceTransparencyBanner',
  SavingsCardSignUp = 'SavingsCardSignup',
  OrderDetailPage = 'OrderDetailPage',
  OrderConfirmationPage = 'OrderConfirmationPage',
  PrescriptionCard = 'PrescriptionCard',
  InsuranceDisclaimerBanner = 'InsuranceDisclaimerBanner',
  CopayMessage = 'CopayMessage',
  CopayErrorMessage = 'CopayErrorMessage',
  RxmInsuranceBanner = 'RxmInsuranceBanner',
  OrderErrorMessage = 'OrderErrorMessage',
  OrderTriageMessage = 'OrderTriageMessage',
  VideoTutorials = 'VideoTutorials',
  LiveChat = 'LiveChat',
  EmptyRxManagerPage = 'EmptyRxManagerPage',
  TransferOutPage = 'TransferOutPage',
  AutoRefillPage = 'AutoRefillPage',
}

export interface SelfEnrollmentBannerMicrocopy {
  mainHeader?: string
  subHeader?: string
  infoOne?: string
  contentHeader?: string
  stepOne?: string
  stepOneSubtext?: string
  stepTwo?: string
  stepTwoSubtext?: string
  stepThree?: string
  stepThreeSubtext?: string
  stepFour?: string
  stepFourSubtext?: string
  ctaButtonText?: string
}

export interface InsuranceBannerMicrocopy {
  mainHeader?: string
  subtext?: string
  backgroundColor?: string
  iconUrl?: string
}

export interface RxManagerBannerMicrocopy {
  mainHeader?: string
  subtext?: string
  backgroundColor?: string
  iconUrl?: string
}

export interface SavingCardFormMicrocopy {
  savingsCardUrlPretext?: string
  savingsCardHyperLinkText?: string
}

export interface SelfEnrollmentPageMicrocopy {
  customerCopyChangeText?: string
}

export interface RxManagerPageMicrocopy {
  customerCopyChangeText?: string
}

export interface InsuranceManagerPageMicrocopy {
  customerCopyChangeText?: string
}

export interface SavingCardSignupMicrocopy {
  radioOptions?: ScsRadioOptionsMicrocopy
  ineligible?: ScsIneligibleMicrocopy
  confirmation?: ScsConfirmationMicrocopy
  medications?: Record<string, ScsMedicationsMicrocopy>
  applySavingsCard?: ScsApplySavingsCardMicrocopy
  logoUrl?: string
}

export interface ScsApplySavingsCardMicrocopy {
  header?: string
  description?: string
}

export interface ScsRadioOptionsMicrocopy {
  initialHeader?: string
  initialSubheader?: string
  insuranceRadioOption?: string
  cashRadioOption?: string
  existingCardRadioOption?: string
  newCardWithInsuranceRadioOption?: string
  existingCardWithInsuranceRadioOption?: string
  insuranceHeader?: string
  insuranceSubheader?: string
  commercialInsurance?: string
  governmentInsurance?: string
  needSavingsCard?: string
  alreadyHaveSavingsCard?: string
}

export interface ScsMedicationsMicrocopy {
  attestations?: Record<string, string>
  termsAndConditions?: Document
}

export interface ScsIneligibleMicrocopy {
  ineligibleHeader?: string
  ineligibleSubheader?: string
  ineligibleSubheaderNoInsurance?: string
  ineligibleStepOneNoInsurance?: Document
  ineligibleStepOne?: string
  ineligibleStepTwo?: string
  ineligibleStepThree?: string
  ctaClose?: string
  ctaCloseNoInsurance?: string
}

export interface ScsConfirmationMicrocopy {
  confirmationHeader?: string
  confirmationSubheader?: string
  confirmationStepOne?: string
  confirmationStepTwo?: string
  confirmationStepThree?: string
  confirmationCalculatingPrice?: string
  confirmationCtaText?: string
}

export interface InsuranceDisclaimerBannerMicrocopy {
  insuranceModalDisclaimer?: Document
  transferPageInsuranceDisclaimer?: Document
}

export interface RxmInsuranceBannerMicrocopy {
  rxmInsuranceBannerHeader?: string
  rxmInsuranceBannerBody?: string
  rxmInsuranceBannerBackgroundImage?: string
}

export interface VideoTutorialLinksMicrocopy {
  embeddedUrl?: string
  buttonImageUrl?: string
}

export interface VideoTutorialsMicrocopy {
  [key: string]: Record<string, Record<string, VideoTutorialLinksMicrocopy>>
}

export interface LiveChatMicroCopy {
  title?: string
  domain?: string
  imageUrl?: string
  profile?: string
  cssUrl?: string
}

export interface EmptyRxManagerMicrocopy {
  pharmacyName?: string
  pharmacyAddress?: string
  pharmacyPhoneNumber?: string
  pharmacyFaxNumber?: string
  pharmacyIds?: string
  talkWithProviderSubtext?: string
}

export interface TransferOutPageMicrocopy {
  pharmacyName?: string
  pharmacyAddress?: string
  pharmacyFaxNumber?: string
  pharmacyIds?: string
}

export interface AutoRefillPageMicrocopy {
  autoRefillTermsCondition: Document
  autoRefillHelpfulInfo: Document
}
