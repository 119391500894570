import { Spacer } from '@truepill/react-capsule'
import { PrescriptionDetailsFormValues, TransferParams } from '@vpharm-platform/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { useLDContextWithLocalStorage } from '../../Components/LDProvider'
import LoadingAnimation from '../../Components/LoadingAnimation'
import { PrescriptionManagementLayout } from '../../Components/PageLayoutWithSidebar'
import StyledToastMessage from '../../Components/ToastMessage'
import {
  PRESCRIPTION_MANAGEMENT_PATH,
  PrescriptionManagementPage,
  retrieveNavigationLinks,
  TRANSFER_IN_PAGE_ENHANCEMENTS,
  TRANSFERS_PATH,
} from '../../constants'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { selectedPatientTokenAtom } from '../../persistRecoil'
import { transferService } from '../../services'
import { FormContainer, LoadingIndicatorWrapper } from './styledComponents'
import TransferDetailsForm, { TransferFormFields } from './TransferDetailsForm'
import { useTransferSimulation } from './TransferDetailsForm/TransferSimulation'
import TransferRequestConfirmation from './TransferRequestConfirmation/TransferRequestConfirmation'

export type TransferState = {
  transferDetailsData: TransferParams
  transferToken: string
}

const TransferRequest: React.FC = () => {
  const history = useHistory()
  const [transferDetails, setTransferDetails] = useState<TransferState>({ transferDetailsData: {}, transferToken: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [transferToken, setTransferToken] = useState('')
  const { customerProfile } = useCustomerProfile()
  const transferSimulation = useTransferSimulation()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const { trackTransferSubmittedEvent } = useAnalytics()
  const { prescriptionTransfersV2, autoRefills } = useLDContextWithLocalStorage()
  const { [TRANSFER_IN_PAGE_ENHANCEMENTS]: enhancementsFeatureFlag } = useLDFlagsWithLocalStorage([TRANSFER_IN_PAGE_ENHANCEMENTS])
  const { theme } = useContentfulTheme()

  useEffect(() => {
    if (!customerProfile.allowPrescriptionTransfers || !prescriptionTransfersV2) {
      history.replace(PRESCRIPTION_MANAGEMENT_PATH)
    }
  }, [customerProfile, history, prescriptionTransfersV2])

  const handleTransferSubmit = useCallback(
    async (formData: TransferFormFields) => {
      try {
        setError('')
        setIsSubmitting(true)
        if (!transferDetails.transferDetailsData.prescription_details && !formData.medicationDetails.medication) {
          setError('Something went wrong. Please refresh the page and try again.')
          return
        }

        const prescriptions = [...(transferDetails.transferDetailsData.prescription_details || [])]
        if (formData.medicationDetails.medication) {
          prescriptions.push({
            medication_name: formData.medicationDetails.medication.formattedName || '',
            generic_name: formData.medicationDetails.medication.genericName || '',
            drug_name: formData.medicationDetails.medication.drugName || '',
            medication_strength: formData.medicationDetails.medicationStrength || '',
          })
        }

        prescriptions.map((med) => trackTransferSubmittedEvent('transfer_in', med.medication_name, formData.pharmacyDetails.pharmacyName))

        const transferTokenResult = await transferService.createTransferRequest(
          {
            ...transferDetails.transferDetailsData,
            prescription_details: prescriptions.map((p) => ({
              ...p,
              medication_name: p.medication_name,
              medication_strength: p.medication_strength,
            })),
            pharmacy_details: {
              pharmacy_name: formData.pharmacyDetails.pharmacyName,
              pharmacy_phone_number: formData.pharmacyDetails.pharmacyPhoneNumber,
              pharmacy_fax_number: formData.pharmacyDetails.pharmacyFaxNumber,
            },
            ...(transferSimulation.transferSimulation.transferSimulationChecked && { simulation: transferSimulation.transferSimulation.simulation }),
          },
          customerProfile.vpharmCustomerToken,
          selectedPatientToken,
        )

        setTransferToken(transferTokenResult)
        if (enhancementsFeatureFlag) {
          history.push(TRANSFERS_PATH, { fromTransferIn: true })
        }
      } catch (e) {
        setError('Something went wrong. Please refresh the page and try again.')
      } finally {
        setIsSubmitting(false)
      }
    },
    [
      transferDetails.transferDetailsData,
      transferSimulation.transferSimulation.transferSimulationChecked,
      transferSimulation.transferSimulation.simulation,
      customerProfile.vpharmCustomerToken,
      selectedPatientToken,
      trackTransferSubmittedEvent,
      enhancementsFeatureFlag,
      history,
    ],
  )

  const handlePrescriptionAdded = useCallback(
    (prescription: PrescriptionDetailsFormValues) => {
      setTransferDetails({
        ...transferDetails,
        transferDetailsData: {
          ...transferDetails.transferDetailsData,
          prescription_details: [...(transferDetails.transferDetailsData.prescription_details || []), prescription],
        },
      })
    },
    [transferDetails],
  )

  const handleDeletePrescription = useCallback(
    (id: string) => {
      const newArr = transferDetails.transferDetailsData.prescription_details?.filter((p) => p.medication_name !== id)
      setTransferDetails({
        ...transferDetails,
        transferDetailsData: {
          ...transferDetails.transferDetailsData,
          prescription_details: newArr,
        },
      })
    },
    [transferDetails],
  )

  return (
    <PrescriptionManagementLayout
      selected={PrescriptionManagementPage.PrescriptionTransfer}
      navigationLinks={retrieveNavigationLinks(customerProfile, prescriptionTransfersV2, autoRefills, theme)}
    >
      <FormContainer>
        {
          <StyledToastMessage state='error' onDismiss={() => setError('')} visible={!!error} timeout={10000}>
            {error}
          </StyledToastMessage>
        }
        {isSubmitting && (
          <LoadingIndicatorWrapper vpTheme={theme}>
            <LoadingAnimation />
          </LoadingIndicatorWrapper>
        )}
        {transferToken && !enhancementsFeatureFlag ? (
          <TransferRequestConfirmation transferToken={transferToken} />
        ) : (
          <TransferDetailsForm
            onAddPrescription={handlePrescriptionAdded}
            onDeletePrescription={handleDeletePrescription}
            onSubmit={handleTransferSubmit}
            prescriptions={transferDetails.transferDetailsData.prescription_details || []}
            transferSimulation={transferSimulation}
          />
        )}
        <Spacer size='lg' />
      </FormContainer>
    </PrescriptionManagementLayout>
  )
}

export default TransferRequest
