import { Header } from '@truepill/react-capsule'
import { breakpoint } from 'common/styles/variables'
import styled from 'styled-components/macro'

export const SplitView = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1.5rem;
  row-gap: 2rem;
  margin: 4.5rem 1.5rem 6rem 1.5rem;

  ${breakpoint.tablet} {
    grid-template-columns: 1fr;
    grid-template-columns: 1fr;
  }
`

export const SplitViewHeader = styled(Header)`
  grid-row-start: 1;
  grid-row-end: 2;
`

export const MainScreen = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 744px;
  width: 100%;

  ${breakpoint.tablet} {
    grid-row-start: 3;
    grid-row-end: 4;
  }
`

export const SecondaryScreen = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  width: 360px;

  ${breakpoint.tablet} {
    grid-row-start: 2;
    grid-row-end: 3;
    width: 100%;
  }
`
