import React from 'react'

import { retrieveNavigationLinks } from '../../constants/navigation-links'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import { useLDContextWithLocalStorage } from '../LDProvider'
import NavigationLinks from './NavigationLinks'

interface Props {
  itemColor: string
  onLinkClick?: () => void
}

const PrescriptionManagementNavigation = ({ onLinkClick, itemColor }: Props): React.ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const { prescriptionTransfersV2, autoRefills } = useLDContextWithLocalStorage()
  const { theme } = useContentfulTheme()

  return (
    <NavigationLinks
      title='Prescription manager'
      onLinkClick={onLinkClick}
      links={retrieveNavigationLinks(customerProfile, prescriptionTransfersV2, autoRefills, theme)}
      itemColor={itemColor}
    />
  )
}

export default PrescriptionManagementNavigation
