import { useEffect } from 'react'

import { LiveChatMicroCopy, MicroCopyResourceType } from './contentful/types/microcopy'
import { useContentfulMicrocopy } from './contentful/useContentfulMicrocopy'
import { useCustomerProfile } from './useCustomerProfile'

export const useFive9 = (): void => {
  const { microcopy: liveChatContent } = useContentfulMicrocopy<LiveChatMicroCopy>(MicroCopyResourceType.LiveChat)
  const { customerProfile } = useCustomerProfile()

  useEffect(() => {
    if (customerProfile.allowLiveChat && liveChatContent) {
      const headerScript = document.createElement('script')
      headerScript.src = 'https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js'
      headerScript.id = 'headerScript'
      const configScript = document.createElement('script')
      configScript.type = 'text/javascript'
      configScript.id = 'configScript'
      const style = document.createElement('style')
      style.textContent = '.five9-frame #five9-popout-button { display: none; }'

      headerScript.addEventListener('load', () => {
        document.body.appendChild(configScript)
        document.body.appendChild(style)
      })

      configScript.text = `var options = {
        rootUrl: 'https://app.five9.com/consoles/',
        type: 'chat',
        title: '${liveChatContent.title}',
        tenant: '${liveChatContent.domain}',
        profiles: '${liveChatContent.profile}',
        showProfiles: false,
        autostart: true,
        theme: '${liveChatContent.cssUrl}',
        logo: '${liveChatContent.imageUrl}',
        surveyOptions: {
          showComment: true,
          requireComment: false,
        },
        fields: {
          name: {
            value: '',
            show: true,
            label: 'Name',
          },
          email: {
            value: '',
            show: true,
            label: 'Email address',
          },
          rxNumber: {
            value: '',
            show: true,
            label: 'Rx number (optional)',
          },
          UserLocale: {
            value: 'en',
            show: false,
          },
        },
        playSoundOnMessage: false,
        allowCustomerToControlSoundPlay: false,
        showEmailButton: false,
        hideDuringAfterHours: true,
        useBusinessHours: false,
        showPrintButton: false,
        allowUsabilityMenu: false,
        enableCallback: false,
        callbackList: '',
        allowRequestLiveAgent: false,
      }
      var target = document.body;
var observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    if (mutation.addedNodes.length && mutation.addedNodes[0].className === 'five9-frame') {
      console.log("Mutation detected that Five9 Widget was added", mutation);
      document.getElementsByClassName('five9-text')[0].innerText = "Chat";
      document.getElementsByClassName('five9-text')[1].innerText = "Chat";
    }
  });
});
var config = { attributes: false, childList: true, characterData: false };
observer.observe(target, config);
                  
      Five9SocialWidget.addWidget(options)`
      document.head.appendChild(headerScript)

      return () => {
        document.body.removeChild(configScript)
        document.body.removeChild(headerScript)
      }
    }
  }, [customerProfile.allowLiveChat, liveChatContent])
}
