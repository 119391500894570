import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const Cartridge = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405946)'>
        <path
          d='M29.3501 34.9951C29.3501 31.2651 32.3801 28.2451 36.1001 28.2451L90.8701 28.2451C91.8601 28.2451 92.6601 29.0451 92.6601 30.0351V39.9651C92.6601 40.9551 91.8601 41.7551 90.8701 41.7551L36.1001 41.7551C32.3701 41.7551 29.3501 38.7251 29.3501 35.0051V34.9951Z'
          fill={vpTheme.colors.grayWhite}
        />
        <path
          d='M90.8701 42.2551H36.1001C32.1001 42.2551 28.8501 39.0051 28.8501 35.0051C28.8501 31.0051 32.1001 27.7451 36.1001 27.7451H90.8701C92.1301 27.7451 93.1601 28.7751 93.1601 30.0351V39.9651C93.1601 41.2251 92.1301 42.2551 90.8701 42.2551ZM36.1001 28.7451C32.6501 28.7451 29.8501 31.5451 29.8501 34.9951C29.8501 38.4451 32.6501 41.2451 36.1001 41.2451H90.8701C91.5801 41.2451 92.1601 40.6651 92.1601 39.9551V30.0351C92.1601 29.3251 91.5801 28.7451 90.8701 28.7451H36.1001Z'
          fill={vpTheme.colors.black}
        />
        <path d='M27.1802 32.1649V37.8149H29.3402V32.1649H27.1802Z' fill={vpTheme.colors['gray-300']} />
        <path d='M29.8402 38.325H26.6802V31.675H29.8402V38.325ZM27.6802 37.325H28.8402V32.675H27.6802V37.325Z' fill={vpTheme.colors.black} />
        <path d='M22.3501 30.9951V38.9951H27.1801V30.9951H22.3501Z' fill={vpTheme.colors['gray-300']} />
        <path d='M27.6801 39.4951H21.8501V30.4951H27.6801V39.4951ZM22.8501 38.4951H26.6801V31.4951H22.8501V38.4951Z' fill={vpTheme.colors.black} />
        <path d='M81.7803 28.2551H37.3403V41.7351H81.7803V28.2551Z' fill={vpTheme.colors['gray-300']} />
        <path d='M82.2803 42.2351H36.8403V27.7451H82.2803V42.2351ZM37.8403 41.2351H81.2803V28.7551H37.8403V41.2351Z' fill={vpTheme.colors.black} />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke={vpTheme.colors['gray-300']} />
      <defs>
        <clipPath id='clip0_19220_405946'>
          <rect width='71.3' height='14.51' fill={vpTheme.colors.grayWhite} transform='translate(21.8501 27.7451)' />
        </clipPath>
      </defs>
    </svg>
  )
}
