import { CapsuleTheme } from '@truepill/react-capsule'

export type SiteThemeColors = {
  colors: {
    pageBackground: string
    grayWhite: string
    iconDefault: string
    brandedTab: string
  }
}

export type PrescriptionCardThemeColors = {
  colors: {
    rxmHeroBackground: string
    rxmSavingsCardChipLabel: string
    rxmSavingsCardChipBackground: string
    rxmSavingsCardRibbon: string
    rxmSavingsCardRibbonBackground: string
  }
}

export type HeaderThemeColors = {
  colors: {
    sitewideBannerText: string
    sitewideBannerBackground: string
  }
}

export type NavThemeColors = {
  colors: {
    navBackground: string
    navItem: string
    navAvatarBg: string
    navAvatarText: string
  }
}

export type FooterThemeColors = {
  colors: {
    footerBackground: string
    footerBorder: string
    footerTextMain: string
  }
}

export type FieldThemeColors = {
  colors: {
    fieldBgDefault: string
    fieldBgComplete: string
    fieldBorderDefault: string
    fieldBorderComplete: string
  }
}

export type ButtonThemeColors = {
  colors: {
    buttonPrimary: string
    buttonPrimaryLabel: string
    buttonSecondary: string
    buttonSecondaryLabel: string
    buttonDisabled: string
    buttonDisabledLabel: string
  }
}

type VpComponentSpecificThemeColors = SiteThemeColors &
  PrescriptionCardThemeColors &
  HeaderThemeColors &
  NavThemeColors &
  FooterThemeColors &
  FieldThemeColors &
  ButtonThemeColors

export type VpTheme = CapsuleTheme & VpComponentSpecificThemeColors

export const defaultTheme: VpTheme = {
  fonts: {
    base: 'Lato, Helvetica, Arial, sans-serif',
    header: 'Lato, Helvetica, Arial, sans-serif',
  },
  colors: {
    'primary-100': '#f7fcff',
    'primary-300': '#dbf0ff',
    'primary-500': '#007ad1',
    'primary-700': '#034d83',
    'primary-900': '#00253f',

    'gray-100': '#f9fafb',
    'gray-300': '#e9ecf0',
    'gray-500': '#9eacbc',
    'gray-700': '#4e5d70',

    white: '#ffffff', // Not in Contentful
    black: '#0f1215', // Not in Contentful
    grayWhite: '#ffffff',

    'functional-info-dark': '#006fc3',
    'functional-info-light': '#DBF0FF',
    'functional-warning-dark': '#af5304',
    'functional-warning-light': '#fff6e8',
    'functional-success-dark': '#0e7016',
    'functional-success-light': '#e6ffe9',
    'functional-error-dark': '#c3362e',
    'functional-error-light': '#fff1f1',

    'typography-dark': '#13171c',
    'typography-medium': '#535762',

    pageBackground: '#ffffff',
    iconDefault: '#034D83',
    brandedTab: '#007AD1',

    rxmHeroBackground: '#F9FAFB',
    rxmSavingsCardChipLabel: '#0E7016',
    rxmSavingsCardChipBackground: '#E6FFE9',
    rxmSavingsCardRibbon: '#034D83',
    rxmSavingsCardRibbonBackground: '#DBF0FF',
    sitewideBannerText: '#ffffff',
    sitewideBannerBackground: '#034D83',
    navBackground: '#ffffff',
    navItem: '#13171c',
    navAvatarBg: '#034D83',
    navAvatarText: '#FFFFFF',
    footerBackground: '#F9FAFB',
    footerBorder: '#E9ECF0',
    footerTextMain: '#535762',
    fieldBgDefault: '#FFFFFF',
    fieldBgComplete: '#F9F9FB',
    fieldBorderDefault: '#4E5A70',
    fieldBorderComplete: '#9EA9BC',

    buttonPrimary: '#034D83',
    buttonPrimaryLabel: '#FFFFFF',
    buttonSecondary: '#007AD1',
    buttonSecondaryLabel: '#FFFFFF',
    buttonDisabled: '#E9ECF0',
    buttonDisabledLabel: '#535762',
  },
}
