import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { ThemedComponent } from '../../common/styledComponents/types'

export const PenInjector = ({ vpTheme }: ThemedComponent): React.ReactElement => {
  return (
    <svg width='115' height='70' viewBox='0 0 115 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' fill={vpTheme.colors.grayWhite} />
      <g clipPath='url(#clip0_19220_405943)'>
        <path
          d='M17.095 31.0801H61.125V41.3801H17.095C15.045 41.3801 13.375 39.7101 13.375 37.6601V34.7901C13.375 32.7401 15.045 31.0701 17.095 31.0701V31.0801Z'
          fill={vpTheme.colors['gray-300']}
        />
        <path
          d='M61.625 41.8801H17.095C14.765 41.8801 12.875 39.9901 12.875 37.6601V34.7901C12.875 32.4701 14.765 30.5801 17.095 30.5801H61.625V41.8801ZM17.095 31.5801C15.325 31.5801 13.875 33.0201 13.875 34.8001V37.6701C13.875 39.4401 15.315 40.8901 17.095 40.8901H60.625V31.5801H17.095Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M94.445 31.0801H61.125V41.3801H94.445V31.0801Z' fill={vpTheme.colors['gray-300']} />
        <path
          d='M94.945 41.8801H60.625V30.5801H94.945V41.8801ZM61.625 40.8801H93.945V31.5801H61.625V40.8801Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M82.925 31.0801H61.125V41.3801H82.925V31.0801Z' fill={vpTheme.colors.grayWhite} />
        <path
          d='M83.425 41.8801H60.625V30.5801H83.425V41.8801ZM61.625 40.8801H82.425V31.5801H61.625V40.8801Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path
          d='M96.2651 31.0801H100.525C101.135 31.0801 101.625 31.5701 101.625 32.1801V40.2901C101.625 40.9001 101.135 41.3901 100.525 41.3901H96.2651V31.0801Z'
          fill={vpTheme.colors['gray-300']}
        />
        <path
          d='M100.525 41.8801H95.7651V30.5801H100.525C101.405 30.5801 102.125 31.3001 102.125 32.1801V40.2901C102.125 41.1701 101.405 41.8901 100.525 41.8901V41.8801ZM96.7651 40.8801H100.525C100.855 40.8801 101.125 40.6101 101.125 40.2801V32.1801C101.125 31.8501 100.855 31.5801 100.525 31.5801H96.7651V40.8801Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M96.4754 31.8301H94.5854V40.6401H96.4754V31.8301Z' fill={vpTheme.colors['gray-300']} />
        <path
          d='M96.9852 41.1401H94.0952V31.3301H96.9852V41.1401ZM95.0952 40.1401H95.9852V32.3301H95.0952V40.1401Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
        <path d='M53.3152 28.6201H18.2852V30.9401H53.3152V28.6201Z' fill={vpTheme.colors['gray-300']} />
        <path
          d='M53.8152 31.4401H17.7852V28.1201H53.8152V31.4401ZM18.7852 30.4401H52.8152V29.1201H18.7852V30.4401Z'
          fill={vpTheme.colors.iconDefault ?? defaultTheme.colors.iconDefault}
        />
      </g>
      <rect x='0.5' y='0.5' width='114' height='69' rx='7.5' stroke={vpTheme.colors['gray-300']} />
      <defs>
        <clipPath id='clip0_19220_405943'>
          <rect width='89.25' height='13.76' fill={vpTheme.colors.grayWhite} transform='translate(12.875 28.1201)' />
        </clipPath>
      </defs>
    </svg>
  )
}
