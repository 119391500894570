import { Header, Spacer, Text } from '@truepill/react-capsule'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { ThemedSelect } from '../../common/styledComponents/ThemedSelect'
import { ACCOUNT_ADDRESSES_PATH } from '../../constants'
import { useContentfulTheme } from '../../hooks'
import { PatientAddress } from '../../interfaces'
import { formatAddressLine } from '../../utils'
import { StyledModal } from './styledComponents'

interface Props {
  addressList: PatientAddress[]
  onSubmit: (address?: PatientAddress) => void
}

type DeliveryAddress = PatientAddress & { label?: string }

export const DefaultAddressModal: React.FC<Props> = ({ addressList, onSubmit }) => {
  const defaultAddress = addressList.find((a) => a.isDefault)
  const [selectedAddress, setSelectedAddress] = useState<PatientAddress | undefined>(defaultAddress)
  const { theme } = useContentfulTheme()

  const currentValue: DeliveryAddress = useMemo(
    () =>
      selectedAddress
        ? { ...selectedAddress, label: formatAddressLine(selectedAddress) }
        : ({ label: 'Select a default address' } as DeliveryAddress),
    [selectedAddress],
  )

  const currentOptions: DeliveryAddress[] = useMemo(
    () => addressList.map((option) => ({ ...option, label: formatAddressLine(option) })),
    [addressList],
  )

  const handleChange = (address?: DeliveryAddress) => {
    setSelectedAddress(address)
  }

  const handleSubmit = () => {
    onSubmit(selectedAddress)
  }

  return (
    <>
      <Header variant='4xl'>Update default delivery address</Header>
      <Spacer size='md' />
      <Text style={{ color: theme.colors['typography-medium'] }}>
        Select an address to change the default shipping location. Go to <Link to={ACCOUNT_ADDRESSES_PATH}>your profile</Link> to add a new address.
      </Text>
      <Spacer size='lg' />
      <ThemedSelect options={currentOptions} value={currentValue} selectedKey='label' label='Default address' onChange={handleChange} />
      <Spacer size='lg' />
      <StyledModal.ActionContainer>
        <ThemedButton onClick={handleSubmit} vpTheme={theme} disabled={!selectedAddress}>
          Save
        </ThemedButton>
      </StyledModal.ActionContainer>
    </>
  )
}
