import { PaymentMethod } from '@stripe/stripe-js'
import { Header, Spacer } from '@truepill/react-capsule'
import React, { useState } from 'react'

import { media } from '../../common/styles/variables'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import type { CreatePaymentData, Payment } from '../../hooks/useUserPayments'
import { capitalizeFirstLetter } from '../../utils/stringUtilities'
import CardholderAndExpiration from '../CardholderNameExpiration'
import { ButtonCard } from '../Fragments/ButtonCard'
import { ItemCard, ItemCardWrapper } from '../Fragments/ItemCard'
import PaymentDelete from './PaymentDelete'
import PaymentForm from './PaymentForm'
import { ModalWrapper, PaymentModalWrapper, StyledPlusCircle, StyledShowMoreCardsButton } from './styledComponents'

interface Props {
  paymentMethods: Payment[]
  maxCardsDisplayed?: number
  isCheckoutFlow?: boolean
  selectedPaymentMethod?: Payment | null
  onSelectedPaymentMethod?: (payment: Payment) => void
  onAddPaymentMethod: (data: CreatePaymentData) => Promise<PaymentMethod | undefined>
  onSetDefaultPaymentMethod: (payment: Payment) => Promise<void>
  onDeletePaymentMethod: (id: string) => Promise<void>
}

const SavedPaymentMethods: React.FC<Props> = ({
  paymentMethods,
  maxCardsDisplayed,
  isCheckoutFlow,
  selectedPaymentMethod,
  onSelectedPaymentMethod,
  onAddPaymentMethod,
  onSetDefaultPaymentMethod,
  onDeletePaymentMethod,
}) => {
  const [shouldShowAllCards, setShouldShowAllCards] = useState(false)
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false)
  const [openDeletePaymentModal, setOpenDeletePaymentModal] = useState(false)
  const [selectedPaymentForDeletion, setSelectedPaymentForDeletion] = useState<Payment>()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const handleOpenAddPaymentModal = () => {
    trackButtonClickEvent('open_add_new_payment_modal', 'Add New Payment method')
    setOpenAddPaymentModal(true)
  }

  const handleOpenDeletePaymentModal = (paymentMethod: Payment) => {
    trackButtonClickEvent('open_delete_payment_modal', 'Delete')
    setSelectedPaymentForDeletion(paymentMethod)
    setOpenDeletePaymentModal(true)
  }

  const handleCancelPaymentModal = () => {
    trackButtonClickEvent('cancel_add_new_payment_modal', 'Cancel')
    setOpenAddPaymentModal(false)
  }

  const handleCancelDeletePaymentModal = () => {
    setOpenDeletePaymentModal(false)
    setSelectedPaymentForDeletion(undefined)
  }

  const handleAddPayment = async (data: CreatePaymentData) => {
    trackButtonClickEvent('save_add_payment', 'Save')
    const paymentMethod = await onAddPaymentMethod(data)
    window.scrollTo(0, 0)
    return paymentMethod
  }

  const handleSetDefaultPayment = (paymentMethod: Payment) => {
    trackButtonClickEvent('set_default_payment', 'Set as Default')
    onSetDefaultPaymentMethod(paymentMethod)
  }

  const handleDeletePayment = (paymentMethodId: string) => {
    trackButtonClickEvent('submit_delete_payment', 'Yes, delete card')
    setOpenDeletePaymentModal(false)
    onDeletePaymentMethod(paymentMethodId)
  }

  return (
    <>
      <ItemCardWrapper className='gap-md tablet:grid-cols-2 grid grid-cols-1' data-testid='payment-list-wrapper' mobileBreakpoint={media.laptop}>
        {paymentMethods.map((payment, index) => (
          <ItemCard
            key={payment.paymentMethodId}
            itemSubject={'payment-card'}
            heading={`${capitalizeFirstLetter(payment.brand)} ending in ${payment.last4}`}
            line1={<CardholderAndExpiration expMonth={payment.expirationMonth} expYear={payment.expirationYear} name={payment.name} />}
            isDefault={payment.default}
            defaultChipText={'Default'}
            setDefault={() => handleSetDefaultPayment(payment)}
            deleteHandler={() => handleOpenDeletePaymentModal(payment)}
            iconUrl={payment.iconUrl}
            hidden={!shouldShowAllCards && !!maxCardsDisplayed && index > maxCardsDisplayed - 1}
            {...(onSelectedPaymentMethod && {
              onClick: () => onSelectedPaymentMethod(payment),
              isSelected: payment.paymentMethodId === selectedPaymentMethod?.paymentMethodId,
              activeBorderKey: 'isSelected',
            })}
          />
        ))}
        {paymentMethods.length < 8 && (
          <ButtonCard
            text='Add new payment method'
            icon={<StyledPlusCircle className='w-md text-cap-info-dark mb-2xs inline-block' vpTheme={theme} />}
            ariaLabel='add new payment button'
            onClick={handleOpenAddPaymentModal}
          />
        )}
      </ItemCardWrapper>
      <PaymentModalWrapper
        aria-label='add credit card'
        isOpen={openAddPaymentModal}
        onDismiss={() => {
          trackButtonClickEvent('close_add_new_payment_modal', 'x')
          setOpenAddPaymentModal(false)
        }}
      >
        <>
          <Header id='insurance-modal-title' variant='4xl'>
            Add new card
          </Header>
          <Spacer size='lg' />
          <PaymentForm
            onSubmit={handleAddPayment}
            onCancel={handleCancelPaymentModal}
            askShouldSave={isCheckoutFlow}
            forceDefault={!paymentMethods.length}
            askDefault
          />
        </>
      </PaymentModalWrapper>
      <ModalWrapper
        aria-label='remove credit card'
        isOpen={openDeletePaymentModal}
        onDismiss={() => {
          trackButtonClickEvent('close_delete_payment_modal', 'x')
          handleCancelDeletePaymentModal()
        }}
      >
        {selectedPaymentForDeletion && (
          <PaymentDelete
            selectedPayment={selectedPaymentForDeletion}
            cancel={() => {
              trackButtonClickEvent('cancel_delete_payment_modal', 'Cancel')
              handleCancelDeletePaymentModal()
            }}
            handleDeletePayment={handleDeletePayment}
          />
        )}
      </ModalWrapper>
      {!!maxCardsDisplayed && paymentMethods.length > maxCardsDisplayed && !shouldShowAllCards && (
        <StyledShowMoreCardsButton variant='primary-text' onClick={() => setShouldShowAllCards(true)} vpTheme={theme}>
          Show more cards...
        </StyledShowMoreCardsButton>
      )}
    </>
  )
}

export default SavedPaymentMethods
